import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './form.css'
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControlLabel, Snackbar, TextField } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ContactsIcon from '@mui/icons-material/Contacts';
import PaddingIcon from '@mui/icons-material/Padding';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PostAddIcon from '@mui/icons-material/PostAdd';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { getDetails, saveDetails } from '../../Helper/api';
import TableDisplay from '../../components/table';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  },
};

const classes = makeStyles({
  input: {
    padding: '10px !important',
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: "#5d5d5d",
    },
  },
});

const FreightForm = () => {

  const location = useLocation()
  const value = location.pathname.split('/')[1]
  const { id } = useParams();

  const [formData, setFormData] = useState({});
  const [originalFormData, setOriginalFormData] = useState({});
  const [changedFields, setChangedFields] = useState({});
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [docValid, setDocValid] = useState(true);
  const [message, setMessage] = useState("");
  const [hasData, setHasData] = useState(true);
  const [freightChargeTerms, setFreightChargeTerms] = useState(false);
  const checkboxOptions = ['Prepaid', 'Collected', 'ThirdParty'];
  const [loading, setLoading] = useState(true);
  const [isEditing, setEditing] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getDetails(id, value);

        setLoading(false);
        setFormData(JSON.parse(JSON.stringify(response.data)));
        setOriginalFormData(JSON.parse(JSON.stringify(response.data)));
        response.data.statusCode === 404 ? setHasData(false) : setHasData(true);

      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {

      }
    };
    fetchData();
  }, [id]);

  const handleInputChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
    setIsDataChanged(true);
    setChangedFields((prevChangedFields) => ({
      ...prevChangedFields,
      [key]: value,
    }));
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleSave = () => {
    if (Object.keys(changedFields).length === 0) {
      return;
    }

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const invalid = () => {
    setDocValid(false);
    setEditing(!isEditing);
  }

  const validate = async () => {
    setDocValid(true);
    formData.status = 'Validated';
    setLoading(true);
    try {
      // const response = await fetch(`https://jtmbaktotl.execute-api.us-east-1.amazonaws.com/dev/bol/${id}`,
      //   {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(formData),
      // });

      const response = await saveDetails(id, value, formData);
      setLoading(false);

      if (response.status === 200) {
        setIsDataChanged(false);
        setChangedFields({});
        setIsModalOpen(false);
        setEditing(false);
        setDocValid(true);
        setMessage('Validation Successful!');
        setOpen(true);
      } else {
        console.error('Failed to update data:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  }

  const handleCancelClick = () => {
    if (Object.keys(changedFields).length === 0) {
      setEditing(false);
      setDocValid(true);
      return;
    }

    setIsCancelModalOpen(true);
  };

  const cancelChanges = () => {
    setFormData(JSON.parse(JSON.stringify(originalFormData)));
    setIsDataChanged(false);
    setChangedFields({});
    setEditing(false);
    setIsCancelModalOpen(false);
    setDocValid(true);
    setMessage('Changes are Discarded');
    setOpen(true);
  };

  const cancelCloseModal = () => {
    setIsCancelModalOpen(false);
  };

  const saveChanges = async () => {
    formData.status = 'Retrain';
    try {

      const response = await saveDetails(id, value, formData)

      if (response.status === 200) {
        setIsDataChanged(false);
        setChangedFields({});
        setIsModalOpen(false);
        setEditing(false);
        setDocValid(true);
        setMessage('Data successfully updated!');
        setOpen(true);
      } else {
        console.error('Failed to update data:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  const renderCheckboxes = () => {
    if (formData?.freightChargeTerms) {
      setFreightChargeTerms(true);
      return checkboxOptions.map((option) => {
        const isChecked = isOptionChecked(option);

        return (
          <FormControlLabel
            key={option}
            control={
              <Checkbox
                disabled={docValid}
                checked={isChecked}
                onChange={(e) => handleCheckboxChange(option, e.target.checked)}
              />
            }
            label={option}
          />
        );
      });
    } else {
      setFreightChargeTerms(false);
    }
  };

  const isOptionChecked = (option) => {
    for (const item of formData.freightChargeTerms) {
      if (item.name === option && item.enabled) {
        return true;
      }
    }
    return false;
  };

  const handleCheckboxChange = (option, checked) => {
    const updatedTerms = formData.freightChargeTerms.map((item) =>
      item.name === option ? { ...item, enabled: checked } : item
    );
    handleInputChange('freightChargeTerms', updatedTerms);
  };

  const FullScreenSpinner = () => {
    return (
      <div className="full-screen-spinner-overlay">
        <div className="spinner"></div>
      </div>
    );
  };

  return (

    <div style={{
      background: '#f0f2f8',
      minHeight: '91vh'
    }}>
      {loading ? (
        // <ClipLoader color="#36d7b7" />
        <FullScreenSpinner />
      ) : (
        <div className='container'>
          <div className='row place-center'>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 header mb-3">
              <div className='row'>
                {formData.docType ? (
                  <>
                    <div className='col-sm-12 col-md-6 col-lg-6'>
                      {formData.docType === 'BOL' && <span> <ReceiptLongIcon /> Bill of Lading</span>}
                      {formData.docType === 'DA' && <span> <ReceiptLongIcon /> Delivery Alerts</span>}
                      {formData.docType === 'PA' && <span> <ReceiptLongIcon /> Pickup Alerts</span>}
                      {formData.docType === 'POD' && <span> <ReceiptLongIcon /> Proof of Delivery</span>}
                      <span> - </span>
                      <span className='form-status'>{formData.status}</span>
                    </div><div className='col-sm-12 col-md-6 col-lg-6'>
                      {(isEditing) && (
                        <Button className='Cancel-btn fl-r' variant="contained" onClick={handleCancelClick}><CancelIcon className='icon-btn' />Cancel</Button>
                      )}
                      {(!isEditing && formData.status !== 'Validated') && (

                        <Button className='edit-btn fl-r' disabled={formData.status === 'Validated'} variant="contained" onClick={invalid}><EditIcon className='icon-btn' />Edit</Button>
                      )}
                      <Button className={formData.status === 'Validated' ? 'disable-validate-btn fl-r' : 'validate-btn fl-r'} disabled={formData.status === 'Validated'} variant="contained" onClick={validate}><CheckIcon className='icon-btn' />Validate</Button>
                    </div>
                  </>
                ) :
                  (
                    <span>Form Details</span>
                  )
                }
              </div>
            </div>
            {hasData ? (
              <div className="row p-0">
                <div className='accordian-mui'>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <span className="header-text"><ReceiptIcon />Shipment Details</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='row pb-3'>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          {/* <SelectionTag name='Bill of Lading Number'></SelectionTag> */}
                          <span className='font-bold'>Bill of Lading Number</span>

                          <TextField id="bolNumber" placeholder={docValid ? '' : 'Enter BOL number'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.bolNumber}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('bolNumber', e.target.value)}
                          />
                        </div>

                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Master Bill of Lading Number</span>

                          <TextField id="masterBolNumber" placeholder={docValid ? '' : 'Enter Master BOL number'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.masterBolNumber}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('masterBolNumber', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Shipment ETA</span>

                          <TextField id="shipmentEta" placeholder={docValid ? '' : 'Enter Shipment ETA'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.shipmentEta}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('shipmentEta', e.target.value)}
                          />
                        </div>

                      </div>
                      <div className='row pb-3'>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Shipment Date</span>

                          <TextField id="shipmentDate" placeholder={docValid ? '' : 'Enter Shipment Date'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.shipmentDate}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('shipmentDate', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Shipment Time</span>

                          <TextField id="shipmentTime" placeholder={docValid ? '' : 'Enter Shipment Time'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.shipmentTime}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('shipmentTime', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Origin</span>

                          <TextField id="shipmentOrigin" placeholder={docValid ? '' : 'Enter Shipment Origin'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.shipmentOrigin}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('shipmentOrigin', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Destination</span>

                          <TextField id="shipmentDestination" placeholder={docValid ? '' : 'Enter Shipment Destination'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.shipmentDestination}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('shipmentDestination', e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='row pb-3'>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Pickup Contact Name</span>

                          <TextField id="pickupContactName" placeholder={docValid ? '' : 'Enter Contact Name'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.pickupContactName}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('pickupContactName', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Pickup Phone Number</span>

                          <TextField id="pickupPhoneNumbr" placeholder={docValid ? '' : 'Enter Phone Number'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.pickupPhoneNumbr}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('pickupPhoneNumbr', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Pickup Address</span>

                          <TextField id="pickupAddress" placeholder={docValid ? '' : 'Enter Address'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.pickupAddress}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('pickupAddress', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Pickup Location</span>

                          <TextField id="pickupLocation" placeholder={docValid ? '' : 'Enter Location'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.pickupLocation}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('pickupLocation', e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='row pb-3'>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Pickup Ready Time</span>

                          <TextField id="pickupReadyTime" placeholder={docValid ? '' : 'Enter Pickup Time'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.pickupReadyTime}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('pickupReadyTime', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Pickup Close Time</span>

                          <TextField id="pickupCloseTime" placeholder={docValid ? '' : 'Enter Close Time'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.pickupCloseTime}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('pickupCloseTime', e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='row pb-3'>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Shipment Pickup</span>

                          <TextField id="shipmentPickup" placeholder={docValid ? '' : 'Enter Shipment Pickup'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.shipmentPickup}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('shipmentPickup', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Shipment Service Level</span>

                          <TextField id="shipmentServiceLevel" placeholder={docValid ? '' : 'Enter Shipment Service Level'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.shipmentServiceLevel}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('shipmentServiceLevel', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Shipment Delivery</span>

                          <TextField id="shipmentDelivery" placeholder={docValid ? '' : 'Enter Shipment Delivery'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.shipmentDelivery}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('shipmentDelivery', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Shipment Requester Phone</span>

                          <TextField id="shipmentRequesterPhone" placeholder={docValid ? '' : 'Enter Shipment Requester Phone'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.shipmentRequesterPhone}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('shipmentRequesterPhone', e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='row pb-3'>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Shipment Requestor Fax</span>

                          <TextField id="ShipmentRequesterFax" placeholder={docValid ? '' : 'Enter Shipment Requestor Fax'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.ShipmentRequesterFax}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('ShipmentRequesterFax', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Shipment Requestor Address</span>

                          <TextField id="shipmentRequesterAddress" placeholder={docValid ? '' : 'Enter Shipment Requestor Address'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.shipmentRequesterAddress}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('shipmentRequesterAddress', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Service Level</span>

                          <TextField id="serviceLevel" placeholder={docValid ? '' : 'Enter Service Level'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.serviceLevel}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('serviceLevel', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                          <span className='font-bold'>Payment Terms</span>

                          <TextField id="paymentTerms" placeholder={docValid ? '' : 'Enter Payment Terms'} variant="filled" disabled={docValid}
                            type="text"
                            value={formData.paymentTerms}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            onChange={(e) => handleInputChange('paymentTerms', e.target.value)}
                          />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className='accordian-mui'>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <span className="header-text"> <LocalShippingIcon /> Shipper Details</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='row pb-3'>
                        <div className='col-sm-12 col-md-6 col-lg-3'>
                          <label className='font-bold'>Name</label>
                          <TextField id="shipperName" placeholder={docValid ? '' : 'Enter Name'} variant="filled" disabled={docValid}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            type="text"
                            value={formData.shipperName}
                            onChange={(e) => handleInputChange('shipperName', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-3'>
                          <label className='font-bold'>Address</label>
                          <TextField id="shipperAddress" placeholder={docValid ? '' : 'Enter Address'} variant="filled" disabled={docValid}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            type="text"
                            value={formData.shipperAddress}
                            onChange={(e) => handleInputChange('shipperAddress', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-3'>
                          <label className='font-bold'>Contact</label>
                          <TextField id="shipperContact" placeholder={docValid ? '' : 'Enter Contact'} variant="filled" disabled={docValid}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            type="text"
                            value={formData.shipperContact ? formData.shipperContact : ''}
                            onChange={(e) => handleInputChange('shipperContact', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-3'>
                          <label className='font-bold'>Phone</label>
                          <TextField id="shipperPhone" placeholder={docValid ? '' : 'Enter Phone Number'} variant="filled" disabled={docValid}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            type="text"
                            value={formData.shipperPhone}
                            onChange={(e) => handleInputChange('shipperPhone', e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-sm-12 col-md-6 col-lg-3'>
                          <label className='font-bold'>Fax</label>
                          <TextField id="shipperFax" placeholder={docValid ? '' : 'Enter Fax Number'} variant="filled" disabled={docValid}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            type="text"
                            value={formData.shipperFax}
                            onChange={(e) => handleInputChange('shipperFax', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-3'>
                          <label className='font-bold'>Requested By</label>
                          <TextField id="shipperRequestedby" placeholder={docValid ? '' : 'Enter Requested by'} variant="filled" disabled={docValid}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            type="text"
                            value={formData.shipperRequestedby}
                            onChange={(e) => handleInputChange('shipperRequestedby', e.target.value)}
                          />
                        </div>

                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className='accordian-mui'>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <span className="header-text"> <ContactsIcon />Consignee Details</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='row'>
                        <div className='col-sm-12 col-md-6 col-lg-3'>
                          <label className='font-bold'>Name</label>
                          <TextField id="consigneeName" placeholder={docValid ? '' : 'Enter Name'} variant="filled" disabled={docValid}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            type="text"
                            value={formData.consigneeName}
                            onChange={(e) => handleInputChange('consigneeName', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-3'>
                          <label className='font-bold'>Address</label>
                          <TextField id="consigneeAddress" placeholder={docValid ? '' : 'Enter Address'} variant="filled" disabled={docValid}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            type="text"
                            value={formData.consigneeAddress}
                            onChange={(e) => handleInputChange('consigneeAddress', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-3'>
                          <label className='font-bold'>Contact</label>
                          <TextField id="consigneeContact" placeholder={docValid ? '' : 'Enter Contact'} variant="filled" disabled={docValid}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            type="text"
                            value={formData.consigneeContact}
                            onChange={(e) => handleInputChange('consigneeContact', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-3'>
                          <label className='font-bold'>Phone Number</label>
                          <TextField id="consigneePhone" placeholder={docValid ? '' : 'Enter Phone number'} variant="filled" disabled={docValid}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            type="text"
                            value={formData.consigneePhone}
                            onChange={(e) => handleInputChange('consigneePhone', e.target.value)}
                          />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className='accordian-mui'>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <span className="header-text"> <AccountBalanceIcon />  3rd Party Bill to Details</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='row'>
                        <div className='col-sm-12 col-md-6 col-lg-3'>
                          <label className='font-bold'>Carrier Name</label>
                          <TextField id="billToName" placeholder={docValid ? '' : 'Enter Carrier Name'} variant="filled" disabled={docValid}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            type="text"
                            value={formData.billToName}
                            onChange={(e) => handleInputChange('billToName', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-3'>
                          <label className='font-bold'>Address</label>
                          <TextField id="billToAddress" placeholder={docValid ? '' : 'Enter Address'} variant="filled" disabled={docValid}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            type="text"
                            value={formData.billToAddress}
                            onChange={(e) => handleInputChange('billToAddress', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-3'>
                          <label className='font-bold'>Contact</label>
                          <TextField id="billToContact" placeholder={docValid ? '' : 'Enter Contact'} variant="filled" disabled={docValid}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            type="text"
                            value={formData.billToContact}
                            onChange={(e) => handleInputChange('billToContact', e.target.value)}
                          />
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-3'>
                          <label className='font-bold'>Phone</label>
                          <TextField id="billToPhone" placeholder={docValid ? '' : 'Enter Phone number'} variant="filled" disabled={docValid}
                            InputProps={{
                              className: classes.input,
                            }}
                            className='col-12'
                            type="text"
                            value={formData.billToPhone}
                            onChange={(e) => handleInputChange('billToPhone', e.target.value)}
                          />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className='accordian-mui'>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <span className="header-text"><PaddingIcon />Additional Details</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='row'>
                        <div className='col-12'>
                          <label className='font-bold'>Freight Charge Terms</label>
                          {freightChargeTerms ? (
                            <div>
                              {renderCheckboxes()}
                            </div>
                          ) : <textarea
                            disabled
                            value='Not Found'
                          ></textarea>
                          }
                        </div>
                        <div className='col-12'>
                          <label className='font-bold'>Special Instructions</label>
                          <textarea
                            id="instructions"
                            disabled={docValid}
                            value={formData.instructions}
                            onChange={(e) => handleInputChange('instructions', e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                {formData.docType === 'POD' && (
                  <div className='accordian-mui'>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <span className="header-text"><PaddingIcon />Delivery Details</span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className='row pb-3'>
                          <div className='col-sm-12 col-md-6 col-lg-3'>
                            <label className='font-bold'>Shipper Sign Date</label>
                            <TextField id="shipperSignDate" placeholder={docValid ? '' : 'Enter Date'} variant="filled" disabled={docValid}
                              InputProps={{
                                className: classes.input,
                              }}
                              className='col-12'
                              type="text"
                              value={formData.shipperSignDate}
                              onChange={(e) => handleInputChange('shipperSignDate', e.target.value)}
                            />
                          </div>
                          <div className='col-sm-12 col-md-6 col-lg-3'>
                            <label className='font-bold'>Shipper Sign Time</label>
                            <TextField id="shipperSignTime" placeholder={docValid ? '' : 'Enter Time'} variant="filled" disabled={docValid}
                              InputProps={{
                                className: classes.input,
                              }}
                              className='col-12'
                              type="text"
                              value={formData.shipperSignTime}
                              onChange={(e) => handleInputChange('shipperSignTime', e.target.value)}
                            />
                          </div>
                          <div className='col-sm-12 col-md-6 col-lg-3'>
                            <label className='font-bold'>Shipper Sign</label>
                            <TextField id="shipperSignExists" placeholder={docValid ? '' : 'Enter Yes or No'} variant="filled" disabled={docValid}
                              InputProps={{
                                className: classes.input,
                              }}
                              className='col-12'
                              type="text"
                              value={formData.shipperSignExists}
                              onChange={(e) => handleInputChange('shipperSignExists', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-sm-12 col-md-6 col-lg-3'>
                            <label className='font-bold'>Consignee Sign Date</label>
                            <TextField id="consigneeSignDate" placeholder={docValid ? '' : 'Enter Date'} variant="filled" disabled={docValid}
                              InputProps={{
                                className: classes.input,
                              }}
                              className='col-12'
                              type="text"
                              value={formData.consigneeSignDate}
                              onChange={(e) => handleInputChange('consigneeSignDate', e.target.value)}
                            />
                          </div>
                          <div className='col-sm-12 col-md-6 col-lg-3'>
                            <label className='font-bold'>Consignee Sign Time</label>
                            <TextField id="consigneeSignTime" placeholder={docValid ? '' : 'Enter Time'} variant="filled" disabled={docValid}
                              InputProps={{
                                className: classes.input,
                              }}
                              className='col-12'
                              type="text"
                              value={formData.consigneeSignTime}
                              onChange={(e) => handleInputChange('consigneeSignTime', e.target.value)}
                            />
                          </div>
                          <div className='col-sm-12 col-md-6 col-lg-3'>
                            <label className='font-bold'>Consignee Sign</label>
                            <TextField id="consigneeSignExists" placeholder={docValid ? '' : 'Enter Yes or No'} variant="filled" disabled={docValid}
                              InputProps={{
                                className: classes.input,
                              }}
                              className='col-12'
                              type="text"
                              value={formData.consigneeSignExists}
                              onChange={(e) => handleInputChange('consigneeSignExists', e.target.value)}
                            />
                          </div>
                        </div>
                      </AccordionDetails>

                    </Accordion>
                  </div>
                )}
                <div className='accordian-mui' style={{ 'padding-bottom': '2%' }}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <span className="header-text"><PostAddIcon />Shipment Item Details</span>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='row'>
                        {formData && formData.itemDetails && (
                          <TableDisplay itemDetails={formData.itemDetails} />
                        )}
                        <div className='col-12'>
                          {(formData.itemDetails && formData.itemDetails.length > 0) && (
                            <div className="table-container">
                              {/* <TableContainer component={Paper}>
                                <Table>
                                  <TableHead>{renderTableHeaders()}</TableHead>
                                  <TableBody>{renderTableRows()}</TableBody>
                                </Table>
                              </TableContainer> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                <div className='text-center'>
                  {isDataChanged && (
                    <button onClick={handleSave} className='col-sm-9 col-md-5 col-lg-3 save-button'>Save Changes</button>
                  )}
                  {!isDataChanged && (
                    null
                  )}
                </div>
              </div>
            ) : (
              <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-7'>No record found for unique ID : {id}</div>
            )}

            <Modal
              style={customStyles}
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              contentLabel="Confirm Changes Modal"
            >
              <h2>Confirm Changes</h2>
              <div>
                <p>Are you sure you want to save the following changes?</p>
                <div>
                  {Object.entries(changedFields).map(([key, value]) => (
                    <div className='mb-2'>
                      <div key={key}>{`${key} : ${originalFormData[key]}`}</div>
                      <div key={key}>{`Updated: ${value}`}</div>
                    </div>
                  ))}
                </div>
              </div>
              <button onClick={saveChanges} className='save-button confirm-button mt-3'>Yes</button>
              <button onClick={closeModal} className='save-button Cancel-btn confirm-button'>No</button>
            </Modal>

            <Modal
              style={customStyles}
              isOpen={isCancelModalOpen}
              onRequestClose={cancelCloseModal}
              contentLabel="Confirm Changes Modal"
            >
              <h2>Cancel Changes</h2>
              <div>
                <p>Are you sure you want to cancel the following changes?</p>
                <div>
                  {Object.entries(changedFields).map(([key, value]) => (
                    <div className='mb-2'>
                      <div key={key}>{`${key} : ${originalFormData[key]}`}</div>
                      <div key={key}>{`Updated: ${value}`}</div>
                    </div>
                  ))}
                </div>
              </div>
              <button onClick={cancelChanges} className='save-button confirm-button mt-3'>Yes</button>
              <button onClick={cancelCloseModal} className='save-button Cancel-btn confirm-button'>No</button>
            </Modal>
          </div>
          <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            message={message}
          />
        </div>
      )}
    </div>
  );
};

export default FreightForm;