import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Button, Card, CardContent, Typography, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LoginIcon from '@mui/icons-material/Login';
import './../../App.css';
import './SignIn.css';
import { CognitoUser, AuthenticationDetails, CognitoUserPool } from 'amazon-cognito-identity-js';
import SessionService from '../../services/SessionService';
import { userLoginActivity } from '../../Helper/api';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';
import { Visibility, VisibilityOff } from '@mui/icons-material'

const SignIn = ({ onLoginSuccess }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isSignIn, setIsSignIn] = useState(true);
    const navigate = useNavigate();
    const [animate, setAnimate] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    useEffect(() => {
        setAnimate(true);
    }, []);

    const poolData = {
        UserPoolId: process.env.REACT_APP_USER_POOL_ID,
        ClientId: process.env.REACT_APP_CLIENT_ID
    };

    const userPool = new CognitoUserPool(poolData);

    const handleSignIn = (e) => {
        e.preventDefault();

        const authenticationData = {
            Username: username,
            Password: password
        };

        const authenticationDetails = new AuthenticationDetails(authenticationData);

        const userData = {
            Username: username,
            Pool: userPool
        };

        const cognitoUser = new CognitoUser(userData);

        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: async (session) => {
                console.log(session.getIdToken());
                SessionService.setCompanyName(session.getIdToken().payload['custom:company']);
                localStorage.setItem('companyName', session.getIdToken().payload['custom:company']);
                localStorage.setItem('user_id', session.getIdToken().payload['sub']);
                SessionService.setCognitoGroups(session.getIdToken().payload['cognito:groups']);
                session.getIdToken().payload['cognito:groups'].forEach(element => {
                    if (!element.includes('_module')) {
                        localStorage.setItem('user_role', element);
                    }
                });
                console.log(localStorage.getItem('user_role'));
                SessionService.setAuthenticated(true);
                await userLoginActivity();
                onLoginSuccess(session);
                navigate('/dashboard');
            },
            onFailure: (err) => {
                SessionService.setAuthenticated(false);
                console.error('Authentication failed:', err);
                alert('Invalid username or password');
            },
            newPasswordRequired: (userAttributes, requiredAttributes) => {
                SessionService.setAuthenticated(false);
                alert('New Password Required');
            }
        });
    };

    useEffect(() => {
        const storedAuth = localStorage.getItem('authenticated');
        if (storedAuth) {
            navigate('/dashboard');
        }
    }, [navigate]);

    return (
        <div className={`container-fluid p-0 m-0 ${animate ? 'fade-in' : ''}`}>
            <div className="row" style={{ height: '93vh' }}>
                <div className="col-md-5 info-panel login-background">
                    <Typography variant="h3" className='mt-3'>{isSignIn ? "Welcome aboard!" : "Welcome Back!"}</Typography>
                    <Typography className='mt-3'>
                        {isSignIn ? (
                            <>
                                <h6>
                                    Let's get started
                                </h6>
                                <h6>
                                    Sign up and unlock exclusive benefits!
                                </h6>
                            </>
                        ) : (
                            <>
                                <h6>
                                    To keep connected with us
                                </h6>
                                <h6>
                                    Enter your credentials to access your account.
                                </h6>
                            </>
                        )}
                    </Typography>
                    <Link className='link' to="/Register">
                        <Button
                            variant="contained"
                            className='signup-btn mt-3'
                            startIcon={<LoginIcon />}
                            onClick={() => setIsSignIn(!isSignIn)}
                        >
                            Sign Up
                        </Button>
                    </Link>
                </div>
                <div className="col-md-7 form-panel div-center mt-3 login-content">
                    <h2 className='font-bold'>Login to your Account</h2>
                    <Card className='col-8 mt-3'>
                        <CardContent>
                            <form onSubmit={handleSignIn}>
                                <div className="input-container">
                                    <label htmlFor="username">Username</label>
                                    <div className="input-with-icon">
                                        <PersonIcon className='login-icons' />
                                        <input
                                            type="text"
                                            id="username"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            required
                                            className='login-input'
                                        />
                                    </div>
                                </div>
                                <div className="input-container">
                                    <label htmlFor="password">Password</label>
                                    <div className="input-with-icon">
                                        <LockIcon className='login-icons' />
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            className='login-input'
                                            id="password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                        {showPassword ? (
                                            <VisibilityOff
                                                className="visibility-icon"
                                                onClick={togglePasswordVisibility}
                                            />
                                        ) : (
                                            <Visibility
                                                className="visibility-icon"
                                                onClick={togglePasswordVisibility}
                                            />
                                        )}
                                    </div>
                                </div>
                                <Button variant='contained' className='login theme-bg' type="submit">
                                    <span className='text-trans-none'>
                                        Login
                                    </span>
                                </Button>
                            </form>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default SignIn;
