import React from 'react';
import { Checkbox, CircularProgress } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import './ColumnsConfig.css';

const renderDatePickerCell = (params, field, editingId, handleFieldChange, formatDateToISOString, formatDate) => {
    const isEditing = editingId === params.row.id;
    return (
        <>
            {isEditing ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        defaultValue={dayjs(formatDate(params.value))}
                        className="input-field"
                        onChange={(date) => handleFieldChange(field, formatDateToISOString(new Date(date.$d)))}
                    />
                </LocalizationProvider>
            ) : (
                <div>{formatDate(params.value)}</div>
            )}
        </>
    );
};

const renderTextCell = (params, field, editingId, handleFieldChange) => {
    const isEditing = editingId === params.row.id;
    return (
        <>
            {isEditing ? (
                <input
                    type="text"
                    placeholder={params.row[field]}
                    onChange={(e) => handleFieldChange(field, e.target.value)}
                    className="input-field"
                />
            ) : (
                params.row[field]
            )}
        </>
    );
};

const handleOpenInvoice = (params, handleOpenInvoicePopup) => {
    localStorage.setItem('invoiceDetails', JSON.stringify(params.row));
    handleOpenInvoicePopup();
};

export const getInvoiceColumns = ({ selectedRows, handleRowSelect, userRole, editingId, handleSave, handleCancelEdit, handleEditClick, handleFieldChange, handleOpenPdfModal, formatDate, formatDateToISOString }) => [
    // { field: 'id', headerName: 'ID', width: 80 },
    {
        field: 'select',
        headerName: 'Select',
        width: 100,
        renderCell: (params) => {
            const isSelected = selectedRows.includes(params.row.invoice_uuid);
            return (
                <Checkbox
                    checked={isSelected}
                    onChange={() => handleRowSelect(params.row.invoice_uuid)}
                    color="primary"
                />
            );
        },
    },
    {
        field: 'pdfs',
        headerName: 'Pdfs',
        width: 70,
        renderCell: (params) => (
            <OpenInNewIcon
                onClick={() => handleOpenPdfModal(params)}
                style={{ cursor: 'pointer' }}
            ></OpenInNewIcon>
        ),
    },
    {
        field: 'invoice_date',
        headerName: 'Date',
        width: 150,
        valueGetter: (params) => {
            return new Date(params.value);
        },
        renderCell: (params) => renderDatePickerCell(params, 'invoice_date', editingId, handleFieldChange, formatDateToISOString, formatDate),
        type: 'date'
    },
    {
        field: 'invoice_number', headerName: 'Invoice No.', width: 100,
        renderCell: (params) => renderTextCell(params, 'invoice_number', editingId, handleFieldChange)
    },
    {
        field: 'order_number', headerName: 'P.O No.', width: 150,
        renderCell: (params) => renderTextCell(params, 'order_number', editingId, handleFieldChange)
    },
    {
        field: 'debtor', headerName: 'Debtors Name', width: 240,
        renderCell: (params) => renderTextCell(params, 'debtor', editingId, handleFieldChange)
    },
    {
        field: 'amount_due', headerName: 'Invoice Amount', width: 150,
        renderCell: (params) => renderTextCell(params, 'amount_due', editingId, handleFieldChange)
    },
    userRole === 'ADMIN'
        ? {
            field: 'edit',
            headerName: 'Edit',
            width: 90,
            renderCell: (params) => {
                const isEditing = editingId === params.row.id;
                return (
                    <div>
                        {isEditing ? (
                            <>
                                <CheckCircleIcon
                                    style={{ cursor: 'pointer', marginRight: 8 }}
                                    onClick={() => handleSave('invoice', params.row.id)}
                                    className='theme-color'
                                />
                                <CancelIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleCancelEdit()}
                                    className='theme-color'
                                />
                            </>
                        ) : (
                            <EditIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleEditClick(params.row.id, params.row)}
                                className='theme-color'
                            />
                        )}
                    </div>
                );
            },
        }
        : null,
    {
        field: 'documentsStatus',
        headerName: 'Documents Status',
        minWidth: 150,
        renderCell: (params) => {
            const { invoice_exists, pod_exists, rate_confirmation_exists } = params.row;

            const isAllTrue = invoice_exists === 'TRUE' && pod_exists === 'TRUE' && rate_confirmation_exists === 'TRUE';

            const iconColor = isAllTrue ? 'green' : 'red';

            const statusText = `
                Invoice: ${invoice_exists === 'TRUE' ? 'Available' : 'Unavailable'}<br />
                POD: ${pod_exists === 'TRUE' ? 'Available' : 'Unavailable'}<br />
                Rate Confirmation: ${rate_confirmation_exists === 'TRUE' ? 'Available' : 'Unavailable'}
            `;

            return (
                <>
                    <div
                        data-tooltip-id="my-tooltip-multiline"
                        data-tooltip-html={statusText}
                    >
                        {isAllTrue ?
                            (<CheckCircleIcon style={{ color: iconColor }} />) :
                            (<InfoIcon style={{ color: iconColor }} />)
                        }
                    </div>
                    <Tooltip id="my-tooltip-multiline" />
                </>
            );
        },
    },
    { field: 'user_name', headerName: 'User Name', width: 200 },
    {
        field: 'status', headerName: 'Status', width: 200,
        renderCell: (params) => {
            const { status } = params.row;
            return (
                <>
                    {status === 'new' ? (
                        <>
                            <span>Processing <CircularProgress className='cir-progress-table' /></span>
                        </>
                    ) : (
                        <span>Processed</span>
                    )}
                </>
            );
        }
    },
].filter(Boolean);

export const getRateConfirmationColumns = ({ selectedRows, handleRowSelect, userRole, editingId, handleSave, handleCancelEdit, handleEditClick, handleFieldChange, handleOpenPdfModal, formatDate, formatDateToISOString }) => [
    { field: 'id', headerName: 'ID', width: 100 },
    {
        field: 'pdfs',
        headerName: 'Pdfs',
        width: 70,
        renderCell: (params) => (
            <OpenInNewIcon
                onClick={() => handleOpenPdfModal(params)}
                style={{ cursor: 'pointer' }}
            ></OpenInNewIcon>
        ),
    },
    {
        field: 'file_name', headerName: 'File Name', width: 250
    },
    {
        field: 'order_number', headerName: 'Order Number', width: 200,
        renderCell: (params) => renderTextCell(params, 'rc_order', editingId, handleFieldChange)
    },
    userRole === 'ADMIN'
        ? {
            field: 'edit',
            headerName: 'Edit',
            width: 90,
            renderCell: (params) => {
                const isEditing = editingId === params.row.id;
                return (
                    <div>
                        {isEditing ? (
                            <>
                                <CheckCircleIcon
                                    style={{ cursor: 'pointer', marginRight: 8 }}
                                    onClick={() => handleSave('rateConfirmation', params.row.id)}
                                    className='theme-color'
                                />
                                <CancelIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleCancelEdit()}
                                    className='theme-color'
                                />
                            </>
                        ) : (
                            <EditIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleEditClick(params.row.id, params.row)}
                                className='theme-color'
                            />
                        )}
                    </div>
                );
            },
        }
        : null,
    {
        field: 'rc_date',
        headerName: 'Created Date',
        width: 150,
        valueGetter: (params) => {
            return new Date(params.value);
        },
        renderCell: (params) => formatDate(params.value),
        type: 'date',
    },
    {
        field: 'documentsStatus',
        headerName: 'Documents Status',
        minWidth: 150,
        renderCell: (params) => {
            const { invoice_exists, pod_exists, rate_confirmation_exists } = params.row;

            const isAllTrue = invoice_exists === 'TRUE' && pod_exists === 'TRUE' && rate_confirmation_exists === 'TRUE';

            const iconColor = isAllTrue ? 'green' : 'red';

            const statusText = `
                Invoice: ${invoice_exists === 'TRUE' ? 'Available' : 'Unavailable'}<br />
                POD: ${pod_exists === 'TRUE' ? 'Available' : 'Unavailable'}<br />
                Rate Confirmation: ${rate_confirmation_exists === 'TRUE' ? 'Available' : 'Unavailable'}
            `;

            return (
                <>
                    <div
                        data-tooltip-id="my-tooltip-multiline"
                        data-tooltip-html={statusText}
                    >
                        {isAllTrue ?
                            (<CheckCircleIcon style={{ color: iconColor }} />) :
                            (<InfoIcon style={{ color: iconColor }} />)
                        }
                    </div>
                    <Tooltip id="my-tooltip-multiline" />
                </>
            );
        },
    },
    { field: 'user_name', headerName: 'User Name', width: 200 },
    {
        field: 'status', headerName: 'Status', width: 200,
        renderCell: (params) => {
            const { status } = params.row;
            return (
                <>
                    {status === 'new' ? (
                        <>
                            <span>Processing <CircularProgress className='cir-progress-table' /></span>
                        </>
                    ) : (
                        <span>Processed</span>
                    )}
                </>
            );
        }
    },
].filter(Boolean);

export const getPodColumns = ({ selectedRows, handleRowSelect, userRole, editingId, handleSave, handleCancelEdit, handleEditClick, handleFieldChange, handleOpenPdfModal, formatDate, formatDateToISOString }) => [
    { field: 'id', headerName: 'ID', width: 100 },
    {
        field: 'pdfs',
        headerName: 'Pdfs',
        width: 70,
        renderCell: (params) => (
            <OpenInNewIcon
                onClick={() => handleOpenPdfModal(params)}
                style={{ cursor: 'pointer' }}
            ></OpenInNewIcon>
        ),
    },
    {
        field: 'file_name', headerName: 'File Name', width: 250
    },
    {
        field: 'order_number', headerName: 'Order Number', width: 200,
        renderCell: (params) => renderTextCell(params, 'pod_order', editingId, handleFieldChange)
    },
    userRole === 'ADMIN'
        ? {
            field: 'edit',
            headerName: 'Edit',
            width: 90,
            renderCell: (params) => {
                const isEditing = editingId === params.row.id;
                return (
                    <div>
                        {isEditing ? (
                            <>
                                <CheckCircleIcon
                                    style={{ cursor: 'pointer', marginRight: 8 }}
                                    onClick={() => handleSave('pod', params.row.id)}
                                    className='theme-color'
                                />
                                <CancelIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => handleCancelEdit()}
                                    className='theme-color'
                                />
                            </>
                        ) : (
                            <EditIcon
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleEditClick(params.row.id, params.row)}
                                className='theme-color'
                            />
                        )}
                    </div>
                );
            },
        }
        : null,
    {
        field: 'pod_date',
        headerName: 'Created Date',
        width: 150,
        valueGetter: (params) => {
            return new Date(params.value);
        },
        renderCell: (params) => formatDate(params.value),
        type: 'date',
    },
    {
        field: 'documentsStatus',
        headerName: 'Documents Status',
        minWidth: 150,
        renderCell: (params) => {
            const { invoice_exists, pod_exists, rate_confirmation_exists } = params.row;

            const isAllTrue = invoice_exists === 'TRUE' && pod_exists === 'TRUE' && rate_confirmation_exists === 'TRUE';

            const iconColor = isAllTrue ? 'green' : 'red';

            const statusText = `
                Invoice: ${invoice_exists === 'TRUE' ? 'Available' : 'Unavailable'}<br />
                POD: ${pod_exists === 'TRUE' ? 'Available' : 'Unavailable'}<br />
                Rate Confirmation: ${rate_confirmation_exists === 'TRUE' ? 'Available' : 'Unavailable'}
            `;

            return (
                <>
                    <div
                        data-tooltip-id="my-tooltip-multiline"
                        data-tooltip-html={statusText}
                    >
                        {isAllTrue ?
                            (<CheckCircleIcon style={{ color: iconColor }} />) :
                            (<InfoIcon style={{ color: iconColor }} />)
                        }
                    </div>
                    <Tooltip id="my-tooltip-multiline" />
                </>
            );
        },
    },
    { field: 'user_name', headerName: 'User Name', width: 200 },
    {
        field: 'status', headerName: 'Status', width: 200,
        renderCell: (params) => {
            const { status } = params.row;
            return (
                <>
                    {status === 'new' ? (
                        <>
                            <span>Processing <CircularProgress className='cir-progress-table' /></span>
                        </>
                    ) : (
                        <span>Processed</span>
                    )}
                </>
            );
        }
    },
].filter(Boolean);

export const getShipmentDetailsColumns = ({ handleOpenPdfModal, formatDate, handleOpenInvoicePopup, handleOpenEditDialog, handleOpenDeleteDialog }) => [
    {
        field: 'actions',
        headerName: 'Actions',
        width: 150,
        renderCell: (params) => (
            <>
                <Tooltip title="Create Invoice">
                    <AddCircleOutlineIcon
                        onClick={() => handleOpenInvoice(params, handleOpenInvoicePopup)}
                        className='pointer br-20p ml-3 create-icon'
                    />
                </Tooltip>
                <Tooltip title="Edit Shipment">
                    <EditIcon
                        onClick={() => handleOpenEditDialog(params)}
                        className='pointer br-20p ml-3 edit-icon'
                    />
                </Tooltip>
                <Tooltip title="Delete">
                    <DeleteIcon
                        onClick={() => handleOpenDeleteDialog(params)}
                        className='pointer br-20p ml-3 delete-icon'
                    />
                </Tooltip>
            </>
        ),
    },
    {
        field: 'created_date',
        headerName: 'Created Date',
        width: 150,
        valueGetter: (params) => {
            return new Date(params.value);
        },
        renderCell: (params) => formatDate(params.value),
        type: 'date',
    },
    {
        field: 'updated_date',
        headerName: 'Updated Date',
        width: 150,
        valueGetter: (params) => {
            return new Date(params.value);
        },
        renderCell: (params) => formatDate(params.value),
        type: 'date',
    },
    { field: 'created_by', headerName: 'Created By', width: 150 },
    { field: 'truck_number', headerName: 'Truck Number', width: 150 },
    { field: 'dispatcher', headerName: 'Dispatcher', width: 150 },
    { field: 'driver', headerName: 'Driver', width: 150 },
    { field: 'from_city', headerName: 'From City', width: 150 },
    { field: 'to_city', headerName: 'To City', width: 150 },
    { field: 'order_number', headerName: 'Order Number', width: 200 },
    { field: 'debtor', headerName: 'Debtor', width: 150 },
    { field: 'phone_number', headerName: 'Phone Number', width: 150 },
    { field: 'bol_number', headerName: 'BOL Number', width: 150 },
    { field: 'rate', headerName: 'Rate', width: 100 },
    { field: 'invoice_number', headerName: 'Invoice Number', width: 150 }
].filter(Boolean);