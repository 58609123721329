import React from 'react';
import { Link } from 'react-router-dom';
import "./LandingPage.css"
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import LinkIcon from '@mui/icons-material/Link';
import EmailIcon from '@mui/icons-material/Email';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import GroupIcon from '@mui/icons-material/Group';
import InsightsIcon from '@mui/icons-material/Insights';
import BusinessIcon from '@mui/icons-material/Business';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import EngineeringIcon from '@mui/icons-material/Engineering';
import blackAndWhiteIcon from './../../assets/b_and_w_image.png';
import laptopImage from './../../assets/laptop_image.png';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const LandingPage = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className='scrollable-container'>
      <section className="bg-white">
        <div className="grid py-8 px-4 mx-auto max-w-screen-xl lg-gap-8 xl-gap-0 lg-py-16 lg-grid-cols-12">
          <div className="place-self-center mr-auto lg-col-span-7">
            <h3 className="relative mb-4 max-w-2xl text-4xl leading-none md-text-5xl xl-text-6xl">
              <AutoAwesomeIcon
                style={{ position: 'relative', top: '-23px', marginRight: '-1px', fontSize: '2rem' }} className='text-primary-600'
              />
              <span className='ai-powered-text'>AI Powered</span> Solution for Managing Your Documents
            </h3>

            <p className="mb-6 max-w-2xl font-light text-gray-500 lg-mb-8 md-text-lg lg-text-xl">
              Revolutionize freight document processing with Cartage Manager. Detect and map invoices, rate confirmations, proof of delivery, and more. Simplify your logistics workflow for improved efficiency today
            </p>
            <Link
              to="/Register"
              className="inline-flex justify-center items-center py-3 px-5 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover-bg-primary-800"
            >
              Get Started
            </Link>
            <Link
              to="/contact-cm"
              className="inline-flex justify-center items-center py-3 px-5 mr-3 text-base font-medium text-center text-gray-900 rounded-lg border border-gray-300 hover-bg-primary-100"
            >
              Request Demo
            </Link>
          </div>
          <div className="hidden lg-mt-0 lg-col-span-5 lg-flex">
            <img className="img-dim" src={blackAndWhiteIcon} alt="Truck Driver" />
          </div>
        </div>
      </section>

      <section className="bg-gray-50">
        <div className='py-8'>
          <h4 className="text-3xl mt-4 tracking-tight leading-tight text-center text-blue-600 md-text-4xl">Why Choose Us?</h4>
          <div className="grid py-8 px-4 mx-auto max-w-screen-xl lg-gap-8 xl-gap-0 lg-grid-cols-12">
            <div className="vertical-blue-line mx-auto place-self-center h-64 lg-col-span-5 relative">
              <div className="absolute"></div>
              <h1 className="mb-6 pr-4 max-w-2xl font-light text-grey-600 lg-mb-8 text-4xl">
                Reasons to Choose Cartage Manager for Your Logistics Document Management Needs
              </h1>
            </div>
            <div className="lg-mt-0 lg-col-span-7 lg-flex flex-col p-4">
              <p class="mb-4 font-light leading-6 font-size-2">Our platform uses AI to simplify document detection and mapping, ensuring quick and efficient processing. Seamlessly integrate data and coordinate logistics effortlessly to reduce manual effort and increase operational efficiency. Our tool is equipped with robust data security measures and provides insightful analytics to enhance your decision-making process. Additionally, you can easily integrate this application into your existing workflow, making implementation straightforward.</p>
              <p class="mb-4 font-medium font-size-2">Simplify your workflows and revolutionize your logistics management with Cartage Manager.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary-100">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm-py-16 lg-px-6">
          <div className="md-grid md-grid-cols-3 lg-grid-cols-3 md-gap-12">
            <div className="bg-white pt-4 px-4 rounded-lg hover-scale">
              <div className="flex items-center mb-4">
                <div className="flex justify-center items-center w-10 h-10 rounded-full bg-primary-100 lg-h-12 lg-w-12 p-4 mr-3 hover-scale-icon-container">
                  <InsightsIcon className="w-5 h-5 text-primary-600 lg-w-6 lg-h-6 hover-scale-icon" />
                </div>
                <p className="text-gray-500 hover-scale-text">Enhances data security and offers analytics</p>
              </div>
            </div>
            <div className="bg-white pt-4 px-4 rounded-lg shadow-md hover-scale hover-scale">
              <div className="flex items-center mb-4">
                <div className="flex justify-center items-center w-10 h-10 rounded-full bg-primary-100 lg-h-12 lg-w-12 p-4 mr-3 hover-scale-icon-container hover-scale-icon-container">
                  <AutoAwesomeIcon className="w-5 h-5 text-primary-600 lg-w-6 lg-h-6 hover-scale-icon" />
                </div>
                <p className="text-gray-500 hover-scale-text">Allows you to customize the product to perfectly fit your business needs</p>
              </div>
            </div>
            <div className="bg-white pt-4 px-4 rounded-lg shadow-md hover-scale">
              <div className="flex items-center mb-4">
                <div className="flex justify-center items-center w-10 h-10 rounded-full bg-primary-100 lg-h-12 lg-w-12 p-4 mr-3 hover-scale-icon-container">
                  <SupportAgentIcon className="w-5 h-5 text-primary-600 lg-w-6 lg-h-6 hover-scale-icon" />
                </div>
                <p className="text-gray-500 hover-scale-text">Dedicated support team ready to assist you</p>
              </div>
            </div>
            <div className="bg-white pt-4 px-4 rounded-lg shadow-md hover-scale">
              <div className="flex items-center mb-4">
                <div className="flex justify-center items-center w-10 h-10 rounded-full bg-primary-100 lg-h-12 lg-w-12 p-4 mr-3 hover-scale-icon-container">
                  <AppShortcutIcon className="w-5 h-5 text-primary-600 lg-w-6 lg-h-6 hover-scale-icon" />
                </div>
                <p className="text-gray-500 hover-scale-text">Digitizes documents, saving paper by reducing physical copies</p>
              </div>
            </div>
            <div className="bg-white pt-4 px-4 rounded-lg shadow-md hover-scale">
              <div className="flex items-center mb-4">
                <div className="flex justify-center items-center w-10 h-10 rounded-full bg-primary-100 lg-h-12 lg-w-12 p-4 mr-3 hover-scale-icon-container">
                  <BusinessIcon className="w-5 h-5 text-primary-600 lg-w-6 lg-h-6 hover-scale-icon" />
                </div>
                <p className="text-gray-500 hover-scale-text">Supports businesses of all sizes</p>
              </div>
            </div>
            <div className="bg-white pt-4 px-4 rounded-lg shadow-md hover-scale">
              <div className="flex items-center mb-4">
                <div className="flex justify-center items-center w-10 h-10 rounded-full bg-primary-100 lg-h-12 lg-w-12 p-4 mr-3 hover-scale-icon-container">
                  <EngineeringIcon className="w-5 h-5 text-primary-600 lg-w-6 lg-h-6 hover-scale-icon" />
                </div>
                <p className="text-gray-500 hover-scale-text">Minimizes the need for manual data entry</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='hidden' style={{ flexDirection: 'column', height: '30vh' }}>
  <div style={{ background: 'linear-gradient(to bottom, rgb(219 234 254) 50%, rgb(249 250 251) 50%)', flex: 1, position: 'relative' }}>
    <div style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', width: '100%', textAlign: 'center' }}>
      <div className="mx-auto max-w-screen-xl lg-px-6" style={{display: 'flex', alignItems: 'center', height: '100%' }}>
      <div style={{ flex: '0 0 40%', height: '100%' }}>
          <img src={laptopImage} alt="Image" style={{ maxWidth: '100%', height: '100%', objectFit: 'cover' }} />
        </div>
        <div className="bg-white px-4 rounded-lg" style={{ flex: '0 0 60%', height: '100%' ,  boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)'}}>
          <div className='py-8'>
            <div className="flex items-center">
              <div className="text-center text-gray-900 py-2 px-2">
                <p className="font-bold text-2xl text-primary-600">400+</p>
                <p className="text-sm">Documents Processed</p>
              </div>
              <div className="w-px h-6 bg-blue-400 mx-4"></div>
              <div className="text-center text-gray-900 py-2 px-2">
                <p className="font-bold text-2xl text-primary-600">8+</p>
                <p className="text-sm">Hours saved every week</p>
              </div>
              <div className="w-px h-6 bg-blue-400 mx-4"></div>
              <div className="text-center text-gray-900 py-2 px-2">
                <p className="font-bold text-2xl text-primary-600">&gt; 94%</p>
                <p className="text-sm">Model accuracy</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
      <section className="bg-gray-50">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm-py-16 lg-px-6">
          <div className="mb-8 max-w-screen-md lg-mb-16">
            <h2 className="mb-4 text-4xl font-extrabold text-gray-900">Designed for business teams like
              yours</h2>
          </div>
          <div className="space-y-8 md-grid md-grid-cols-2 lg-grid-cols-2 md-gap-12">
            <div className="flex">
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg-h-12 lg-w-12 p-4 mr-3">
                <FindInPageIcon className="w-5 h-5 text-primary-600 lg-w-6 lg-h-6" />
              </div>
              <div>
                <h3 className="mb-2 text-xl font-bold">Document Identification</h3>
                <p className="text-gray-500">CartageManager identifies cartage
                  documents, saving you time and effort in document management.</p>
              </div>
            </div>
            <div className="flex">
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg-h-12 lg-w-12 p-4 mr-3 p-4 mr-3">
                <LinkIcon className="w-5 h-5 text-primary-600 lg-w-6 lg-h-6" />
              </div>
              <div>
                <h3 className="mb-2 text-xl font-bold">Document Mapping</h3>
                <p className="text-gray-500">With CartageManager, easily map associated documents of
                  shipments, ensuring a comprehensive overview of all relevant information for efficient
                  decision-making.</p>
              </div>
            </div>
            <div className="flex">
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg-h-12 lg-w-12 p-4 mr-3">
                <DocumentScannerIcon className="w-5 h-5 text-primary-600 lg-w-6 lg-h-6" />
              </div>
              <div>
                <h3 className="mb-2 text-xl font-bold">In-App Invoice Creation</h3>
                <p className="text-gray-500">Our application allows you to create invoices seamlessly
                  within the platform, eliminating the need to switch between applications.</p>
              </div>
            </div>
            <div className="flex">
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg-h-12 lg-w-12 p-4 mr-3">
                <EmailIcon className="w-5 h-5 text-primary-600 lg-w-6 lg-h-6" />
              </div>
              <div>
                <h3 className="mb-2 text-xl font-bold">Subscribe for real-time document information</h3>
                <p className="text-gray-500">CartageManager offers a subscription feature that
                  enables you to receive real-time information extracted from your shipment documents to make
                  quick, informed decisions.</p>
              </div>
            </div>
            <div className="flex">
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg-h-12 lg-w-12 p-4 mr-3">
                <TextFieldsIcon className="w-5 h-5 text-primary-600 lg-w-6 lg-h-6" />
              </div>
              <div>
                <h3 className="mb-2 text-xl font-bold">Automatic Form Field Population</h3>
                <p className="text-gray-500">Automatically fill form fields with data extracted from
                  a document like a Bill of Lading using Cartage Manager, mapping the extracted data to
                  corresponding fields.</p>
              </div>
            </div>
            <div className="flex">
              <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg-h-12 lg-w-12 p-4 mr-3">
                <GroupIcon className="w-5 h-5 text-primary-600 lg-w-6 lg-h-6" />
              </div>
              <div>
                <h3 className="mb-2 text-xl font-bold">User Management</h3>
                <p className="text-gray-500">Effortlessly manage user accounts and permissions with
                  Cartage Manager's role-based access control. Assign different roles and ensure secure and
                  efficient user management with granular control over access levels.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-primary-700">
      <div className="px-4 mx-auto max-w-screen-xl py-8 lg-px-6">
        {/* <Carousel 
          responsive={responsive} 
          infinite={false}
          arrows={true}
          showDots={false}
          className='pb-2'
        >
            <div className='rounded-lg text-center' style={{ width: '60%', margin: '0 auto', padding: '20px' }}>
            <p className='px-4 font-bold text-white'>Favored Express</p>
            <p className="md-text-4xl font-light px-4 text-white" style={{ wordWrap: 'break-word' }}>"Cartage Manager system has significantly streamlined our workflow by making invoice management easier, enabling easy tracking of invoices, and simplifying the process of exporting documents. It automatically merges documents, which is a fantastic feature, and it has greatly reduced our reliance on paper, eliminating the need to print documents. Our entire company loves it!"</p>
            <p className='px-4 font-medium text-white'>- Hilda Kahia</p>
          </div>
          <div className='rounded-lg text-center' style={{ width: '60%', margin: '0 auto', padding: '20px' }}>
            <p className='px-4 font-bold text-white'>Company name</p>
            <p className="md-text-4xl font-light px-4 text-white" style={{ wordWrap: 'break-word' }}>"CartageManager has revolutionized our document management process, making it efficient and effective."</p>
            <p className='px-4 font-medium text-white'>- Customer 2</p>
          </div>
          <div className='rounded-lg text-center' style={{ width: '60%', margin: '0 auto', padding: '20px' }}>
            <p className='px-4 font-bold text-white'>Company name</p>
            <p className="md-text-4xl font-light px-4 text-white" style={{ wordWrap: 'break-word' }}>"Using CartageManager has been a game-changer for our business. Highly recommend it!"</p>
            <p className='px-4 font-medium text-white'>- Customer 3</p>
          </div>
        </Carousel> */}
        <div className='rounded-lg text-center' style={{ width: '60%', margin: '0 auto', padding: '20px' }}>
      <p className='px-4 font-bold text-white'>Favored Express</p>
      <p className="md-text-2xl font-light px-4 text-white" style={{ wordWrap: 'break-word' }}>"Cartage Manager has significantly streamlined our workflow by making invoice management easier, enabling easy tracking of invoices, and simplifying the process of exporting documents. It automatically merges documents, which is a fantastic feature, and it has greatly reduced our reliance on paper, eliminating the need to print documents. Our entire company loves it!"</p>
      <p className='px-4 font-medium text-white'>- Hilda Kahia</p>
     </div>
      </div>
    </section>
      {/* <section className="bg-primary-700">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm-py-16 lg-px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <p className="mb-6 font-light text-white md-text-lg">Want to experience the power of Cartage Manager?</p>
            <h2 className="mb-4 text-4xl font-light leading-tight text-white">Try Cartage Manager today!</h2>
            <a href="#" className="bg-white leading-tight text-center text-gray-900 rounded-lg border border-gray-300 text-sm px-5 py-5 mr-2 mb-2 hover-bg-primary-100">Request Demo</a>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default LandingPage;
