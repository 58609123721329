import React from 'react';
import Modal from '@material-ui/core/Modal';
import './ConfirmationModal.css';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import Button from '@mui/material/Button';

const ConfirmationModal = ({ isOpen, message, onConfirm, onCancel }) => {
    return (
        <Modal open={isOpen} onClose={onCancel}>
            <div className="confirmation-modal-box">
                <div className="container">
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <ReportProblemRoundedIcon className='confirmation-icon' />
                        </div>
                        <div className='col-12 text-center'>
                            <h2>Confirmation</h2>
                            <p>{message}</p>
                            <div >
                                <Button className='confirmation-buttons theme-bg' variant="contained" color="primary" onClick={onConfirm}>
                                    <span className='text-trans-none'>Yes</span>
                                </Button>
                                <Button className='confirmation-buttons ml-3' variant="outlined" color="secondary" onClick={onCancel}>
                                    <span className='text-trans-none'>No</span>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
