import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import TableExpansionPanel from '../../../components/TableExpansionPanel'; // Make sure to import the new component
import './UserActivityDetails.css';
import {
    getUserDocumentExportedActivityDetails,
    getUserCreateInvoiceActivityDetails,
    getUserDocumentSentActivityDetails,
    getUserDownloadActivityDetails,
    getUserLoginActivityDetails
} from '../../../Helper/api';

const UserActivityDetails = ({ open, onClose, userDetailsData }) => {
    const [docSentRows, setDocSentRows] = useState([]);
    const [invoiceCreatedRows, setInvoiceCreatedRows] = useState([]);
    const [docExportedRows, setDocExportedRows] = useState([]);
    const [docDownloadedRows, setDocDownloadedRows] = useState([]);
    const [loginActivityRows, setLoginActivityRows] = useState([]);


    const docSentColumns = [
        { field: 'id', headerName: 'S No.', width: 100 },
        { field: 'file_name', headerName: 'File Name', width: 250 },
        { field: 'sent_type', headerName: 'Sent Type', width: 250 },
        {
            field: 'created_date', headerName: 'Sent Date', width: 200,
            renderCell: (params) => <div>{formatDate(params.value)}</div>
        }];

    const invoiceCreatedColumns = [
        { field: 'id', headerName: 'S No.', width: 100 },
        { field: 'invoice_number', headerName: 'Invoice Number', width: 250 },
        { field: 'order_number', headerName: 'Order_Number', width: 250 },
        {
            field: 'created_date', headerName: 'Created Date', width: 200,
            renderCell: (params) => <div>{formatDate(params.value)}</div>
        }];

    const docExportedColumns = [
        { field: 'id', headerName: 'S No.', width: 100 },
        { field: 'shipments_count', headerName: 'Total Shipments', width: 200 },
        {
            field: 'start_date', headerName: 'Start Date', width: 250,
            renderCell: (params) => <div>{formatDate(params.value)}</div>
        },
        {
            field: 'end_date', headerName: 'End Date', width: 250,
            renderCell: (params) => <div>{formatDate(params.value)}</div>
        },
        {
            field: 'created_date', headerName: 'Created Date', width: 250,
            renderCell: (params) => <div>{formatDate(params.value)}</div>
        }];
    const docDownloadedColumns = [
        { field: 'id', headerName: 'S No.', width: 100 },
        { field: 'file_type', headerName: 'Document Type', width: 600 },
        {
            field: 'created_date', headerName: 'Downloaded Date', width: 300,
            renderCell: (params) => <div>{formatDate(params.value)}</div>
        }];
    const loginActivityColumns = [
        { field: 'id', headerName: 'S No.', width: 100 },
        {
            field: 'login_date', headerName: 'Login Date', width: 300,
            renderCell: (params) => <div>{formatDate(params.value)}</div>
        }
    ];

    useEffect(() => {
        async function fetchUserActivityDetails() {
            const responses = await Promise.all([
                getUserDocumentSentActivityDetails(userDetailsData.user_id),
                getUserDocumentExportedActivityDetails(userDetailsData.user_id),
                getUserDownloadActivityDetails(userDetailsData.user_id),
                getUserLoginActivityDetails(userDetailsData.user_id),
                getUserCreateInvoiceActivityDetails(userDetailsData.user_id),
            ]);
            setDocSentRows(responses[0]?.data.map((row, index) => ({ ...row, id: index + 1 })));
            setDocExportedRows(responses[1]?.data.map((row, index) => ({ ...row, id: index + 1 })));
            setDocDownloadedRows(responses[2]?.data.map((row, index) => ({ ...row, id: index + 1 })));
            setLoginActivityRows(responses[3]?.data.map((row, index) => ({ ...row, id: index + 1 })));
            setInvoiceCreatedRows(responses[4]?.data.map((row, index) => ({ ...row, id: index + 1 })));
        }
        fetchUserActivityDetails();
    }, [userDetailsData]);

    const formatDate = (dateString) => {
        if (dateString === null || dateString === '') {
            return '-';
        }
        const date = new Date(dateString);
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        };
        const formatter = new Intl.DateTimeFormat('en-US', options);
        try {
            return formatter.format(date);
        } catch {
            return '-';
        }
    };

    return (
        <div style={{ width: '100vw' }}>
            <Dialog open={open} onClose={onClose} style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', zIndex: 1000 }} maxWidth="lg">
                <DialogTitle>User Activity Details</DialogTitle>
                <DialogContent style={{ minHeight: '70vh', maxHeight: '70vh' }}>
                    <TableExpansionPanel title="Document Sent" count={userDetailsData.document_sent_count} rows={docSentRows} columns={docSentColumns} />
                    <TableExpansionPanel title="Exported Documents" count={userDetailsData.export_summary_count} rows={docExportedRows} columns={docExportedColumns} />
                    <TableExpansionPanel title="Documents Downloaded" count={userDetailsData.file_download_count} rows={docDownloadedRows} columns={docDownloadedColumns} />
                    <TableExpansionPanel title="Invoices Created" count={userDetailsData.invoice_created_count} rows={invoiceCreatedRows} columns={invoiceCreatedColumns} />
                    <TableExpansionPanel title="Login Activity" count={userDetailsData.login_activity_count} rows={loginActivityRows} columns={loginActivityColumns} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UserActivityDetails;
