import React from 'react';
import { PieChart, Pie, Cell, Label } from 'recharts';

const DoughnutChart = ({ percentage }) => {
  const chartData = [{ name: 'progress', value: percentage }, { name: 'remaining', value: 100 - percentage }];
  const COLORS = ['#0088FE', '#EFEFEF'];

  return (
    <PieChart width={200} height={200}>
      <Pie
        data={chartData}
        dataKey="value"
        startAngle={90}
        endAngle={-270}
        innerRadius="80%"
        outerRadius="90%"
        paddingAngle={0}
        isAnimationActive={false}
      >
        {chartData.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
        <Label
          value={`${percentage}%`}
          position="center"
          fill="#333"
          style={{ fontSize: '20px', fontWeight: 'bold' }}
        />
      </Pie>
    </PieChart>
  );
};

export default DoughnutChart;
