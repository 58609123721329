import * as React from 'react';
import { PieChart, Pie, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';


const colors =  [ '#20b2aa','#ff69b4', '#ffa500']; 

const PieChartComponent = ({data=[]}) => {
  const renderCustomTooltip = ({ payload }) => {
    if (payload && payload.length > 0) {
      const { role_name, role_count } = payload[0].payload;
      const percent = ((role_count / data?.reduce((acc, curr) => acc + curr.role_count, 0)) * 100).toFixed(2);
      return (
        <div style={{ backgroundColor: 'white', padding: '5px', border: '1px solid #ccc' }}>
          <p>{role_name}</p>
          <p>{`${role_count} (${percent}%)`}</p>
        </div>
      );
    }
    return null;
  };

  const renderCustomLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="black"
        fontSize={12}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={270}>
      <PieChart>
        <Pie
          data={data}
          dataKey="role_count"
          nameKey="role_name"
          cx="50%"
          cy="50%"
          outerRadius={100}
          innerRadius={70}
          labelLine={false}
          label={renderCustomLabel}
        >
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Pie>
        <Tooltip content={renderCustomTooltip} />
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default PieChartComponent;
