import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid } from '@mui/x-data-grid';

const TableExpansionPanel = ({ title, count, rows, columns, pageSizeOptions = [5, 10, 15, 20, 25] }) => (
  <Accordion className='mb-3'>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
      {title} ({count})
    </AccordionSummary>
    <AccordionDetails>
      <DataGrid
        style={{ minHeight: '50vh', maxHeight: '65vh' }}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={pageSizeOptions}
      />
    </AccordionDetails>
  </Accordion>
);

export default TableExpansionPanel;