import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import './../Shipments.css';

const ShipmentTable = ({
    rows,
    columns,
    editingId,
}) => {

    return (
        <div className='col-12'>
            <DataGrid
                keys={editingId}
                style={{ minHeight: '50vh', maxHeight: '60vh' }}
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                sx={{
                    '& .MuiDataGrid-virtualScroller': {
                        minHeight: '35vh',
                        maxHeight: '55vh',
                    },
                }}
                pageSizeOptions={[10, 15, 20, 25]}
            />
        </div>
    );
};

export default ShipmentTable;
