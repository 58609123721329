import React, { useState } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { insertQuickBooksCreds } from '../../../Helper/api';

const QuickBooksPopup = ({ openQuickBooks, handleCloseQuickBooksPopup }) => {
    const [clientId, setClientId] = useState('');
    const [clientSecret, setClientSecret] = useState('');

    const handleSaveCredentials = async () => {
        try {
            const response = await insertQuickBooksCreds(clientId, clientSecret);
            console.log(response);
            handleCloseQuickBooksPopup();
        } catch {
            console.error('Error saving QuickBook credentials');
        }
    };

    const handleCancel = () => {
        handleCloseQuickBooksPopup();
    };

    return (
        <Dialog open={openQuickBooks} maxWidth="sm" fullWidth>
            <DialogTitle>Enter QuickBooks Credentials</DialogTitle>
            <DialogContent>
                <TextField
                    label="Client ID"
                    value={clientId}
                    onChange={(e) => setClientId(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Client Secret"
                    value={clientSecret}
                    onChange={(e) => setClientSecret(e.target.value)}
                    fullWidth
                    margin="normal"
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSaveCredentials} color="primary">Save</Button>
                <Button onClick={handleCancel} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default QuickBooksPopup