import axios from "axios";
import {
    API_STATUS
} from "./constants"
import {
    getItemWithExpiry
} from "../utils/localStorage";
const apiConfig = process.env.REACT_APP_API_CONFIG;
const shipmentApi = process.env.REACT_APP_INVOICE_API_CONFIG;
const companySignupApi = process.env.REACT_APP_COMPANYSIGNUP_API_CONFIG;
const userActivityApi = process.env.REACT_APP_INVOICE_USER_ACTIVITY_CONFIG;
const env = process.env.REACT_APP_ENV;

const parsedUrl = new URL(window.location.href);
const cmBaseUrl = `${parsedUrl.protocol}//${parsedUrl.host}`;

export const getDetails = async (id, value) => {
    try {
        let path = "";
        if (value === "bol") {
            path = `/bol/id/${id}`;
        } else if (value === "pa") {
            path = `/pa/id/${id}`;
        } else if (value === "da") {
            path = `/da/id/${id}`;
        } else if (value === "pod") {
            path = `/pod/id/${id}`;
        }

        const response = await axios.get(apiConfig + path);

        return response
    } catch (error) {
        return "id is not defined";
    }
};

export const searchDetails = async (id, value) => {
    try {
        let path = `/bol/number/${id}`;

        const response = await axios.get(apiConfig + path);

        return response
    } catch (error) {
        return {
            status: 404,
            description: `BOL number ${id} not found`
        };
    }
};

export const saveDetails = async (id, value, params) => {
    try {
        let path = "";
        if (value === "bol") {
            path = `/bol/id/${id}`;
        } else if (value === "pa") {
            path = `/pa/id/${id}`;
        } else if (value === "da") {
            path = `/da/id/${id}`;
        } else if (value === "pod") {
            path = `/pod/id/${id}`;
        }
        const response = await axios.post(apiConfig + path, params);

        return response
    } catch (error) {
        return "number is not defined";
    }
};

export const getShipments = async (formattedStartDate, formattedEndDate, userId) => {
    try {
        let path = `/invoice/list`;
        const response = await axios.get(shipmentApi + path + `?startDate=${formattedStartDate}&endDate=${formattedEndDate}&company=${localStorage.getItem('companyName')}&userId=${(userId && userId !== 'all') ? userId : ''}`);
        return response
    } catch (error) {
        return "Unable to fetch shipments";
    }
};

export const getRateConfirmations = async (formattedStartDate, formattedEndDate, userId) => {
    try {
        let path = `/rateConfirmation/list`;
        const response = await axios.get(shipmentApi + path + `?startDate=${formattedStartDate}&endDate=${formattedEndDate}&company=${localStorage.getItem('companyName')}&userId=${(userId && userId !== 'all') ? userId : ''}`);
        return response
    } catch (error) {
        return "Unable to fetch shipments";
    }
}

export const getPods = async (formattedStartDate, formattedEndDate, userId) => {
    try {
        let path = `/pod/list`;
        const response = await axios.get(shipmentApi + path + `?startDate=${formattedStartDate}&endDate=${formattedEndDate}&company=${localStorage.getItem('companyName')}&userId=${(userId && userId !== 'all') ? userId : ''}`);
        return response
    } catch (error) {
        return "Unable to fetch shipments";
    }
}

export const getShipmentDetails = async (formattedStartDate, formattedEndDate, userId) => {
    console.log(userId);
    try {
        const path = `/shipmentDetails`;
        const companyUuid = JSON.parse(localStorage.getItem('userDetails')).company_uuid;
        const userQuery = userId && userId !== 'all' ? `&userId=${userId}` : '';
        const url = `${shipmentApi}${path}?company_uuid=${companyUuid}&startDate=${formattedStartDate}&endDate=${formattedEndDate}${userQuery}`;
        const response = await axios.get(url);
        console.log(response);
        return response;
    } catch (error) {
        console.error("Unable to fetch shipments", error);
        return "Unable to fetch shipments";
    }
}

export const deleteShipmentDetails = async (uuid) => {
    try {
        let path = `/shipmentDetails`;
        const response = await axios.delete(shipmentApi + path + `?uuid=${uuid}`);
        console.log(response);
        return response;
    }
    catch (error) {
        console.error('Failed to delete shipment:', error);
        return "Unable to delete shipment";
    }
}

export const updateShipmentDetails = async (data) => {
    try {
        let path = `/shipmentDetails`;
        const response = await axios.put(shipmentApi + path, data);
        return response
    } catch (error) {
        return "Unable to update shipment details";
    }
};

export const createShipmentDetail = async (data) => {
    try {
        let path = `/shipmentDetails`;
        const response = await axios.post(shipmentApi + path, data);
        return response
    } catch (error) {
        return "Unable to update shipment details";
    }
};

export const getShipmentByOrder = async (order_number) => {
    try {
        let path = `/invoice/order/${order_number}`;
        const response = await axios.get(shipmentApi + path + `?company=${localStorage.getItem('companyName')}`);
        return response
    } catch (error) {
        return "Unable to fetch shipments";
    }
};

export const getUnlinkedPdfs = async (fileName) => {
    if (fileName === undefined) {
        fileName = '';
    }
    try {
        let path = `/unlinked/pdf`;
        const response = await axios.get(shipmentApi + path + `?company=${localStorage.getItem('companyName')}&fileName=${fileName}`);
        return response
    } catch (error) {
        return "Unable to fetch shipments";
    }
};

export const updateLink = async (data) => {
    try {
        let path = `/pod/link`;
        const response = await axios.put(shipmentApi + path + `?company=${localStorage.getItem('companyName')}`, data);
        return response
    } catch (error) {
        return "Unable to fetch shipments";
    }
};

export const unlinkDocument = async (data) => {
    try {
        let path = `/unlinkDoc`;
        const response = await axios.put(shipmentApi + path + `?company=${localStorage.getItem('companyName')}`, data);
        return response
    } catch (error) {
        return "Unable to fetch shipments";
    }
};

export const getFilteredInvoices = async (formattedStartDate, formattedEndDate) => {
    try {
        let path = `/invoices/date`;

        const response = await axios.get(shipmentApi + path + `?startDate=${formattedStartDate}&endDate=${formattedEndDate}&company=${localStorage.getItem('companyName')}`);

        return response;
    } catch (error) {
        return "Unable to fetch shipments";
    }
};

export const getSummaryDetails = async (startDate, endDate, transferType, signature, selectedRowIds) => {
    console.log('transferType', transferType)
    console.log('signature', signature)
    console.log("selectedRows", selectedRowIds)
    try {
        let path = '/invoices/summary/export';
        let queryParams = `?company=${encodeURIComponent(localStorage.getItem('companyName'))}`;

        if (startDate && endDate) {
            queryParams += `&startDate=${startDate}&endDate=${endDate}`;
        }

        const data = {
            'transferType': transferType,
            'signature': signature,
            'selectedRowIds': selectedRowIds
        }
        console.log("data", data)
        const response = await axios.post(shipmentApi + path + queryParams, data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response;
    } catch (error) {
        console.error('Error fetching summary details:', error);
        return {
            statusCode: 504,
            message: "Unable to fetch summary details"
        };
    }
};

export const postDocuments = async (fileData) => {
    const path = '/upload';
    const queryParams = `?company=${localStorage.getItem('companyName')}`;
    const data = {
        files: fileData
    }
    try {
        const response = await axios.post(shipmentApi + path + queryParams, data);
        return response;
    } catch (error) {
        return 'Unable to upload Documents';
    }
}

export const companySignup = async (data) => {
    try {
        console.log("data", data)
        const path = "/company/signup";
        const response = await axios.post(companySignupApi + path, data);
        return {
            data: response.data,
            status: API_STATUS.success
        };
    } catch (error) {
        return 'Unable to signup company';
    }
}

export const sendSupportEmail = async (data) => {
    try {
        console.log("data", data)
        const path = "/sendSupportEmail";
        const response = await axios.post(companySignupApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to send Support Email';
    }
}

export const getModuleFeature = async () => {
    try {
        const path = "/plans";
        const response = await axios.get(companySignupApi + path);
        console.log("plans", response)
        return response.data;
    } catch (error) {
        return {
            data: [],
            status: API_STATUS.error,
            message: error.message
        };
    }
}
export const companyNameValidation = async (companyName) => {
    try {
        let path = "/check/company";
        path += companyName ? `?company_name=${companyName}` : '';
        const response = await axios.get(companySignupApi + path);
        return {
            data: response.data,
            status: API_STATUS.success
        };
    } catch (error) {
        return {
            data: [],
            status: API_STATUS.error,
            message: error.message
        };
    }
};
export const userNameValidation = async (user_name) => {
    try {
        let path = "/check/user";
        path += user_name ? `?user_name=${user_name}` : '';
        const response = await axios.get(companySignupApi + path);
        return {
            data: response.data,
            status: API_STATUS.success
        };
    } catch (error) {
        return {
            data: [],
            status: API_STATUS.error,
            message: error.message
        };
    }
};

export const downloadActivity = async (data) => {
    const path = '/userFileDownloadActivity';
    const queryParams = `?user_id=${localStorage.getItem('user_id')}`;
    data.company = localStorage.getItem('companyName');
    console.log(data);

    try {
        const response = await axios.post(userActivityApi + path + queryParams, data);
        return response;
    } catch (error) {
        return 'Unable to load details';
    }
}

export const documentSentActivity = async (data) => {
    const path = '/userDocumentSentActivity';
    const queryParams = `?user_id=${localStorage.getItem('user_id')}`;

    try {
        const response = await axios.post(userActivityApi + path + queryParams, data);
        return response;
    } catch (error) {
        return 'Unable to load details';
    }
}

export const createInvoiceActivity = async (data) => {
    const path = '/userCreateInvoiceActivity';

    try {
        const response = await axios.post(userActivityApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to load create invoice activity';
    }
}

export const documentExportedActivity = async (data) => {
    const path = '/userExportSummaryActivity';
    const queryParams = `?user_id=${localStorage.getItem('user_id')}`;

    try {
        const response = await axios.post(userActivityApi + path + queryParams, data);
        return response;
    } catch (error) {
        return 'Unable to load details';
    }
}

export const userLoginActivity = async () => {
    const path = '/userLoginActivity';
    const queryParams = `?user_id=${localStorage.getItem('user_id')}`;
    const data = {
        "company": localStorage.getItem('companyName'),
        "login_date": new Date()
    }

    try {
        const response = await axios.post(userActivityApi + path + queryParams, data);
        return response;
    } catch (error) {
        return 'Unable to load details';
    }
}

export const getUserActivityDetails = async () => {
    const path = '/userDetails';
    // const queryParams = `?user_id=2daeb16f-8489-4c3c-941a-d9d01b5b07ec`;
    const queryParams = `?user_id=${localStorage.getItem('user_id')}`;
    try {
        const response = await axios.get(userActivityApi + path + queryParams);
        console.log(response);
        return response;
    } catch (error) {
        return 'Unable to load details';
    }
}


export const getUserLoginActivityDetails = async (userId) => {
    const path = '/userLoginActivity';
    const queryParams = `?user_id=${userId}`;
    try {
        const response = await axios.get(userActivityApi + path + queryParams);
        console.log(response);
        return response;
    } catch (error) {
        return 'Unable to load details';
    }
}

export const getUserDownloadActivityDetails = async (userId) => {
    const path = '/userFileDownloadActivity';
    const queryParams = `?user_id=${userId}`;
    try {
        const response = await axios.get(userActivityApi + path + queryParams);
        console.log(response);
        return response;
    } catch (error) {
        return 'Unable to load details';
    }
}

export const getUserDocumentSentActivityDetails = async (userId) => {
    const path = '/userDocumentSentActivity';
    const queryParams = `?user_id=${userId}`;
    try {
        const response = await axios.get(userActivityApi + path + queryParams);
        console.log(response);
        return response;
    } catch (error) {
        return 'Unable to load details';
    }
}

export const getUserCreateInvoiceActivityDetails = async (userId) => {
    const path = '/userCreateInvoiceActivity';
    const queryParams = `?user_id=${userId}`;
    try {
        const response = await axios.get(userActivityApi + path + queryParams);
        console.log(response);
        return response;
    } catch (error) {
        return 'Unable to load details';
    }
}

export const getUserDocumentExportedActivityDetails = async (userId) => {
    const path = '/userExportSummaryActivity';
    const queryParams = `?user_id=${userId}`;
    try {
        const response = await axios.get(userActivityApi + path + queryParams);
        console.log(response);
        return response;
    } catch (error) {
        return 'Unable to load details';
    }
}

export const getUserDetails = async (userId) => {
    const path = '/user';
    const queryParams = `?user_id=${userId}`;
    try {
        const response = await axios.get(userActivityApi + path + queryParams);
        if (response.length === 1) {
            localStorage.setItem('userDetails', response[0]);
        }
        return response;
    } catch (error) {
        return 'Unable to load user details';
    }
}

export const getUserRoles = async () => {
    const path = '/roles';
    try {
        const response = await axios.get(companySignupApi + path);
        return response;
    } catch (error) {
        return 'Unable to load user details';
    }
}

export const getUserDetailsByCompany = async (company) => {
    const path = '/company/user';
    const queryParams = `?company_name=${company}`;
    try {
        const response = await axios.get(userActivityApi + path + queryParams);
        if (response.length === 1) {
            localStorage.setItem('userDetails', response[0]);
        }
        return response;
    } catch (error) {
        return 'Unable to load user details';
    }
}

export const updateUserRole = async (data) => {
    const path = '/company/user';
    console.log(data)
    try {
        const response = await axios.put(companySignupApi + path, data);
        return {
            data: response.data,
            status: API_STATUS.success
        };
    } catch (error) {
        return 'Unable to load user details';
    }
}

export const addUser = async (data) => {
    const path = '/company/user';
    console.log(data)
    try {
        const response = await axios.post(companySignupApi + path, data);
        console.log(response)
        return {
            data: response.data,
            status: API_STATUS.success
        };
    } catch (error) {
        return 'Unable to add user';
    }
}
export const authorize = async () => {
    const path = '/qb/authentication';
    console.log(localStorage);
    const data = {
        clientId: localStorage.getItem('clientId'),
        clientSecret: localStorage.getItem('clientSecret'),
        redirectUri: env === 'development' ? `${cmBaseUrl}/qb` : 'https://cartagemanager.com/qb'
    }
    try {
        const response = await axios.post(shipmentApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to load user details';
    }
}

export const callback = async (code) => {
    const path = '/qb/callback';
    console.log(localStorage);
    const data = {
        code: code,
        clientId: localStorage.getItem('clientId'),
        clientSecret: localStorage.getItem('clientSecret'),
        redirectUri: env === 'development' ? `${cmBaseUrl}/qb` : 'https://cartagemanager.com/qb'
    }
    try {
        const response = await axios.post(shipmentApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to load user details';
    }
}

export const getQuickBooksCreds = async () => {
    const path = '/invoice/credentials';
    console.log(localStorage.getItem('userDetails'));
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));

    const queryParams = `?company_id=${userDetails?.company_uuid}`;
    try {
        const response = await axios.get(shipmentApi + path + queryParams);
        return response;
    } catch (error) {
        return 'Unable to load user details';
    }
}

export const insertQuickBooksCreds = async (clientId, clientSecret) => {
    const path = '/invoice/credentials';
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));

    const company_uuid = `${userDetails?.company_uuid}`;

    const data = {
        client_id: clientId,
        client_secret: clientSecret,
        company_uuid: company_uuid
    }
    try {
        const response = await axios.post(shipmentApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to load user details';
    }
}

export const updateQuickBooksCreds = async (clientId, clientSecret, client_credentials_uuid) => {
    const path = '/invoice/credentials';
    const data = {
        clientId: clientId,
        clientSecret: clientSecret,
        client_credentials_uuid: client_credentials_uuid
    }
    try {
        const response = await axios.put(shipmentApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to load user details';
    }
}

export const getQuickBooksCustomerList = async (companyName) => {
    const data = {
        realmId: localStorage.getItem('realmId'),
        accessToken: JSON.parse(localStorage.getItem('access_token')).value,
        companyName: companyName
    }
    const path = `/quickbooks/companies`;

    try {
        const response = await axios.post(shipmentApi + path, data);
        return response.data;
    } catch (error) {
        console.error('Error fetching QuickBooks customer list:', error);
        throw error;
    }
};

export const getQuickBooksTermsList = async () => {
    const data = {
        realmId: localStorage.getItem('realmId'),
        accessToken: JSON.parse(localStorage.getItem('access_token')).value,
    }
    const path = `/quickbooks/terms`;

    try {
        const response = await axios.post(shipmentApi + path, data);
        return response.data;
    } catch (error) {
        console.error('Error fetching QuickBooks customer list:', error);
        throw error;
    }
};

export const getQuickBooksServiceList = async () => {
    const data = {
        realmId: localStorage.getItem('realmId'),
        accessToken: JSON.parse(localStorage.getItem('access_token')).value,
    }
    const path = `/quickbooks/services`;

    try {
        const response = await axios.post(shipmentApi + path, data);
        return response.data;
    } catch (error) {
        console.error('Error fetching QuickBooks service list:', error);
        throw error;
    }
};

export const createInvoice = async (data) => {
    const path = `/quickbooks/create-invoice`;
    const finalData = {
        authData: {
            realmId: localStorage.getItem('realmId'),
            accessToken: JSON.parse(localStorage.getItem('access_token')).value,
        },
        data: data
    }
    try {
        const response = await axios.post(shipmentApi + path, finalData);
        return response?.data;
    } catch (error) {
        console.error('Error fetching QuickBooks service list:', error);
        throw error;
    }
}

export const saveInvoice = async (invoiceId) => {
    const path = `/invoice/save`;
    const data = {
        realmId: localStorage.getItem('realmId'),
        accessToken: JSON.parse(localStorage.getItem('access_token')).value,
        invoiceId: invoiceId,
        companyName: localStorage.getItem('companyName'),
        userId: localStorage.getItem('user_id')
    }
    try {
        const response = await axios.post(shipmentApi + path, data);
        return response?.data;
    } catch (error) {
        console.error('Error fetching QuickBooks service list:', error);
        throw error;
    }
}
export const sendInvite = async (data) => {
    try {
        console.log("data", data)
        const path = "/invite";
        const response = await axios.post(userActivityApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to send invite';
    }
}

export const getCompanyDetails = async () => {
    try {
        let path = `/companies`;
        const response = await axios.get(userActivityApi + path);
        return response
    } catch (error) {
        return "Unable to fetch company details";
    }
};

export const updateInvoice = async (data) => {
    try {
        let path = `/invoice/update`;
        // const response = await axios.get(userActivityApi + path);
        // return response
        console.log("Invoice data", data)
        const response = await axios.put(shipmentApi + path + `?company=${localStorage.getItem('companyName')}`, data);
        return response
    } catch (error) {
        return "Unable to Update Invoice Details";
    }
};

export const updateRateConfirmation = async (data) => {
    try {
        let path = `/rateConfirmation/update`;
        console.log("Rc data", data)
        const response = await axios.put(shipmentApi + path + `?company=${localStorage.getItem('companyName')}`, data);
        return response
    } catch (error) {
        return "Unable to Update Rc Details";
    }
};
export const updatePod = async (data) => {
    try {
        let path = `/pod/update`;
        console.log("Pod data", data)
        const response = await axios.put(shipmentApi + path + `?company=${localStorage.getItem('companyName')}`, data);
        return response
    } catch (error) {
        return "Unable to Update Pod Details";
    }
};

export const triggerShipment = async (payload) => {
    try {
        const path = '/pod/data'; // Adjust the API endpoint as needed
        const response = await axios.post(shipmentApi + path, payload);
        return response;
    } catch (error) {
        console.error('Error triggering shipment:', error);
        throw error;
    }
};

//Dashboard APIs
export const getCompaniesList = async () => {
    try {
        const path = '/filters/companies';
        const response = await axios.get(userActivityApi + path);
        return response;
    } catch (error) {
        console.error('Error fetching the company list:', error);
        throw error;
    }
};
export const getUsageMetrics = async (dateRange, company) => {
    try {
        const path = '/dashboard/usageMetrics';
        let queryString = `?dateRange=${dateRange}`
        if (company && company !== 'allCustomers') {
            queryString += `&company=${company}`;
        }
        const response = await axios.get(userActivityApi + path + queryString);
        return response;
    } catch (error) {
        console.error('Error fetching the usageMetrics', error);
        throw error;
    }
};
export const getDocumentMetrics = async (dateRange, company, userId) => {
    try {
        const path = '/dashboard/documentMetrics';
        let queryString = `?dateRange=${dateRange}`
        if (company && company !== 'allCustomers') {
            queryString += `&company=${company}`;
            if (userId && userId !== 'allUsers') {
                queryString += `&userId=${userId}`;
            }
        }
        const response = await axios.get(userActivityApi + path + queryString);
        return response;
    } catch (error) {
        console.error('Error fetching the documentMetrics', error);
        throw error;
    }
};
export const getUserMetrics = async (dateRange, company) => {
    try {
        const path = '/dashboard/userMetrics';
        let queryString = `?dateRange=${dateRange}`
        if (company && company !== 'allCustomers') {
            queryString += `&company=${company}`;
        }
        const response = await axios.get(userActivityApi + path + queryString);
        return response;
    } catch (error) {
        console.error('Error fetching the userMetrics', error);
        throw error;
    }
};
export const getTextractMetrics = async () => {
    try {
        const path = '/dashboard/textractMetrics';
        const response = await axios.get(userActivityApi + path);
        return response;
    } catch (error) {
        console.error('Error fetching the textractMetrics', error);
        throw error;
    }
};
export const getDocTableMetrics = async (dateRange, company, userId) => {
    console.log("userId inside docTable metrics func", userId)
    try {
        const path = '/dashboard/docTableMetrics';
        let queryString = `?dateRange=${dateRange}`
        if (company && company !== 'allCustomers') {
            queryString += `&company=${company}`;
            if (userId && userId !== 'allUsers') {
                queryString += `&userId=${userId}`;
            }
        }
        const response = await axios.get(userActivityApi + path + queryString);
        return response;
    } catch (error) {
        console.error('Error fetching the docTableMetrics', error);
        throw error;
    }
};
export const getRoleMetrics = async (company) => {
    try {
        const path = '/dashboard/roleMetrics';
        let fullPath = userActivityApi + path;
        if (company && company !== 'allCustomers') {
            fullPath += `?company=${company}`;
        }
        const response = await axios.get(fullPath);
        return response;
    } catch (error) {
        console.error('Error fetching the roleMetrics', error);
        throw error;
    }
};

//ZohoLeads API
export const createLeads = async (data) => {
    try {
        console.log("data",data)
        let path = `/leads`;
        console.log("path",companySignupApi + path)
        const response = await axios.post(companySignupApi + path, data);
        return response
    } catch (error) {
        return "Unable to update shipment details";
    }
};
