import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';

const EditShipmentDetails = ({ open, handleClose, shipment = {}, handleSave, isEditing, handleSearch }) => {
    const [formValues, setFormValues] = useState({ ...shipment });

    useEffect(() => {
        if (shipment) {
            if (!isEditing && !shipment.dispatcher) {
                const userDetails = JSON.parse(localStorage.getItem('userDetails'));
                const dispatcherName = userDetails ? `${userDetails.first_name} ${userDetails.last_name}` : '';
                setFormValues({dispatcher: dispatcherName });
            } else {
                setFormValues({ ...shipment });
            }
        }
    }, [shipment, isEditing]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSaveClick = async () => {
        await handleSave(formValues);
        setTimeout(() => {
            handleSearch();
        }, 500);
        handleClose();
    };


    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle>{isEditing ? 'Edit Shipment Details' : 'Add Shipment Details'}</DialogTitle>
            <DialogContent>
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            <TextField
                                margin="dense"
                                label="Truck Number"
                                name="truck_number"
                                value={formValues.truck_number || ''}
                                onChange={handleChange}
                                fullWidth
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                margin="dense"
                                label="Dispatcher"
                                name="dispatcher"
                                value={formValues.dispatcher || ''}
                                onChange={handleChange}
                                fullWidth
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                margin="dense"
                                label="Driver"
                                name="driver"
                                value={formValues.driver || ''}
                                onChange={handleChange}
                                fullWidth
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                margin="dense"
                                label="From City"
                                name="from_city"
                                value={formValues.from_city || ''}
                                onChange={handleChange}
                                fullWidth
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                margin="dense"
                                label="To City"
                                name="to_city"
                                value={formValues.to_city || ''}
                                onChange={handleChange}
                                fullWidth
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                margin="dense"
                                label="Debtor"
                                name="debtor"
                                value={formValues.debtor || ''}
                                onChange={handleChange}
                                fullWidth
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                margin="dense"
                                label="Order Number"
                                name="order_number"
                                value={formValues.order_number || ''}
                                onChange={handleChange}
                                fullWidth
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                margin="dense"
                                label="BOL Number"
                                name="bol_number"
                                value={formValues.bol_number || ''}
                                onChange={handleChange}
                                fullWidth
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                margin="dense"
                                label="Invoice Number"
                                name="invoice_number"
                                value={formValues.invoice_number || ''}
                                onChange={handleChange}
                                fullWidth
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                margin="dense"
                                label="Phone Number"
                                name="phone_number"
                                value={formValues.phone_number || ''}
                                onChange={handleChange}
                                fullWidth
                            />
                        </div>
                        <div className="col-3">
                            <TextField
                                margin="dense"
                                label="Rate"
                                name="rate"
                                value={formValues.rate || ''}
                                onChange={handleChange}
                                fullWidth
                            />
                        </div>

                    </div>
                </div>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">Cancel</Button>
                <Button onClick={handleSaveClick} color="primary">Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditShipmentDetails;
