import React, { useState, useEffect } from 'react';
import SideBar from '../../../SideBar';
import { Card, Chip, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { getUsageMetrics, getDocumentMetrics, getDocTableMetrics, getUserMetrics, getRoleMetrics, getTextractMetrics, getCompaniesList, getUserDetailsByCompany } from '../../../Helper/api';
import MultiLineChart from './multiChartComponent/index';
import UserDocumentInfoTable from './UserDocumentInfoTable';
import PieChartComponent from './pieChartComponent/index';
import DoughnutChart from './doughnutChartComponent/index';
import UserLoginTable from './UserLoginTable';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import InsightsIcon from '@mui/icons-material/Insights';
import DataSaverOffRoundedIcon from '@mui/icons-material/DataSaverOffRounded';
import DonutLargeRoundedIcon from '@mui/icons-material/DonutLargeRounded';
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import './analytics.css';
import Slider from 'react-slick';


const Analytics = () => {
  const isFromCompanyList = localStorage.getItem('companyName') === 'bizcloudExperts';
  const companyType = isFromCompanyList ? 'company' : 'customer';
  const [dateRangeValue, setDateRangeValue] = useState('allTime');
  const [customerValue, setCustomerValue] = useState(companyType === 'customer' ? localStorage.getItem('companyName') : 'allCustomers');
  const [userValue, setUserValue] = useState('allUsers');
  const [users, setUsers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [usageMetrics, setUsageMetrics] = useState([]);
  const [documentMetrics, setDocumentMetrics] = useState([]);
  const [docTableMetrics, setDocTableMetrics] = useState([]);
  const [userMetrics, setUserMetrics] = useState([]);
  const [roleMetrics, setRoleMetrics] = useState([]);
  const [f1Scores, setF1Scores] = useState({
    f1ScoreInvoice: 0,
    f1ScoreRC: 0,
    f1ScorePOD: 0
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const iconMap = {
    "New Companies": BusinessIcon,
    "New Users": PeopleAltIcon,
    "Documents Uploaded": CloudUploadIcon,
    "Documents Exported": SummarizeIcon,
    "Documents Downloaded": CloudDownloadIcon,
  }

  const handleDateRangeChange = (event) => {
    setDateRangeValue(event.target.value);
  };

  const handleCustomerChange = (event) => {
    setCustomerValue(event.target.value);
    setUserValue('allUsers')
  };

  const parseF1Score = (score) => {
    const parsed = parseFloat(score);
    return isNaN(parsed) ? 0 : parsed;
  };

  const handleUserChange = (event) => {
    const userId = event.target.value;
    console.log("userId", userId)
    setUserValue(userId);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          usageMetricsResponse,
          documentMetricsResponse,
          docTableMetricsResponse,
          userMetricsResponse,
          roleMetricsResponse,
          userDetailsResponse,
        ] = await Promise.all([
          getUsageMetrics(dateRangeValue, customerValue),
          getDocumentMetrics(dateRangeValue, customerValue, userValue),
          getDocTableMetrics(dateRangeValue, customerValue, userValue),
          getUserMetrics(dateRangeValue, customerValue),
          getRoleMetrics(customerValue),
          getUserDetailsByCompany(customerValue),
        ]);

        setUsageMetrics(usageMetricsResponse.data || []);
        setDocumentMetrics(documentMetricsResponse.data.chartData || []);
        setDocTableMetrics(docTableMetricsResponse.data.tableData || []);
        setUserMetrics(userMetricsResponse.data || []);
        setRoleMetrics(roleMetricsResponse.data || []);
        setUsers([{ user_id: 'allUsers', first_name: 'All', last_name: 'Users' }, ...(userDetailsResponse.data || [])]);
      } catch (error) {
        console.error('Error fetching data:', error);
        setUsageMetrics([]);
        setDocumentMetrics([]);
        setDocTableMetrics([]);
        setUserMetrics([]);
        setRoleMetrics([]);
        setUsers([]);
      }
    };

    fetchData();
  }, [dateRangeValue, customerValue, userValue, companyType]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const [customersResponse, textractMetricsResponse] = await Promise.all([
          getCompaniesList(),
          getTextractMetrics(),
        ]);

        setCustomers([{ company_label: 'All Customers', company_value: 'allCustomers' }, ...(customersResponse.data || [])]);
        setF1Scores(textractMetricsResponse.data || { f1ScoreInvoice: 0, f1ScoreRC: 0, f1ScorePOD: 0 });
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
        setCustomers([]);
        setF1Scores({ f1ScoreInvoice: 0, f1ScoreRC: 0, f1ScorePOD: 0 });
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <div className="container-fluid p-0">
      <div className="row no-gutters">
        <div className="col-lg-2">
          <SideBar />
        </div>
        <div className="col-lg-10">
          <div className="d-flex align-items-center justify-content-between py-3 px-4">
            <h2 className="m-0"><DashboardRoundedIcon className='fs-xxl theme-color'></DashboardRoundedIcon> Dashboard</h2>
            <div className="d-flex align-items-center">
              <FormControl variant="outlined" style={{ minWidth: 180 }}>
                <InputLabel id="date-range-label">Date Range</InputLabel>
                <Select
                  labelId="date-range-label"
                  id="date-range"
                  value={dateRangeValue}
                  onChange={handleDateRangeChange}
                  label="Date Range"
                  style={{ height: '2em' }}
                >
                  <MenuItem value={'allTime'}>All Time</MenuItem>
                  <MenuItem value={'thisWeek'}>This Week</MenuItem>
                  <MenuItem value={'thisMonth'}>This Month</MenuItem>
                  <MenuItem value={'thisYear'}>This Year</MenuItem>
                </Select>
              </FormControl>
              <span style={{ margin: '0 16px' }}></span>
              {companyType === 'company' && (
                <FormControl variant="outlined" style={{ minWidth: 180 }}>
                  <InputLabel id="customer-list-label">Customers</InputLabel>
                  <Select
                    labelId="customer-list-label"
                    id="customer-list"
                    value={customerValue}
                    onChange={handleCustomerChange}
                    label="Customers"
                    style={{ height: '2em' }}
                  >
                    {customers.map((customer) => (
                      <MenuItem key={customer.company_uuid} value={customer.company_value}>
                        {customer.company_label || customer.company_value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>
          </div>
          <div className="py-4 px-4" style={{ height: 'calc(100vh - 150px)', overflowY: 'scroll' }}>
            <Card className="mb-4 usage-metrics-card p-3">
              <div className="card-header">
                <h4 className="font-light"><LegendToggleIcon className='fs-xxl theme-color'></LegendToggleIcon> Usage Metrics</h4>
              </div>
              <div className="card-body p-2">
                <div className="row">{Array.isArray(usageMetrics) && usageMetrics.length > 0 ? (
                  usageMetrics.length > 4 ? (
                    <Slider {...settings}>
                      {usageMetrics.map((usageMetric, index) => (
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4 pt-1" key={index}>
                          <div className="card metrics-card shadow-sm h-90" style={{ marginLeft: '5%' }}>
                            <div className="card-body">
                              <div className='text-center'>
                                {iconMap[usageMetric?.metricName] ? React.createElement(iconMap[usageMetric.metricName], { className: 'metrics-icon', color: 'primary' }) : null}
                              </div>
                              <h6 className='ml-3 font-bold text-center'>{usageMetric?.metricName}</h6>
                              <p className='mt-3 metrics-details'>
                                <span className="metric-value">{usageMetric?.currMetricValue}</span>
                                {usageMetric?.change !== 'N/A' ? (
                                  <Chip
                                    icon={usageMetric?.change.startsWith('-') ? <TrendingDownIcon /> : <TrendingUpIcon />}
                                    label={`${usageMetric?.change} (${usageMetric?.previousMetricValue})`}
                                    className={usageMetric?.change.startsWith('-') ? 'text-danger' : 'text-success'}
                                  />
                                ) : (
                                  <Chip label={`Metrics Unvailable`} />
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    usageMetrics.map((usageMetric, index) => (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-3 mb-4 pt-1" key={index}>
                        <div className="card metrics-card shadow-sm h-90">
                          <div className="card-body">
                            <div className='text-center'>
                              {iconMap[usageMetric?.metricName] ? React.createElement(iconMap[usageMetric.metricName], { className: 'metrics-icon', color: 'primary' }) : null}
                            </div>
                            <h6 className='ml-3 font-bold text-center'>{usageMetric?.metricName}</h6>
                            <p className='mt-3 metrics-details'>
                              <span className="metric-value">{usageMetric?.currMetricValue}</span>
                              {usageMetric?.change !== 'N/A' ? (
                                <Chip
                                  icon={usageMetric?.change.startsWith('-') ? <TrendingDownIcon /> : <TrendingUpIcon />}
                                  label={`${usageMetric?.change} (${usageMetric?.previousMetricValue})`}
                                  className={usageMetric?.change.startsWith('-') ? 'text-danger' : 'text-success'}
                                />
                              ) : (
                                <Chip label={`Metrics Unvailable`} />
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  )
                ) : (
                  <p>No usage metrics available</p>
                )}
                </div>
              </div>
            </Card>

            <Card className="p-3 mb-4 usage-metrics-card">
              <div className="card-header">
                <h4 className='font-light'> <InsightsIcon className='fs-xxl theme-color'></InsightsIcon> Document Processing Metrics</h4>
              </div>
              {customerValue !== 'allCustomers' && (
                <div className='px-4 py-4'>
                  <FormControl variant="outlined" style={{ width: 180 }}>
                    <InputLabel id="user-list-label">Users</InputLabel>
                    <Select
                      labelId="user-list-label"
                      id="user-list"
                      value={userValue}
                      onChange={handleUserChange}
                      label="Users"
                      style={{ height: '2em' }}
                    >
                      {users.map(user => (
                        <MenuItem key={user.user_id} value={user.user_id}>
                          {user.first_name && user.last_name ? `${user.first_name} ${user.last_name}` : user.user_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
              <div className="card-body d-flex">
                <div className="w-50 pr-2">
                  <MultiLineChart data={documentMetrics} />
                </div>
                <div className="w-50 pl-2">
                  <UserDocumentInfoTable data={docTableMetrics} />
                </div>
              </div>
            </Card>
            {companyType === 'company' && (
              <div className="row">
                <div className="col-lg-4 mb-4">
                  <Card className="p-4 card usage-metrics-card">
                    <div>
                      <h4><DonutLargeRoundedIcon className='fs-xxl theme-color'></DonutLargeRoundedIcon> Role Metrics</h4>
                    </div>
                    <div className="card-body">
                      <div className="w-100">
                        {roleMetrics && roleMetrics.length > 0 ? (
                          <PieChartComponent data={roleMetrics} />
                        ) : (
                          <p>No role metrics available</p>
                        )}
                      </div>
                    </div>
                  </Card>
                </div>
                <div className="col-lg-8 mb-4">
                  <Card className="p-4 card textract-metrics-card">
                    <div>
                      <h4><DataSaverOffRoundedIcon className='fs-xxl theme-color'></DataSaverOffRoundedIcon> Textract Metrics</h4>
                    </div>
                    <div style={{ display: 'flex' }} className="py-4 mb-12 card-body justify-content-between">
                      <div style={{ textAlign: 'center' }}>
                        <h6>Invoice</h6>
                        <h6>F1 Score</h6>
                        <DoughnutChart percentage={parseF1Score(f1Scores.f1ScoreInvoice)} label="Invoice" />
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <h6>Rate Confirmation</h6>
                        <h6>F1 Score</h6>
                        <DoughnutChart percentage={parseF1Score(f1Scores.f1ScoreRC)} label="RC" />
                      </div>
                      <div style={{ textAlign: 'center' }}>
                        <h6>POD</h6>
                        <h6>F1 Score</h6>
                        <DoughnutChart percentage={parseF1Score(f1Scores.f1ScorePOD)} label="POD" />
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            )}
            <Card className="p-3 mb-4 usage-metrics-card">
              <h4><SupervisedUserCircleOutlinedIcon className='fs-xxl theme-color'></SupervisedUserCircleOutlinedIcon> User Metrics</h4>
              <div className="card-body d-flex">
                <div className="w-100 pr-2 no-border">
                  <UserLoginTable data={userMetrics} />
                </div>
              </div>
            </Card>
          </div >
        </div >
      </div >
    </div >
  );
};

export default Analytics;
