let companyName = localStorage.getItem('companyName') || '';
let cognitoGroups = localStorage.getItem('cognitoGroups') || '';
let authenticated = localStorage.getItem('authenticated') || '';

const SessionService = {
    getCompanyName: () => {
        return companyName;
    },
    setCompanyName: (value) => {
        companyName = value;
        localStorage.setItem('companyName', value);
    },

    getCognitoGroups: () => {
        return cognitoGroups;
    },
    setCognitoGroups: (value) => {
        cognitoGroups = value;
        localStorage.setItem('cognitoGroups', value);
    },
    getAuthenticated: () => {
        return authenticated;
    },
    setAuthenticated: (value) => {
        authenticated = value;
        localStorage.setItem('authenticated', value);
    }
};

export default SessionService;