import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';

const UserDocumentInfoTable = ({ data }) => {
  const [pageSize, setPageSize] = useState(5);

  // Define columns for DataGrid
  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'company', headerName: 'Company', flex: 1 },
    { field: 'invoice', headerName: 'Invoice', flex: 1 },
    { field: 'rate_confirmation', headerName: 'Rate Confirmation', flex: 1 },
    { field: 'pod', headerName: 'Proof of Delivery', flex: 1 },
  ];

  // Add an ID to each row for DataGrid
  const rows = data.map((row, index) => ({ id: index, ...row }));

  return (
    <div style={{ height: 340, width: '100%' }} className='no-border'>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 20]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
      />
    </div>
  );
};

export default UserDocumentInfoTable;
