import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div className='footer padding-1'>
            <div className="container-fluid m-0">
                <div className="row">
                    <div className="vertical-align-center text-center">
                        <span className='text-colour vertical-middle footer-font-size'>Copyright © 2024 Bizcloud Experts. Terms of Service | Privacy Policy</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer