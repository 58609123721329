// ShipmentActions.js
import React from 'react';
import { Button } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import UploadRoundedIcon from '@mui/icons-material/UploadRounded';
import ReceiptIcon from '@mui/icons-material/Receipt';
import './../Shipments.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const ShipmentActions = ({ userRole, openExportPdfDetails, handleOpenUploadDocuments, handleOpenInvoicePopup, handleOpenAddShipment, selectedTab }) => {

    const handleOpenInvoice = (handleOpenInvoicePopup) => {
        localStorage.removeItem('invoiceDetails');
        handleOpenInvoicePopup();
    };

    return (
        <div className="row p-3">
            <div className="col-lg-12 p-0">
                {selectedTab === '4' ? (
                    <Button
                        onClick={handleOpenAddShipment}
                        variant="contained"
                        className="fl-r theme-bg w-13 btn-br-40p"
                    >
                        <AddCircleIcon />
                        <span className="ml-3 text-trans-none">Add Shipment</span>
                    </Button>
                ) : (
                    <>
                        {userRole === 'ADMIN' && (
                            <Button
                                variant="contained"
                                className="fl-r ml-3 theme-bg btn-br-40p"
                                onClick={openExportPdfDetails}
                            >
                                <PictureAsPdfIcon />
                                <span className="text-trans-none padding-left-10">Export PDF</span>
                            </Button>
                        )}
                        {userRole !== 'READONLY' && (
                            <Button
                                variant="contained"
                                className="fl-r ml-3 theme-bg btn-br-40p"
                                onClick={handleOpenUploadDocuments}
                            >
                                <UploadRoundedIcon />
                                <span className="ml-3 text-trans-none padding-left-10">Upload</span>
                            </Button>
                        )}
                        {userRole === 'ADMIN' && (
                            <Button
                                onClick={() => handleOpenInvoice(handleOpenInvoicePopup)}
                                variant="contained"
                                className="fl-r theme-bg w-13 btn-br-40p"
                            >
                                <ReceiptIcon />
                                <span className="ml-3 text-trans-none">Create Invoice</span>
                            </Button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default ShipmentActions;
