import React, { useState, useEffect } from 'react';
import SideBar from '../../SideBar';
import './CompanyDetails.css';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, TablePagination, Paper, Button, Modal, Backdrop, Fade } from '@mui/material';
// import TableContainer from '@mui/material/TableContainer';
// import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InviteSentPopup from './InviteSentPopup';
import { sendInvite, getCompanyDetails } from '../../Helper/api';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const CompanyDetails = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isInviteSentPopupOpen, setIsInviteSentPopupOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [companies, setCompanies] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [loading, setLoading] = useState(false);


    const data = {
        firstName: "Kiran K"
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await getCompanyDetails();
                setCompanies(response.data);
                setLoading(false);
                console.log(companies);
            } catch (error) {
                console.error('Error fetching company details:', error);
            }
        };

        fetchData();
    }, []);
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };
    const handleOpenModal = () => {
        console.log("Modal opened");
        setIsModalOpen(true);
        setEmail('');
    };

    const handleCloseModal = () => {
        console.log("Modal closed");
        setIsModalOpen(false);
    };


    const handleCloseInviteSentPopup = () => {
        setIsInviteSentPopupOpen(false);
    };


    const handleSendInvite = async () => {
        try {
            if (!email) {
                console.error('Email address is required');
                return;
            }
            const inviteData = {
                email_id: email,
            };
            const response = await sendInvite(inviteData);

            // Once the email is sent successfully, open the second popup
            console.log(response);
            setIsModalOpen(false);
            setIsInviteSentPopupOpen(true);
        } catch (error) {
            console.error('Error sending invite:', error);
        }

    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    return (
        <div className='container-fluid p-0 overflow-x-none'>
            <div className='row'>
                <div className='col-lg-2'>
                    <SideBar data={data}></SideBar>
                </div>
                <div className='col-lg-10'>
                    <div className='container'>
                        <div className='row margin-left'>
                            <div className='col-12 service-header-font' style={{ color: "#0f257e" }}>
                                List of Customers
                                <button className="btn btn-primary float-end" style={{ backgroundColor: "#0f257e" }} onClick={handleOpenModal}>Onboard Company</button>
                            </div>
                        </div>
                        <div className='row margin-left'>
                            <div className='col-12'>
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Company Name</TableCell>
                                                <TableCell>Phone Number</TableCell>
                                                <TableCell>Email</TableCell>
                                                <TableCell>Address</TableCell>
                                                <TableCell>Subscription Plan</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {companies && companies?.length > 0 ? (
                                                (rowsPerPage > 0
                                                    ? companies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    : companies
                                                ).map((company, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{company?.company_label}</TableCell>
                                                        <TableCell>{company?.phone}</TableCell>
                                                        <TableCell>{company?.email}</TableCell>
                                                        <TableCell>{company?.address}</TableCell>
                                                        <TableCell>{company?.plan_name}</TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <TableRow>
                                                    <TableCell colSpan={5} align="center">No companies found</TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                    component="div"
                                    count={companies.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
            >
                <div className='modal-box'>
                    <div className="close-icon" onClick={handleCloseModal}>X</div>
                    <div>
                        <h3>Sign Up via Invite Link</h3>
                        <p>Enter your email address to receive an invite link to join</p>
                        <input type="text" placeholder="Enter your email address" value={email}
                            onChange={handleEmailChange} />
                        <Button variant="contained" color="primary" style={{ backgroundColor: "#0f257e" }} onClick={handleSendInvite}>Send Invite Link</Button>
                        <div className="divider">
                            <hr />
                            <span>or</span>
                        </div>
                        <Link to="/Register" className="link">
                            <Button variant="contained" color="primary" style={{ backgroundColor: "#0f257e" }}>Register by Completing the Form</Button>
                        </Link>
                    </div>
                </div>

            </Modal>
            <InviteSentPopup isOpen={isInviteSentPopupOpen} handleClose={handleCloseInviteSentPopup} />
        </div>
    )
}

export default CompanyDetails;
