import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setItemWithExpiry } from '../../utils/localStorage';
import { callback } from '../../Helper/api';

const QuickBooks = () => {
    const [pageParams, setPageParams] = useSearchParams();
    const [code, setCode] = useState('');
    const [state, setState] = useState('');
    const [realmId, setRealmId] = useState('');
    const navigate = useNavigate();
    const [shouldNavigate, setShouldNavigate] = useState(false); // Flag to determine whether navigation is needed

    useEffect(() => {
        setCode(pageParams.get('code'));
        setRealmId(pageParams.get('realmId'));
        setState(pageParams.get('state')); // Setting state value

        const getAuthData = async () => {
            try {
                const result = await callback(pageParams.get('code'))

                localStorage.setItem('code', pageParams.get('code'));
                localStorage.setItem('state', pageParams.get('state'));
                localStorage.setItem('realmId', pageParams.get('realmId'));

                if (result?.data?.body?.access_token) {
                    setItemWithExpiry('access_token', result?.data?.body?.access_token, 60);
                    setShouldNavigate(true);
                }
            } catch (error) {
                console.error("Error occurred:", error);
            }
        };

        // Call getAuthData only once
        if (code && realmId && state) {
            getAuthData();
        }
    }, [code, realmId, state]);

    useEffect(() => {
        if (shouldNavigate) {
            navigate('/invoice-module', { state: { openInvoicePopup: true } });
        }
    }, [shouldNavigate, navigate]);

    return <div />;
};

export default QuickBooks;
