import React from 'react';
import Modal from '@material-ui/core/Modal';
import './WarningModal.css';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import Button from '@mui/material/Button';

const WarningModal = ({ isOpen, message, onClose }) => {
  return (
    <Modal open={isOpen}>
      <div className="warning-modal-box">
        <div className="container">
          <div className='row'>
            <div className='col-12 text-center'>
              <ReportProblemRoundedIcon className='warning-icon' />
            </div>
            <div className='col-12 text-center'>
              <h2>Warning</h2>
              <p>{message}</p>
              <Button onClick={onClose}>Close</Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WarningModal;
