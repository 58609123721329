import React from 'react';
import { Modal, Button } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

const InviteSentPopup = ({ isOpen, handleClose }) => {
    return (
        <Modal open={isOpen} onClose={handleClose}>
            <div className='modal-box' style={{ width: '30%' }}>
                <div className="close-icon" onClick={handleClose}>X</div>
                <CheckCircle sx={{ fontSize: '64px', color: '#0f257e', marginBottom: '20px' }} />
                <h3>Invite Sent Successfully</h3>
                <Button variant="contained" color="primary" onClick={handleClose} style={{ backgroundColor: "#0f257e" }}>Close</Button>
            </div>
        </Modal>
    );
};

export default InviteSentPopup;
