import React from 'react';
import './FullScreenSpinner.css';

const FullScreenSpinner = () => {
    return (
        <div className="full-screen-spinner-overlay">
            <div className="spinner"></div>
        </div>
    )
}

export default FullScreenSpinner