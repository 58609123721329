import { useState, useCallback } from 'react';

const useErrorModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');

  const showModal = useCallback((errorMessage) => {
    setMessage(errorMessage);
    setIsOpen(true);
  }, []);

  const hideModal = useCallback(() => {
    console.log("hidemodal called")
    setIsOpen(false);
    setMessage('');
  }, []);

  return { isOpen, message, showModal, hideModal };
};

export default useErrorModal;
