import React from 'react';
import ReactApexChart from 'react-apexcharts';

const MultiLineChart = ({ data }) => {
    // Transform the data to fit ApexCharts format
    const periods = data.map(item => item.period);
    const podData = data.map(item => item.pod);
    const rcData = data.map(item => item.rc);
    const invoiceData = data.map(item => item.invoice);

    const series = [
        {
            name: 'Proof of Delivery',
            data: podData,
        },
        {
            name: 'Rate Confirmation',
            data: rcData,
        },
        {
            name: 'Invoice',
            data: invoiceData,
        }
    ];

    const options = {
        chart: {
            height: 400,
            type: 'area',  // Set chart type to 'area'
        },
        colors: ['#008ffb75', '#00e396', '#ffeb00'], // Customize the colors here
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: 'smooth',
        },
        xaxis: {
            categories: periods,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 0.1,
                opacityFrom: 0.4,
                opacityTo: 0.5,
                stops: [0, 90, 100]
            }
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy HH:mm',
            },
        },
        legend: {
            position: 'top',
        },
    };

    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="area" height={400} />
        </div>
    );
};

export default MultiLineChart;
