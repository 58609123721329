import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import Button from '@mui/material/Button';
import './ErrorModal.css';

const ErrorModal = ({ isOpen, message, onClose }) => {
  const [openErrorModal, setOpenErrorModal] = useState(isOpen);

  useEffect(() => {
    setOpenErrorModal(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpenErrorModal(false);
  };

  return (
    <Modal open={openErrorModal}>
      <div className="error-modal-box">
        <div className="container">
          <div className='row'>
            <div className='col-12 text-center'>
              <ReportProblemRoundedIcon className='error-icon' />
            </div>
            <div className='col-12'>
              <h2 className='text-center'>Error</h2>
              <p className='text-justify' dangerouslySetInnerHTML={{ __html: message }}></p>
              <Button onClick={handleClose}>Close</Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ErrorModal;
