// Modal.js
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdOutlineManageSearch } from "react-icons/md";
import { RiPassValidLine } from "react-icons/ri";
import './Modal.css';

const Modal = ({ isOpen, onClose }) => {
  const location = useLocation();

  // Add an event listener to the overlay
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen && !event.target.closest('.modal-content')) {
        onClose();
      }
    };

    // Attach the event listener
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <Link to="/search" onClick={onClose} style={{ color: location.pathname === '/search' ? 'black' : 'gray' }}>
          <MdOutlineManageSearch style={{ fontSize: '30px', marginRight: "10px", marginLeft: "10px" }} /> SEARCH
        </Link>
        <Link to="/" onClick={onClose} className="order" style={{ color: location.pathname === '/' ? 'black' : 'gray' }}>
          <RiPassValidLine style={{ fontSize: '30px', marginRight: "10px", marginLeft: "10px" }} /> VALIDATION
        </Link>
      </div>
    </div>
  );
};

export default Modal;