import React, { useState } from 'react';
import './Contact.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { createLeads } from '../../Helper/api';
import SnackBarMessage from '../../components/SnackBarMessage';

const Contact = () => {
  const [form, setForm] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    website_url: '',
    company: '',
    description: '',
  });

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('form:', form);
      const response = await createLeads(form);

      if (response.status === 200) {
        setIsSnackbarOpen(true);
        setSnackBarMessage('Thank you for reaching out! Our sales team will contact you shortly.');
        setForm({
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          website_url: '',
          company: '',
          description: '',
        });
      } else {
        setIsSnackbarOpen(true);
        setSnackBarMessage('We are facing some issue, please try again later!');
      }
    } catch (error) {
      console.error('Error:', error);
      setIsSnackbarOpen(true);
      setSnackBarMessage('An error occurred. Please try again.');
    }
  };

  const isFormValid = () => {
    return (
      form.first_name.trim() !== '' &&
      form.last_name.trim() !== '' &&
      form.email.trim() !== '' &&
      form.phone.trim() !== '' &&
      form.company.trim() !== ''
    );
  };

  return (
    <section className='scrollable-container rd_content px-5'>
      <div className='rd_content-wrapper'>
        <div className='rd_col six mt-6'>
          <div className='m--w-90'>
            <h1 className='lead-in-1 rd-style mb-5'>Request a demo</h1>
            <h2 className='title-4 m--title-5 m-0 mb-4'>We'd love to show you our app.</h2>
            <p className='fs-5 m--fs-6 lh-6'>
              Fill up the form today for a personalized demo to discover how CartageManager can benefit your business and to
              <strong> learn about our limited time offers</strong>.
            </p>
            <div className="md-grid md-grid-rows-3 lg-grid-rows-3">
              <div className="bg-white rounded-lg">
                <div className="flex items-center mb-4">
                  <div className="flex justify-center items-center w-10 h-10 rounded-full bg-primary-100 lg-h-12 lg-w-12 p-4 mr-3">
                    <EmailIcon className="w-5 h-5 text-primary-600 lg-w-6 lg-h-6" />
                  </div>
                  <p className="text-gray-500">bizdev@bizcloudexperts.com</p>
                </div>
              </div>
              <div className="bg-white rounded-lg">
                <div className="flex items-center mb-4">
                  <div className="flex justify-center items-center w-10 h-10 rounded-full bg-primary-100 lg-h-12 lg-w-12 p-4 mr-3">
                    <CallIcon className="w-5 h-5 text-primary-600 lg-w-6 lg-h-6" />
                  </div>
                  <p className="text-gray-500">(214) 206-8976</p>
                </div>
              </div>
              <div className="bg-white rounded-lg">
                <div className="flex items-center mb-4">
                  <div className="flex justify-center items-center w-10 h-10 rounded-full bg-primary-100 lg-h-12 lg-w-12 p-4 mr-3">
                    <LocationOnIcon className="w-5 h-5 text-primary-600 lg-w-6 lg-h-6" />
                  </div>
                  <p className="text-gray-500">417 Oakbend Dr. Suite 180 Lewisville, TX 75067</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='rd_col one'></div>
        <div className='rd_col five'>
          <Grid justifyContent="center">
            <Grid item xs={12} sm={10} md={8}>
              <Card variant="outlined" className="contact-card">
                <CardContent>
                  <form onSubmit={handleSubmit}>
                    <TextField
                      className="mb-3"
                      fullWidth
                      label="First Name*"
                      variant="outlined"
                      name="first_name"
                      value={form.first_name}
                      onChange={handleChange}
                    />
                    <TextField
                      className="mb-3"
                      fullWidth
                      label="Last Name*"
                      variant="outlined"
                      name="last_name"
                      value={form.last_name}
                      onChange={handleChange}
                    />
                    <TextField
                      className="mb-3"
                      fullWidth
                      label="Phone Number*"
                      variant="outlined"
                      name="phone"
                      value={form.phone}
                      onChange={handleChange}
                    />
                    <TextField
                      className="mb-3"
                      fullWidth
                      label="Company*"
                      variant="outlined"
                      name="company"
                      value={form.company}
                      onChange={handleChange}
                    />
                    <TextField
                      className="mb-3"
                      fullWidth
                      label="Email*"
                      variant="outlined"
                      type="email"
                      name="email"
                      value={form.email}
                      onChange={handleChange}
                    />
                    <TextField
                      className="mb-3"
                      fullWidth
                      label="Company URL"
                      variant="outlined"
                      name="website_url"
                      value={form.website_url}
                      onChange={handleChange}
                    />
                    <TextField
                      className="mb-3"
                      fullWidth
                      multiline
                      minRows={4}
                      maxRows={6}
                      label="Description"
                      variant="outlined"
                      name="description"
                      value={form.description}
                      onChange={handleChange}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={!isFormValid()}
                    >
                      Submit
                    </Button>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>
      <SnackBarMessage
        isSnackbarOpen={isSnackbarOpen}
        snackBarMessage={snackBarMessage}
        setIsSnackbarOpen={setIsSnackbarOpen}
      />
    </section>
  );
};

export default Contact;
