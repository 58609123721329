import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import './UploadDocuments.css';
import { documentSentActivity } from '../../../Helper/api';
import ErrorModal from '../../../components/ErrorModal';

const UploadDocuments = ({ isOpen, onProcess, cancel }) => {
    const [files, setFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    const handleProcess = () => {
        const validFiles = files.filter(file => !file.isOversized);
        const filesData = validFiles.map(file => ({
            file_name: file.name,
            file_type: file.type,
            base64_data: file.data,
            user_id: localStorage.getItem('user_id'),
        }));
        console.log(filesData);
        const fileSentActivity = validFiles.map(file => ({
            "sent_type": "Direct Upload",
            "file_name": file.name,
            "status": "Success",
            "user_id": localStorage.getItem('user_id'),
            "company": localStorage.getItem('companyName'),
            "created_date": new Date()
        }));

        onProcess(filesData);
        documentSentActivity(fileSentActivity);

        const oversizedFiles = files.filter(file => file.isOversized);
        if (oversizedFiles.length > 0) {
            setErrorMessage(`Cannot process the below files, since they are more than 4MB:<br/>${oversizedFiles.map((file, index) => `${index + 1}. ${file.name}`).join('<br/>')}<br/><br/>Please email the documents as an attachment to fe@cartagemanager.com for processing.`);
            setIsErrorModalOpen(true);
        } else {
            setFiles([]);
        }
    };


    const handleCancel = () => {
        setFiles([]);
        cancel();
    };

    const handleUploadDocuments = (event) => {
        const selectedFiles = event.target.files;
        if (selectedFiles.length > 0) {
            const filesArray = Array.from(selectedFiles);
            Promise.all(filesArray.map(file => readFileAsBase64(file)))
                .then(dataArray => {
                    const updatedFiles = dataArray.map((data, index) => ({
                        name: filesArray[index].name,
                        type: filesArray[index].type,
                        data: data,
                        isOversized: filesArray[index].size > 4 * 1024 * 1024
                    }));
                    setFiles(prevFiles => [...prevFiles, ...updatedFiles]);
                })
                .catch(error => console.error('Error reading files:', error));
        }
    };

    const readFileAsBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
        });
    };

    const handleDeleteFile = (index) => {
        setFiles(prevFiles => prevFiles.filter((file, i) => i !== index));
    };

    const handleCloseErrorModal = () => {
        setIsErrorModalOpen(false);
    };

    return (
        <>
            <Modal open={isOpen}>
                <div className='export-modal-box'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-6'>
                                <h3>Documents</h3>
                            </div>
                            <div className='col-6'>
                                <label htmlFor="file-upload" className="custom-file-upload w-75 fl-r">
                                    <FileUploadIcon></FileUploadIcon> Upload
                                </label>
                                <input
                                    className='file-selector-button pl-0'
                                    id="file-upload"
                                    type="file"
                                    accept="application/pdf"
                                    multiple
                                    onChange={handleUploadDocuments}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='container file-container'>
                        <div className='row mh-20'>
                            {files.length > 0 ? (
                                files.map((file, index) => (
                                    <div key={index} className="file-item pt-3">
                                        <span>{index + 1}. {file.name}</span>
                                        {file.isOversized && <span className='file-size-warning'> >4MB</span>}
                                        <IconButton className='p-0 fl-r' onClick={() => handleDeleteFile(index)}><DeleteIcon /></IconButton>
                                    </div>
                                ))
                            ) : (
                                <span>No documents uploaded</span>
                            )}
                        </div>
                    </div>
                    <div className='col-12'>
                        <p className="warning-message text-center">Please note: You can upload a maximum of 5 files at a time for processing.</p>
                    </div>
                    <Button
                        onClick={handleCancel}
                        className='mt-3 cancel-btn'
                    >
                        <span className='text-trans-none'>Cancel</span>
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className='fl-r mt-3'
                        style={{ width: '40%' }}
                        onClick={handleProcess}
                        disabled={files.length > 5 || files.length === 0}
                    >
                        <span className='text-trans-none'>Process</span>
                    </Button>
                </div>
            </Modal>
            <ErrorModal
                isOpen={isErrorModalOpen}
                message={errorMessage}
                onClose={handleCloseErrorModal}
            />
        </>
    );
};

export default UploadDocuments;
