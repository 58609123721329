import React, { useEffect, useState } from 'react';
import SideBar from '../../SideBar';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import './Users.css'
import { getUserRoles, getUserDetailsByCompany, updateUserRole, addUser } from '../../Helper/api';
import Checkbox from '@mui/material/Checkbox';
import { DataGrid } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

const Users = () => {

    const [roles, setRoles] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState();
    const [refresh, setRefresh] = useState(false);
    const [users, setUsers] = useState([]);
    const [formValid, setFormValid] = useState(false);
    const [passwordValidation, setPasswordValidation] = useState({
        minLength: false,
        hasNumber: false,
        hasSpecialChar: false,
        hasUppercase: false,
        hasLowercase: false
    });
    const [userDetails, setUserDetails] = useState({
        company: localStorage.getItem('companyName'),
        firstName: "",
        lastName: "",
        user_email: "",
        user_Name: "",
        password: "",
        modules: localStorage.getItem('cognitoGroups'),
        user_phone: "",
        role: roles,
    })
    useEffect(() => {
        async function fetchUserDetails() {
            const response = await getUserDetailsByCompany(localStorage.getItem('companyName'));
            const roles = await getUserRoles();

            const modifiedUsers = await Promise.all(response.data?.map((user, index) => {
                const roleDetail = roles.data.find(role => role.role_id === user.role_id);
                const roleName = roleDetail.role_name
                const fullName = `${user.first_name} ${user.last_name}`;
                return {
                    id: user.user_id,
                    name: fullName,
                    role: roleName,
                    isSelected: false
                };
            }));

            setUsers(modifiedUsers);
            console.log("Modified users:", modifiedUsers);
        }
        fetchUserDetails();
    }, [refresh]);

    useEffect(() => {
        const isFormValid = Object.values(userDetails).every(value => value !== '') && selectedRole !== '';
        setFormValid(isFormValid);
    }, [userDetails, selectedRole]);

    const isAnyUserSelected = users.some(user => user.isSelected);

    const rows = users.map((user) => ({
        id: user.id,
        isSelected: user.isSelected,
        name: user.name,
        role: user.role,
    }));

    let columns = [
        { field: 'name', headerName: 'Name', width: 300 },
        { field: 'role', headerName: 'Role', width: 150 },
    ];

    if (localStorage.getItem('user_role') === 'ADMIN') {
        columns = [
            {
                field: 'isSelected',
                headerName: 'Select',
                sortable: false,
                renderCell: (params) => (
                    <>
                        <Checkbox
                            checked={params.value}
                            onChange={() => handleUserSelection(params.id)}
                            disabled={params.id === localStorage.getItem('user_id')} // Disable if it matches the logged-in user's ID
                        />
                    </>
                ),
                width: 100
            },
            ...columns // Spread the existing columns array
        ];
    }


    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await getUserRoles();
                console.log(response);
                setRoles(response.data);
            } catch (error) {
                console.error("Failed to fetch roles:", error);
            }
        };

        fetchRoles();
    }, []);

    const handleUserSelection = (userId) => {
        setUsers(users.map(user => {
            if (user.id === userId) {
                return { ...user, isSelected: !user.isSelected };
            }
            return user;
        }));
    };

    const handleChangeAccess = async () => {
        console.log(users)
        await Promise.all(users.map(async (user) => {
            if (user.isSelected === true) {
                const updateRoleData = { user_id: user.id, role: selectedRole }
                await updateUserRole(updateRoleData)
                setRefresh(prevRefresh => !prevRefresh);
            }
        }))

    };

    const handleRoleSelectChange = (event) => {
        setSelectedRole(event.target.value.toUpperCase());
    };

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        for (const key in passwordValidation) {
            if (!passwordValidation[key]) {
                alert("Password must meet all requirements.");
                return;
            }
        }


        const formData = {
            company: localStorage.getItem('companyName'),
            firstName: userDetails.firstName,
            lastName: userDetails.lastName,
            user_email: userDetails.user_email,
            user_Name: userDetails.user_Name,
            password: userDetails.password,
            modules: localStorage.getItem('cognitoGroups'),
            user_phone: userDetails.user_phone,
            role: selectedRole
        };
        await addUser(formData)

        setUserDetails({
            company: "",
            firstName: "",
            lastName: "",
            user_email: "",
            user_Name: "",
            password: "",
            modules: "",
            user_phone: "",
            role: roles,
        });
        handleClose();


    };

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setUserDetails(prevState => ({ ...prevState, [name]: value }));
        if (name === 'password') {
            validatePassword(value);
        }
    };

    const validatePassword = (password) => {
        setPasswordValidation({
            minLength: password.length >= 8,
            hasNumber: /\d/.test(password),
            hasSpecialChar: /[!@#$%^&*]/.test(password),
            hasUppercase: /[A-Z]/.test(password),
            hasLowercase: /[a-z]/.test(password)
        });
    };

    const renderValidationIcon = (isValid) => {
        return isValid ? <CheckIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} />;
    };

    return (
        <div className='container-fluid p-0 overflow-x-none'>
            <div className='row'>
                <div className='col-lg-2'>
                    <SideBar></SideBar>
                </div>
                <div className='col-lg-10 mt-3 user-container'>
                    <div className='row mt-3'>
                        <div className='col-12'>
                            <span className='service-header-font' style={{ color: "#3860B2" }}>Users</span>

                            <Dialog open={open} onClose={handleClose}>
                                <DialogTitle>Add User</DialogTitle>
                                <DialogContent>
                                    {/* Add your form fields here */}
                                    <TextField
                                        margin="dense"
                                        id="firstName"
                                        name="firstName"
                                        label="First Name"
                                        value={userDetails.firstName}
                                        type="text"
                                        fullWidth
                                        onChange={handleOnChange}
                                    />
                                    <TextField
                                        margin="dense"
                                        id="lastName"
                                        name="lastName"
                                        label="Last Name"
                                        value={userDetails.lastName}
                                        type="text"
                                        fullWidth
                                        onChange={handleOnChange}
                                    />
                                    <TextField
                                        margin="dense"
                                        id="user_email"
                                        name="user_email"
                                        label="Email Address"
                                        value={userDetails.user_email}
                                        type="email"
                                        fullWidth
                                        onChange={handleOnChange}
                                    />
                                    <TextField
                                        margin="dense"
                                        id="user_phone"
                                        name="user_phone"
                                        label="Phone Number"
                                        value={userDetails.user_phone}
                                        type="text"
                                        fullWidth
                                        onChange={handleOnChange}
                                    />
                                    <TextField
                                        select
                                        margin="dense"
                                        id="role"
                                        label="Role"
                                        value={selectedRole}
                                        onChange={handleRoleSelectChange}
                                        fullWidth
                                    >
                                        {roles.map((role) => (
                                            <MenuItem key={role.role_id} value={role.role_name.toUpperCase()}>
                                                {role.role_name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        margin="dense"
                                        id="user_Name"
                                        name="user_Name"
                                        label="Username"
                                        value={userDetails.user_Name}
                                        type="text"
                                        fullWidth
                                        onChange={handleOnChange}
                                    />
                                    <TextField
                                        margin="dense"
                                        id="password"
                                        name="password"
                                        label="Password"
                                        value={userDetails.password}
                                        type="password"
                                        fullWidth
                                        onChange={handleOnChange}
                                        helperText={
                                            <div className='invoice-popup-container p-3 mt-3'>
                                                <h6>Password requirements</h6>
                                                <div>
                                                    {renderValidationIcon(passwordValidation.minLength)} Minimum length (8 characters) <br />
                                                    {renderValidationIcon(passwordValidation.hasNumber)} Contains at least 1 number <br />
                                                    {renderValidationIcon(passwordValidation.hasSpecialChar)} Contains at least 1 special character <br />
                                                    {renderValidationIcon(passwordValidation.hasUppercase)} Contains at least 1 uppercase letter <br />
                                                    {renderValidationIcon(passwordValidation.hasLowercase)} Contains at least 1 lowercase letter
                                                </div>
                                            </div>
                                        }
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <div className='place-center col-12 p-3 pt-0'>
                                        <Button className='cancel-btn col-5' onClick={handleClose}>
                                            <span className='text-trans-none'>Cancel</span>
                                        </Button>
                                        <Button
                                            className='theme-bg submit-btn col-5 fl-r'
                                            variant='contained'
                                            onClick={handleSubmit}
                                            disabled={!formValid}>
                                            <span className='text-trans-none'>Submit</span>
                                        </Button>
                                    </div>
                                </DialogActions>
                            </Dialog>
                            {localStorage.getItem('user_role') === 'ADMIN' && (

                                <>
                                    <Button
                                        variant='contained'
                                        className='ml-3 h-2em fl-r'
                                        onClick={handleClickOpen}
                                    >
                                        <span className='text-trans-none'>
                                            Add User
                                        </span>
                                    </Button>
                                    <Button
                                        variant='contained'
                                        className='ml-3 h-2em fl-r'
                                        onClick={handleChangeAccess}
                                        disabled={!isAnyUserSelected}
                                    >
                                        <span className='text-trans-none'>
                                            Change Access
                                        </span>
                                    </Button>
                                    <FormControl className='col-2 fl-r'>
                                        <InputLabel id="role-select-label">Role</InputLabel>
                                        <Select
                                            value={selectedRole}
                                            label="Role"
                                            className='h-2em'
                                            onChange={handleRoleSelectChange}
                                        >
                                            {roles.map((role) => (
                                                <MenuItem key={role.role_id} value={role.role_name.toUpperCase()}>
                                                    {role.role_name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </>

                            )}
                        </div>
                        <div className='col-12 mt-3'>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: 10 },
                                    },
                                }}
                                pageSizeOptions={[10, 15, 20, 25]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Users;
