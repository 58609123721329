import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from 'dayjs';
import FullScreenSpinner from '../../components/FullScreenSpinner';
import FilterModal from './FilterModal';
import ShipmentTable from './ShipmentTable';
import SnackBarMessage from '../../components/SnackBarMessage';
import { authorize, documentExportedActivity, getPods, getQuickBooksCreds, getRateConfirmations, getShipmentDetails, getShipments, getSummaryDetails, postDocuments, updateInvoice, updatePod, updateRateConfirmation, createShipment, updateShipmentDetails, createShipmentDetail, deleteShipmentDetails, getUserDetailsByCompany } from '../../Helper/api';
import { getInvoiceColumns, getPodColumns, getRateConfirmationColumns, getShipmentDetailsColumns } from './ColumnsConfig';
import PdfModals from './ShipmentModals';
import ShipmentActions from './ShipmentActions';
import useErrorModal from '../../components/ErrorModal/useErrorModal';
import { Box, Button, CircularProgress, Tab, Tabs, Tooltip } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import RefreshIcon from '@mui/icons-material/Refresh';
import { getItemWithExpiry } from '../../utils/localStorage';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Skeleton from '@mui/material/Skeleton';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import NotesIcon from '@mui/icons-material/Notes';
import ChecklistRoundedIcon from '@mui/icons-material/ChecklistRounded';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import EditShipmentDetails from '../../Modules/Invoice/EditShipmentDetails';
import ConfirmationModal from '../../components/ConfirmationModal';

const Shipments = () => {
    const today = dayjs();
    const previousSaturday = today.day(-1);
    const nextFriday = today.day(5);
    const [editingId, setEditingId] = useState(null);
    const [editedRow, setEditedRow] = useState(null);
    const editedRowRef = useRef(null); // Ref for editedRow
    const userRole = localStorage.getItem('user_role');
    const [selectedRows, setSelectedRows] = useState([]);
    const [openPdfModal, setOpenPdfModal] = useState(false);
    const [rows, setRows] = useState([]);
    const [pdfModalData, setPdfModalData] = useState(null);
    const [startDate, setStartDate] = useState(previousSaturday);
    const [endDate, setEndDate] = useState(nextFriday);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedUsername, setSelectedUsername] = useState('');
    const [uploadDocuments, setUploadDocuments] = useState(false);
    const [isPdfOpen, setIsPdfOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [isExportPdfDetailsOpen, setIsExportPdfDetailsOpen] = useState(false);
    const [table, setTable] = useState('invoices');
    const [columns, setColumns] = useState([]);
    const [colName, setColName] = useState('invoiceColumns');
    const [isInvoicePopupOpen, setInvoicePopupOpen] = useState(false);
    const [isQuickBooksPopupOpen, setQuickBooksPopupOpen] = useState(false);
    const [hasQuickBookCreds, setHasQuickBookCreds] = useState(false);
    const [showWarningModal, setShowWarningModal] = useState(false);
    const { isOpen, message, showModal, hideModal } = useErrorModal();
    const [isFilterPopupOpen, setIsFilterPopupOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false); // State for dialog
    const [isEditing, setIsEditing] = useState(false); // State to track if editing or creating
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deletedRow, setDeletedRow] = useState(null);
    const [users, setUsers] = useState([]);

    let location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setEditingId(null);
        setEditedRow(null);
        setColName(getColName(localStorage.getItem('tabValue')));
        setTable(getTableName(localStorage.getItem('tabValue')));
        fetchUserDetails();
        fetchQuickBookCreds();
        setInvoicePopupOpen(location?.state?.openInvoicePopup);
    }, []); // Ensure the dependency array is empty to run only once

    // Ensure columns update when selectedRows changes
    useEffect(() => {
        if (table === 'invoices') {
            setColumns(getInvoiceColumns({
                selectedRows,
                handleRowSelect,
                userRole,
                editingId,
                handleSave,
                handleCancelEdit,
                handleEditClick,
                handleFieldChange,
                handleOpenPdfModal,
                formatDate,
                formatDateToISOString
            }));
        } else if (table === 'rateConfirmations') {
            setColumns(getRateConfirmationColumns({
                selectedRows,
                handleRowSelect,
                userRole,
                editingId,
                handleSave,
                handleCancelEdit,
                handleEditClick,
                handleFieldChange,
                handleOpenPdfModal,
                formatDate,
                formatDateToISOString
            }));
        }
        if (table === 'proofOfDelivery') {
            setColumns(getPodColumns({
                selectedRows,
                handleRowSelect,
                userRole,
                editingId,
                handleSave,
                handleCancelEdit,
                handleEditClick,
                handleFieldChange,
                handleOpenPdfModal,
                formatDate,
                formatDateToISOString
            }));
        }
        if (table === 'shipmentDetails') {
            setColumns(getShipmentDetailsColumns({
                handleOpenPdfModal,
                formatDate,
                handleOpenInvoicePopup,
                handleOpenEditDialog,
                handleOpenDeleteDialog
            }));
        }
    }, [selectedRows, userRole, editingId]); // Add necessary dependencies

    useEffect(() => {
        let formattedStartDate = '';
        let formattedEndDate = '';

        if (startDate && endDate) {
            formattedStartDate = startDate.toISOString().split('T')[0];
            formattedEndDate = endDate.toISOString().split('T')[0];
        }
        fetchDataDetails(localStorage.getItem('tabValue'), formattedStartDate, formattedEndDate);
    }, [users])

    function getColName(tab) {
        switch (tab) {
            case '1':
                return 'invoiceColumns';
            case '2':
                return 'rateConfirmationColumns';
            case '3':
                return 'podColumns';
            case '4':
                return 'shipmentDetailsColumns';
            default:
                return 'invoiceColumns';
        }
    }

    function getTableName(tab) {
        switch (tab) {
            case '1':
                return 'invoices';
            case '2':
                return 'rateConfirmations';
            case '3':
                return 'proofOfDelivery';
            case '4':
                return 'shipmentDetails';
            default:
                return 'invoices';
        }
    }

    const fetchUserDetails = async () => {
        try {
            const companyName = localStorage.getItem('companyName');
            const response = await getUserDetailsByCompany(companyName);
            setUsers(response.data); // Adjust according to your API response structure
            console.log(response.data)
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

    async function fetchDataDetails(tab, formattedStartDate, formattedEndDate) {
        if (tab === '1') {
            fetchData(formattedStartDate, formattedEndDate);
        }
        if (tab === '2') {
            fetchRateConfirmations(formattedStartDate, formattedEndDate);
        }
        if (tab === '3') {
            fetchPods(formattedStartDate, formattedEndDate);
        }
        if (tab === '4') {
            await fetchShipmentDetails(formattedStartDate, formattedEndDate);
        }
    }

    const handleRowSelect = (rowId) => {
        const isSelected = selectedRows.includes(rowId);
        let newSelected = [];
        if (!isSelected) {
            newSelected = [...selectedRows, rowId];
        } else {
            newSelected = selectedRows.filter(id => id !== rowId);
        }
        setSelectedRows(newSelected);
    };

    const handleEditClick = (id, row) => {
        setIsEditing(true);
        setEditingId(id);
        setEditedRow({ ...row });
        editedRowRef.current = { ...row }; // Update ref
        setColumns(getInvoiceColumns({
            selectedRows,
            handleRowSelect,
            userRole,
            editingId,
            handleSave,
            handleCancelEdit,
            handleEditClick,
            handleFieldChange,
            handleOpenPdfModal,
            formatDate,
            formatDateToISOString
        }));
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setEditingId(null);
        setEditedRow(null);
        editedRowRef.current = null; // Reset ref
    };

    const handleSave = async (shipmentType, id) => {
        try {
            const updatedRows = rows.map(row =>
                row.id === id ? { ...row, ...editedRowRef.current } : row
            );
            setRows(updatedRows);
            let formattedStartDate = '';
            let formattedEndDate = '';

            if (startDate && endDate) {
                formattedStartDate = startDate.toISOString().split('T')[0];
                formattedEndDate = endDate.toISOString().split('T')[0];
            }
            if (shipmentType === 'invoice') {
                await updateInvoice(editedRowRef.current);
            } else if (shipmentType === 'rateConfirmation') {
                await updateRateConfirmation(editedRowRef.current);
                await fetchRateConfirmations(formattedStartDate, formattedEndDate);
            } else if (shipmentType === 'pod') {
                await updatePod(editedRowRef.current);
                await fetchPods(formattedStartDate, formattedEndDate);
            }
            setIsEditing(false);
            setEditingId(null);
            setEditedRow(null);
            editedRowRef.current = null;

        } catch (error) {
            showModal("An error occurred while saving data.");
        }
    };

    const handleFieldChange = (field, value) => {
        setEditedRow(prevRow => ({
            ...prevRow,
            [field]: value
        }));
        editedRowRef.current = {
            ...editedRowRef.current,
            [field]: value
        }; // Update ref
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleTableChange = (event) => {
        const newTable = event.target.value;
        setTable(newTable);
        setEditingId(null);
        setEditedRow(null);
    };

    const handleTableTabSelect = async (table) => {
        setTable(table);
    }

    useEffect(() => {
        handleSearch(selectedUser, undefined, startDate, endDate);
    }, [table]);

    const handleSearch = async (selectedUser, userDetails, startDate, endDate) => {
        setSelectedUser(selectedUser);
        if (userDetails) {
            setSelectedUsername(userDetails?.first_name + " " + userDetails?.last_name);
        } else if (selectedUser === 'all') {
            setSelectedUsername('All');
        }
        setIsLoading(true);
        setIsEditing(false);
        setEditingId(null);
        setEditedRow(null);
        editedRowRef.current = null; // Reset ref
        let formattedStartDate = '';
        let formattedEndDate = '';

        console.log("startDate, endDate", startDate, endDate);

        if (startDate && endDate) {
            formattedStartDate = startDate.toISOString().split('T')[0];
            formattedEndDate = endDate.toISOString().split('T')[0];
        }
        if (table === 'invoices') {
            await fetchData(formattedStartDate, formattedEndDate, selectedUser);
            setColumns(getInvoiceColumns({
                selectedRows,
                handleRowSelect,
                userRole,
                editingId,
                handleSave,
                handleCancelEdit,
                handleEditClick,
                handleFieldChange,
                handleOpenPdfModal,
                formatDate,
                formatDateToISOString
            }));
            setColName('invoiceColumns');
            setIsLoading(false);
        }
        if (table === 'rateConfirmations') {
            await fetchRateConfirmations(formattedStartDate, formattedEndDate, selectedUser);
            setColumns(getRateConfirmationColumns({
                selectedRows,
                handleRowSelect,
                userRole,
                editingId,
                handleSave,
                handleCancelEdit,
                handleEditClick,
                handleFieldChange,
                handleOpenPdfModal,
                formatDate,
                formatDateToISOString
            }));
            setColName('rateConfirmationColumns');
            setIsLoading(false);
        }
        if (table === 'proofOfDelivery') {
            await fetchPods(formattedStartDate, formattedEndDate, selectedUser);
            setColumns(getPodColumns({
                selectedRows,
                handleRowSelect,
                userRole,
                editingId,
                handleSave,
                handleCancelEdit,
                handleEditClick,
                handleFieldChange,
                handleOpenPdfModal,
                formatDate,
                formatDateToISOString
            }));
            setColName('podColumns');
            setIsLoading(false);
        }
        if (table === 'shipmentDetails') {
            await fetchShipmentDetails(formattedStartDate, formattedEndDate, selectedUser);
            setColumns(getShipmentDetailsColumns({
                handleOpenPdfModal,
                formatDate,
                handleOpenInvoicePopup,
                handleOpenEditDialog,
                handleOpenAddShipment,
                handleOpenDeleteDialog
            }));
            setIsLoading(false);
        }
    };

    const openFilterModal = () => {
        setIsFilterPopupOpen(true);
    };

    const closeFilterModal = () => {
        setIsFilterPopupOpen(false);
    };

    const handleOpenPdfModal = (params) => {
        if (params.row?.order_number) {
            setPdfModalData(params.row);
            setOpenPdfModal(true);
        } else {
            setShowWarningModal(true);
        }
    };

    const handleCloseWarningModal = () => {
        setShowWarningModal(false);
    };

    const handleClosePdfModal = () => {
        handleSearch(selectedUser, undefined, startDate, endDate);
        setOpenPdfModal(false);
    };

    const handleOpenUploadDocuments = () => {
        setUploadDocuments(true);
    };

    const handleCloseUploadDocuments = () => {
        setUploadDocuments(false);
    };

    const processDocuments = async (fileData) => {
        setIsLoading(true);
        try {
            const response = await postDocuments(fileData);
            if (response?.data?.statusCode === 200) {
                setIsSnackbarOpen(true);
                setSnackBarMessage(response?.data?.body?.message);
            } else {
                setIsSnackbarOpen(true);
                let fileNames = '';
                response?.data?.body?.failedFiles?.forEach((element, index) => {
                    fileNames += element?.file_name + ' ,';
                    if (index === response.data.body.failedFiles.length - 1) {
                        fileNames = fileNames.slice(0, -1);
                    }
                });
                setSnackBarMessage('Unable to process ' + fileNames + '. Please try uploading them later.');
            }
            setIsLoading(false);
            setUploadDocuments(false);
        } catch (error) {
            showModal("An error occurred while processing documents.");
            setIsLoading(false);
            setUploadDocuments(false);
        }
    };

    const openPreviewPdfModal = async (selectedTransferType, selectedSignature, selectedRows) => {
        setIsExportPdfDetailsOpen(false);
        setIsLoading(true);
        try {
            if (selectedRows.length === 0) {
                setIsSnackbarOpen(true);
                setSnackBarMessage('Please select at least one checkbox');
                return;
            }
            const formattedStartDate = startDate?.toISOString().split('T')[0];
            const formattedEndDate = endDate?.toISOString().split('T')[0];
            const response = await getSummaryDetails(formattedStartDate, formattedEndDate, selectedTransferType, selectedSignature, selectedRows);
            if (response?.data?.body?.preSignedUrl) {
                setPdfUrl(response?.data?.body?.preSignedUrl);
                setIsPdfOpen(true);
                const data = {
                    "shipments_count": rows.length,
                    "start_date": startDate,
                    "end_date": endDate,
                    "company": localStorage.getItem('companyName'),
                    "created_date": new Date()
                };
                await documentExportedActivity(data);
            } else if (response.data?.statusCode !== 200) {
                setIsSnackbarOpen(true);
                setSnackBarMessage('Unable to open PDF');
            }
        } catch (error) {
            showModal("An error occurred while fetching details.");
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const openExportPdfDetails = () => {
        setIsExportPdfDetailsOpen(true);
    };

    const closePdfModal = () => {
        setIsPdfOpen(false);
    };

    const cancel = () => {
        setIsExportPdfDetailsOpen(false);
    };

    const formatDateToISOString = (date) => {
        if (isNaN(date.getTime())) {
            throw new Error('Invalid date string: Unable to create a Date object from the provided string.');
        }
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
        return formattedDate;
    };

    const formatDate = (dateString) => {
        if (dateString === null || dateString === '') {
            return '-';
        }
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formatter = new Intl.DateTimeFormat('en-US', {
            ...options,
            timeZone: 'UTC'
        });
        try {
            return formatter.format(date);
        } catch {
            return '-';
        }
    };

    const handleOpenInvoicePopup = async () => {
        setIsLoading(true);
        if (hasQuickBookCreds) {
            const response = await authorize();
            window.location.href = response.data;
        } else {
            setQuickBooksPopupOpen(true);
            setIsLoading(false);
        }
    };

    const handleCloseInvoicePopup = () => {
        setInvoicePopupOpen(false);
        navigate(location.pathname, { state: { ...location.state, openInvoicePopup: false } });
    };

    const handleCloseQuickBooksPopup = () => {
        setQuickBooksPopupOpen(false);
    };

    const fetchQuickBookCreds = async () => {
        const response = await getQuickBooksCreds();
        if (response?.data?.body?.length === 0) {
            setHasQuickBookCreds(false);
        } else {
            localStorage.setItem('clientId', response?.data?.body[0].client_id);
            localStorage.setItem('clientSecret', response?.data?.body[0].client_secret);
            setHasQuickBookCreds(true);
        }
    };

    const removeUser = () => {
        setSelectedUser(null);
        setSelectedUsername(null);
        handleSearch(null, null, startDate, endDate);
    }

    const removeDates = () => {
        setStartDate(null);
        setEndDate(null);
        handleSearch(selectedUser, undefined, null, null);
    };

    const fetchData = async (formattedStartDate, formattedEndDate, selectedUser) => {
        try {
            const response = await getShipments(formattedStartDate, formattedEndDate, selectedUser === 'all' ? null : selectedUser);
            const updatedRows = response?.data?.body.map((row, index) => ({
                ...row,
                id: index + 1,
                selected: true
            }));
            setRows(updatedRows || []);
            const selectedRows = updatedRows.filter(row => row.selected).map(row => row.invoice_uuid);
            setSelectedRows(selectedRows);
        } catch (error) {
            showModal("An error occurred while fetching data.");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchRateConfirmations = async (formattedStartDate, formattedEndDate, selectedUser) => {
        try {
            const response = await getRateConfirmations(formattedStartDate, formattedEndDate, selectedUser === 'all' ? null : selectedUser);
            const updatedRows = response?.data?.body.map((row, index) => ({
                ...row,
                id: index + 1,
            }));
            setRows(updatedRows || []);
        } catch (error) {
            showModal("An error occurred while fetching data.");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchPods = async (formattedStartDate, formattedEndDate, selectedUser) => {
        try {
            const response = await getPods(formattedStartDate, formattedEndDate, selectedUser === 'all' ? null : selectedUser);
            const updatedRows = response?.data?.body.map((row, index) => ({
                ...row,
                id: index + 1,
            }));
            setRows(updatedRows || []);
        } catch (error) {
            showModal("An error occurred while fetching data.");
        } finally {
            setIsLoading(false);
        }
    };

    const fetchShipmentDetails = async (formattedStartDate, formattedEndDate, selectedUser) => {
        try {
            const response = await getShipmentDetails(formattedStartDate, formattedEndDate, selectedUser === 'all' ? null : selectedUser);
            console.log("response", response.data);
            const updatedRows = response?.data?.body.map((row, index) => {
                const user = users?.find(user => user.user_id === row.user_id);
                console.log(users);
                const createdBy = user ? `${user.first_name} ${user.last_name}` : 'Unknown';

                return {
                    ...row,
                    id: index + 1,
                    created_by: createdBy,
                };
            });
            console.log(updatedRows);
            setRows(updatedRows || []);
        } catch (error) {
            showModal("An error occurred while fetching Shipment Details data.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleOpenEditDialog = (params) => {
        setIsEditing(true);
        setEditedRow(params.row);
        setEditDialogOpen(true);
    };

    const handleOpenDeleteDialog = (params) => {
        console.log(params)
        setDeletedRow(params.row);
        setDeleteDialogOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setDeleteDialogOpen(false);
        setDeletedRow(null);
    };

    const handleCloseEditDialog = () => {
        setEditDialogOpen(false);
        setEditedRow(null);
    };

    const handleSaveEditDialog = async (updatedShipment) => {
        try {
            const data = {
                ...updatedShipment,
                updated_date: new Date()
            }
            await updateShipmentDetails(data);
            const updatedRows = rows.map(row => row.id === data.id ? data : row);
            setRows(updatedRows);
            setEditDialogOpen(false);
        } catch (error) {
            showModal("An error occurred while saving shipment details.");
        }
    };

    const handleOpenAddShipment = () => {
        setIsEditing(false);
        setEditedRow({});
        setEditDialogOpen(true);
    };

    const handleSaveAddDialog = async (newShipment) => {
        const user_id = localStorage.getItem('user_id');
        const company_uuid = JSON.parse(localStorage.getItem('userDetails')).company_uuid;

        const data = {
            ...newShipment,
            user_id: user_id,
            company_uuid: company_uuid,
            created_date: new Date(),
            updated_date: new Date()
        };
        try {
            const response = await createShipmentDetail(data);
            // const newShipmentWithId = { ...newShipment, id: response.data.id };
            // setRows([...rows, newShipmentWithId]);
            console.log(data);
            setEditDialogOpen(false);
        } catch (error) {
            showModal("An error occurred while adding new shipment.");
        }
    };

    const [tabValue, setTabValue] = useState(localStorage.getItem('tabValue') || '1');

    const handleTabChange = (event, newValue) => {
        localStorage.setItem('tabValue', newValue);
        setTabValue(newValue);
    };

    const handleDeleteShipmentDetails = async () => {
        try {

            const response = await deleteShipmentDetails(deletedRow.uuid);
            if (response.status === 200) {
                const updatedRows = rows.filter(row => row.uuid !== deletedRow.uuid);
                setRows(updatedRows);
                setIsSnackbarOpen(true);
                setSnackBarMessage('Shipment successfully deleted.');
            } else {

                setIsSnackbarOpen(true);
                setSnackBarMessage('Failed to delete the shipment.');
            }
        } catch (error) {

            setIsSnackbarOpen(true);
            setSnackBarMessage('Error occurred while deleting the shipment.');
        } finally {

            setDeleteDialogOpen(false);
        }
    }


    return (
        <div className='container'>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, width: '100%', borderColor: 'divider', display: 'flex', justifyContent: 'center' }}>
                        <TabList onChange={handleTabChange} className='w-100'>
                            <Tab
                                className="text-trans-none ml-33"
                                label={
                                    <Box display="flex" alignItems="center">
                                        <ReceiptIcon />
                                        <span style={{ marginLeft: 8 }}>Invoice</span>
                                    </Box>
                                }
                                onClick={() => handleTableTabSelect('invoices')}
                                value="1"
                            />
                            <Tab
                                className="text-trans-none"
                                label={
                                    <Box display="flex" alignItems="center">
                                        <PriceCheckIcon />
                                        <span style={{ marginLeft: 8 }}>Rate Confirmation</span>
                                    </Box>
                                }
                                onClick={() => handleTableTabSelect('rateConfirmations')}
                                value="2"
                            />
                            <Tab
                                className="text-trans-none"
                                label={
                                    <Box display="flex" alignItems="center">
                                        <LocalShippingIcon />
                                        <span style={{ marginLeft: 8 }}>Proof of Delivery</span>
                                    </Box>
                                }
                                onClick={() => handleTableTabSelect('proofOfDelivery')}
                                value="3"
                            />
                            <Tab
                                className="text-trans-none ml-auto"
                                label={
                                    <Box display="flex" alignItems="center">
                                        <EditNoteRoundedIcon />
                                        <span style={{ marginLeft: 8 }}>Shipment Details</span>
                                    </Box>
                                }
                                onClick={() => handleTableTabSelect('shipmentDetails')}
                                value="4"
                            />
                        </TabList>
                    </Box>
                    <div className='row mt-1 mb-3'>
                        <div className='col-11 mt-2'>
                            <div className='fl-r'>
                                <Stack direction="row" spacing={1}>
                                    <Chip
                                        className='h-25p'
                                        label={`User: ${selectedUsername ? selectedUsername : 'All'}`}
                                        deleteIcon={selectedUsername === null ? null : <CloseRoundedIcon />}
                                        onDelete={selectedUsername === null ? null : removeUser}
                                    />
                                    {startDate && endDate && (
                                        <Chip
                                            className='h-25p'
                                            label={`Start date: ${formatDate(startDate)} - End date: ${formatDate(endDate)}`}
                                            deleteIcon={<CloseRoundedIcon />}
                                            onDelete={removeDates}
                                        />
                                    )}
                                </Stack>
                            </div>
                        </div>
                        <div className='col-1 mt-2'>
                            <Tooltip title="Filters">
                                <TuneIcon onClick={openFilterModal} className='ml-1 pointer vertical-align-top ml-15'></TuneIcon>
                            </Tooltip>
                            {isLoading ? (
                                <CircularProgress className='loader-progress fl-r' />
                            ) : (
                                <RefreshIcon className='pointer refresh-icon fl-r' onClick={() => handleSearch(selectedUser, undefined, startDate, endDate)} />
                            )}
                        </div>
                    </div>
                    <FilterModal
                        handleSearch={handleSearch}
                        startDate={startDate}
                        endDate={endDate}
                        sUser={selectedUser}
                        handleStartDateChange={handleStartDateChange}
                        handleEndDateChange={handleEndDateChange}
                        table={table}
                        handleTableChange={handleTableChange}
                        isFilterPopupOpen={isFilterPopupOpen}
                        closeFilterModal={closeFilterModal}
                        users={users}
                    />
                    <EditShipmentDetails
                        open={editDialogOpen}
                        handleClose={handleCloseEditDialog}
                        shipment={editedRow}
                        handleSave={isEditing ? handleSaveEditDialog : handleSaveAddDialog}
                        isEditing={isEditing}
                        handleSearch={() => handleSearch(selectedUser, undefined, startDate, endDate)}
                    />

                    <ConfirmationModal
                        isOpen={deleteDialogOpen}
                        onCancel={handleCloseDeleteDialog}
                        message="Are you sure you want to delete?"
                        onConfirm={handleDeleteShipmentDetails}
                        shipment={deletedRow}
                    />

                    <>
                        {isLoading ? (
                            <Stack spacing={2}>
                                <Skeleton variant="rounded" width={'100%'} height={100} />
                                <Skeleton variant="rounded" width={'100%'} height={80} />
                                <Skeleton variant="rounded" width={'100%'} height={100} />
                            </Stack>
                        ) : (
                            <>
                                <ShipmentTable
                                    rows={rows}
                                    columns={columns}
                                    editingId={editingId}
                                />
                                <ShipmentActions
                                    userRole={userRole}
                                    openExportPdfDetails={openExportPdfDetails}
                                    handleOpenUploadDocuments={handleOpenUploadDocuments}
                                    handleOpenInvoicePopup={handleOpenInvoicePopup}
                                    handleOpenAddShipment={handleOpenAddShipment}
                                    selectedTab={tabValue}
                                />
                            </>
                        )}
                    </>
                    <SnackBarMessage
                        isSnackbarOpen={isSnackbarOpen}
                        snackBarMessage={snackBarMessage}
                        setIsSnackbarOpen={setIsSnackbarOpen}
                    />
                    <PdfModals
                        isPdfOpen={isPdfOpen}
                        closePdfModal={closePdfModal}
                        pdfUrl={pdfUrl}
                        isExportPdfDetailsOpen={isExportPdfDetailsOpen}
                        openPreviewPdfModal={openPreviewPdfModal}
                        cancel={cancel}
                        selectedRows={selectedRows}
                        uploadDocuments={uploadDocuments}
                        handleCloseUploadDocuments={handleCloseUploadDocuments}
                        processDocuments={processDocuments}
                        showWarningModal={showWarningModal}
                        handleCloseWarningModal={handleCloseWarningModal}
                        openPdfModal={openPdfModal}
                        handleClosePdfModal={handleClosePdfModal}
                        pdfModalData={pdfModalData}
                        isInvoicePopupOpen={isInvoicePopupOpen}
                        handleCloseInvoicePopup={handleCloseInvoicePopup}
                        isQuickBooksPopupOpen={isQuickBooksPopupOpen}
                        handleCloseQuickBooksPopup={handleCloseQuickBooksPopup}
                        isOpen={isOpen}
                        message={message}
                        onClose={hideModal}
                    />
                </TabContext>
            </Box>
        </div>
    );
};

export default Shipments;
