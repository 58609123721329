import React, { useEffect, useState } from 'react'
import SideBar from '../../SideBar'
import { Table, TableBody, TableCell, TableHead, TableRow, IconButton } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getUserActivityDetails } from '../../Helper/api';
import UserActivityDetails from './UserActivityDetails';

const UserActivity = () => {

    const [userActivityDetails, setUserActivityDetails] = useState([]);
    const [openUserActivity, setOpenUserActivity] = useState(false);
    const [userDetailsData, setUserDetailsData] = useState('');

    const data = {
        firstName: "Hilda Kahia"
    }

    useEffect(() => {
        async function fetchUserActivityDetails() {
            const response = await getUserActivityDetails();
            setUserActivityDetails(response?.data);
            console.log(response);
        }
        fetchUserActivityDetails();
    }, []);

    const openUserDetailsModal = (data) => {
        setOpenUserActivity(true);
        setUserDetailsData(data);
    }

    const handleClosePdfModal = () => {
        setOpenUserActivity(false);
    };

    return (
        <div className='container-fluid p-0 overflow-x-none'>
            <div className='row'>
                <div className='col-lg-2'>
                    <SideBar></SideBar>
                </div>
                <div className='col-lg-10'>
                    <div className='container'>
                        <div className='row margin-left'>
                            <div className='col-12 service-header-font' style={{ color: "#3860B2" }}>
                                User Activity
                            </div>
                            <div className='col-12'>
                                <TableContainer component={Paper} className='mt-3'>
                                    <Table size="small" aria-label="user activity table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">ID</TableCell>
                                                <TableCell align="center">User Name</TableCell>
                                                <TableCell align="center">Role</TableCell>
                                                <TableCell align="center">Logins Count</TableCell>
                                                <TableCell align="center">Documents Sent</TableCell>
                                                <TableCell align="center">Documents Exported</TableCell>
                                                <TableCell align="center">Documents Downloaded</TableCell>
                                                <TableCell align="center">Invoices Created</TableCell>
                                                <TableCell align="center">Details</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {userActivityDetails?.map((row, index) => (
                                                <TableRow key={row.id}>
                                                    <TableCell align="center">{index + 1}</TableCell>
                                                    <TableCell align="center">{row?.first_name} {row?.last_name}</TableCell>
                                                    <TableCell align="center">{row?.role_name}</TableCell>
                                                    <TableCell align="center">{row?.login_activity_count}</TableCell>
                                                    <TableCell align="center">{row?.document_sent_count}</TableCell>
                                                    <TableCell align="center">{row?.export_summary_count}</TableCell>
                                                    <TableCell align="center">{row?.file_download_count}</TableCell>
                                                    <TableCell align="center">{row?.invoice_created_count}</TableCell>
                                                    <TableCell align="center">
                                                        <IconButton aria-label="view details" onClick={() => openUserDetailsModal(row)}>
                                                            <VisibilityIcon style={{ color: 'black' }} />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>
                <UserActivityDetails open={openUserActivity} onClose={handleClosePdfModal} userDetailsData={userDetailsData} />
            </div>
        </div>
    )
}

export default UserActivity