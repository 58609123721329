import { Card, CardContent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SideBar from '../../SideBar';
import './pricing.css';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { FaCentSign } from "react-icons/fa6";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { getModuleFeature } from '../../Helper/api';
import SessionService from '../../services/SessionService';
// import { color } from 'html2canvas/dist/types/css/types/color';

const ModulesPricing = () => {

    const userGroups = localStorage.getItem('cognitoGroups');
    const groupsLength = userGroups && userGroups?.split(',').length;
    const [plans, setPlans] = useState([]);

    useEffect(() => {
        const fetchPlanData = async () => {
            const res = await getModuleFeature();
            if (groupsLength === 1) {
                const filtered = res.find((element) => element.plan_name === 'Basic')
                setPlans(filtered);
            } else {
                const filtered = res.find((element) => element.plan_name === 'Advanced')
                setPlans(filtered);
            }
            console.log(plans)
        };
        fetchPlanData();
    }, [])

    return (
        <div className='container-fluid p-0 overflow-x-none'>
            <div className='row'>
                <div className='col-lg-2'>
                    <SideBar></SideBar>
                </div>
                <div className='col-lg-10'>
                    <div className='container'>
                        <div className='row margin-left'>
                            <div className='col-12 service-header-font' style={{ color: "#3860B2" }}>
                                Modules and Pricing
                            </div>
                            <div className='col-12'>
                                <div className='row'>
                                    {/* {plans.map((item, index) => ( */}
                                    <div className='col-4'>
                                        <Card sx={{ height: 400, width: 340, borderRadius: '16px', boxShadow: 3 }}>
                                            <CardContent className='card-content'>
                                                <div className='card-title'>
                                                    <div className='card-titlecl1'>
                                                        <div className='module-header'>
                                                            {plans.plan_name}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='card-title'>
                                                    <div className='card-price'>{plans.pricing}</div>
                                                    <div className='card-price-icon'><FaCentSign /></div>
                                                    <div className='card-price-label'><label>/document</label></div>
                                                </div>
                                                <List >
                                                    {(plans.feature_list)?.split(',').map((feature, index) => (
                                                        <ListItem
                                                            sx={{ gap: 2 }}
                                                        >
                                                            <IoIosCheckmarkCircle className='card-icon'></IoIosCheckmarkCircle>
                                                            <ListItemText primary={feature} />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </CardContent>
                                        </Card>
                                    </div>
                                    {/* ))} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModulesPricing