// PdfModal.jsx
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {
  getShipmentByOrder,
  getUnlinkedPdfs,
  unlinkDocument,
  updateLink,
} from "../../../Helper/api";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import "./OpenPdfModal.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PreviewPDF from "../PreviewPDF";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import FullScreenSpinner from "../../../components/FullScreenSpinner";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';
import { triggerShipment } from '../../../Helper/api.js';


const customStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    zIndex: 1000,
  },
};

const OpenPdfModal = ({ open, onClose, modalData }) => {
  const [pdfData, setPdfData] = useState([]);
  const [unlinkedPdfs, setUnlinkedPdfs] = useState([]);
  const [pdfUrl, setPdfUrl] = useState("");
  const [isPdfOpen, setIsPdfOpen] = useState(false);
  const [loading, setLoding] = useState(true);
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const [searchValue, setSearchValue] = React.useState("");
  const [docType, setDocType] = useState("");
  const [selectedLinks, setSelectedLinks] = useState(
    Array(unlinkedPdfs.length).fill("")
  );
  const userRole = localStorage.getItem("user_role");

  const handleLinkChange = (event, index) => {
    const newSelectedLinks = [...selectedLinks];
    newSelectedLinks[index] = event.target.value;
    setSelectedLinks(newSelectedLinks);
  };

  const handleChangeAccordion = (index) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? index : null);
  };
  const handleNoProofOfDelivery = () => {
    const orderNumber = modalData.order_number; // Replace "" with the actual order number

    // Retrieve user_id and companyName from localStorage
    const userId = localStorage.getItem('user_id');
    const company = localStorage.getItem('companyName');

    // Payload to be sent to the shipment API
    const payload = {
      order_number: orderNumber,
      user_id: userId,
      company: company,
      status: "Completed"
    };
    triggerShipment(payload)
      .then(response => {
        fetchShipmentByOrderData();
        setSelectedLinks(Array(unlinkedPdfs.length).fill(""));
      })
      .catch(error => {
        console.error('Error Sending Payload:', error);
      });
  };

  useEffect(() => {
    setLoding(true);
    fetchShipmentByOrderData();
  }, [modalData]);

  useEffect(() => {
    setLoding(true);
    fetchUnlinkedPdfsData();
  }, [modalData]);

  const closePdfModal = () => {
    setIsPdfOpen(false);
  };

  const openPreviewPdfModal = async (url, doc) => {
    setDocType(doc);
    setPdfUrl(url);
    setIsPdfOpen(true);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchValue(value);
    fetchUnlinkedPdfsData(value);
  };

  const fetchShipmentByOrderData = async () => {
    setLoding(true);
    console.log(modalData);
    console.log(pdfData);
    try {
      if (modalData) {
        const data = await getShipmentByOrder(modalData.order_number);
        setPdfData(data?.data?.body[0]);
        console.log(pdfData);
      }
    } catch (error) {
      console.error("Error fetching PDF data:", error);
    }
    setLoding(false);
  };

  const fetchUnlinkedPdfsData = async (fileName) => {
    setLoding(true);
    try {
      const unlinkedPdfsData = await getUnlinkedPdfs(fileName);
      if (unlinkedPdfsData?.data?.statusCode === 200) {
        setUnlinkedPdfs(unlinkedPdfsData?.data?.body);
      }
    } catch (error) {
      console.error("Error fetching PDF data:", error);
    }
    setLoding(false);
  };

  const linkPdf = async (pdfs, linkType) => {
    console.log(pdfs);
    console.log(linkType);
    setLoding(true);
    try {
      const requestBody = {
        linkType: linkType,
        fileType: pdfs?.file_type,
        uuid: pdfs?.uuid,
        bucket_name: pdfs?.bucket_name,
        key_name: pdfs?.key_name,
        order_number: modalData?.order_number,
        file_name: pdfs?.file_name,
      };
      const response = await updateLink(requestBody);
      fetchShipmentByOrderData();
      fetchUnlinkedPdfsData();
      setSelectedLinks(Array(unlinkedPdfs.length).fill(""));
    } catch (error) {
      console.error("Error linking PDF:", error);
    }
    setLoding(false);
  };

  const unlinkPdf = async (keyName, linkType, fileType, uuid) => {
    setLoding(true);
    try {
      const requestBody = {
        linkType: linkType,
        fileType: fileType,
        uuid: uuid,
        order_number: "",
        key_name: keyName,
      };
      const response = await unlinkDocument(requestBody);
      fetchShipmentByOrderData();
      fetchUnlinkedPdfsData();
      setSelectedLinks(Array(unlinkedPdfs.length).fill(""));
    } catch (error) {
      console.error("Error linking PDF:", error);
    }
    setLoding(false);
  };

  function removeSuffix(filename) {
    return filename.replace(/_errorDoc/g, "");
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      style={customStyles}
    >
      <DialogTitle className="text-color">
        {" "}
        <DescriptionIcon></DescriptionIcon> Shipment Documents
      </DialogTitle>
      {loading && <FullScreenSpinner />}
      <DialogContent sx={{ width: "100%", height: "80vh" }}>
        <div className="container text-color">
          <div className="row">
            <div className="col-sm-6 col-md-4 col-lg-3">
              {pdfData?.invoice_s3_link !== null && (
                <>
                  <div>
                    <ReceiptIcon></ReceiptIcon> Invoice
                  </div>
                  {pdfData?.invoice_s3_link ? (
                    <>
                      <embed
                        style={{
                          width: "100%",
                          height: "57vh",
                          overflow: "hidden",
                          border: "none",
                        }}
                        scroll="no"
                        src={pdfData.invoice_s3_link + "#toolbar=0"}
                        title="description"
                      ></embed>
                      <div
                        className="text-center pointer"
                        style={{ width: "100%" }}
                        onClick={() =>
                          openPreviewPdfModal(
                            pdfData.invoice_s3_link,
                            "Invoice Document"
                          )
                        }
                      >
                        <span>Preview - Invoice </span>
                        <OpenInNewIcon></OpenInNewIcon>
                      </div>
                    </>
                  ) : (
                    <span>No document found</span>
                  )}
                </>
              )}
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <>
                <div>
                  <PriceCheckIcon></PriceCheckIcon> Rate Confirmation
                </div>
                {pdfData?.rc_s3_link ? (
                  <>
                    <embed
                      style={{
                        width: "100%",
                        height: "57vh",
                        overflow: "hidden",
                        border: "none",
                      }}
                      scroll="no"
                      src={pdfData.rc_s3_link + "#toolbar=0"}
                    ></embed>
                    <div
                      className="text-center pointer"
                      style={{ width: "100%" }}
                      onClick={() =>
                        openPreviewPdfModal(
                          pdfData.rc_s3_link,
                          "Rate Confirmation Document"
                        )
                      }
                    >
                      <span>Preview - Rate Confirmation </span>
                      <OpenInNewIcon></OpenInNewIcon>
                    </div>
                    {userRole !== "READONLY" && (
                      <div className="text-center">
                        <Button
                          variant="contained"
                          className="theme-bg"
                          style={{ fontSize: "x-small" }}
                          onClick={() =>
                            unlinkPdf(
                              pdfData.rc_key_name,
                              "rc",
                              "rc",
                              pdfData.rc_uuid
                            )
                          }
                        >
                          <LinkOffIcon className="fs-l"></LinkOffIcon>
                          <span className="text-trans-none">
                            Unlink Document
                          </span>
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <span>No document found</span>
                )}
              </>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <>
                <div>
                  <LocalShippingIcon></LocalShippingIcon> Proof of Delivery
                </div>
                {pdfData?.pod_uuid ? (
                  <>
                    {pdfData?.pod_s3_link ? (
                      <>
                        <embed
                          style={{
                            width: "100%",
                            height: "57vh",
                            overflow: "hidden",
                            border: "none",
                          }}
                          scroll="no"
                          src={pdfData.pod_s3_link + "#toolbar=0"}
                        ></embed>
                        <div
                          className="text-center pointer"
                          style={{ width: "100%" }}
                          onClick={() =>
                            openPreviewPdfModal(
                              pdfData.pod_s3_link,
                              "Proof of Delivery Document"
                            )
                          }
                        >
                          <span>Preview - Proof of Delivery </span>
                          <OpenInNewIcon></OpenInNewIcon>
                        </div>
                      </>
                    ) : (
                      <div style={{ height: "61vh" }}>
                        <span>There is no Proof of Delivery document for this shipment.</span>
                      </div>
                    )}
                    {userRole !== "READONLY" && (
                      <div className="text-center">
                        <Button
                          variant="contained"
                          className="theme-bg"
                          style={{ fontSize: "x-small" }}
                          onClick={() =>
                            unlinkPdf(
                              pdfData.pod_key_name,
                              "pod",
                              "pod",
                              pdfData.pod_uuid
                            )
                          }
                        >
                          <LinkOffIcon className="fs-l"></LinkOffIcon>
                          <span className="text-trans-none">
                            Unlink Document
                          </span>
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <div style={{ height: '61vh' }}>
                    <span>No document found</span>
                  </div>
                )}

                {(userRole !== "READONLY" && !pdfData?.pod_uuid) && (
                  <div className="text-center" style={{ height: '61vh' }}>
                    <Button
                      variant="contained"
                      style={{ fontSize: "x-small" }}
                      onClick={handleNoProofOfDelivery}
                    >
                      <ContentPasteOffIcon className="fs-l"></ContentPasteOffIcon>
                      <span className="text-trans-none">
                        No Proof of Delivery
                      </span>
                    </Button>
                  </div>
                )}
              </>
            </div>
            <div className="col-sm-6 col-md-12 col-lg-3 border-color">
              <div>
                <DialogTitle className="p-0">
                  {" "}
                  <LinkOffIcon></LinkOffIcon> Unlinked Files (
                  {unlinkedPdfs.length})
                </DialogTitle>
              </div>
              <div>
                <TextField
                  className="pt-2 pb-4 col-12"
                  id="outlined-basic"
                  placeholder="Search"
                  variant="outlined"
                  value={searchValue}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 unlinked-div">
                {unlinkedPdfs &&
                  unlinkedPdfs.map((pdfs, index) => (
                    <div className="pb-3" key={index}>
                      <Accordion
                        expanded={expandedAccordion === index}
                        onChange={handleChangeAccordion(index)}
                      >
                        <AccordionSummary
                          style={{ background: "#ebedf3" }}
                          expandIcon={<KeyboardArrowDownIcon />}
                        >
                          <Tooltip title={removeSuffix(pdfs.file_name)}>
                            <div className="ellipsis">
                              {index + 1}. {removeSuffix(pdfs.file_name)}
                            </div>
                          </Tooltip>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            {expandedAccordion === index && (
                              <embed
                                style={{
                                  width: "100%",
                                  height: "40vh",
                                  overflow: "hidden",
                                  border: "none",
                                }}
                                src={pdfs.s3_link + "#toolbar=0"}
                                title="description"
                              />
                            )}
                            <div>
                              <span
                                onClick={() =>
                                  openPreviewPdfModal(pdfs.s3_link, "Document")
                                }
                                className="pointer"
                              >
                                Preview File{" "}
                                <OpenInNewIcon className="fs-m"></OpenInNewIcon>
                              </span>
                            </div>
                            {userRole !== "READONLY" && (
                              <>
                                <InputLabel
                                  className="fs-10 mt-3"
                                  id={`link-type-${index}`}
                                >
                                  Select Document Type
                                </InputLabel>
                                <Select
                                  className="w-65 h-5"
                                  value={selectedLinks[index] || ""}
                                  onChange={(event) =>
                                    handleLinkChange(event, index)
                                  }
                                >
                                  <MenuItem
                                    value="pod"
                                    disabled={pdfData.pod_uuid !== null}
                                  >
                                    Proof of delivery
                                  </MenuItem>
                                  <MenuItem
                                    value="rc"
                                    disabled={pdfData.rc_s3_link !== null}
                                  >
                                    Rate Confirmation
                                  </MenuItem>
                                </Select>
                                <Button
                                  variant="contained"
                                  onClick={() =>
                                    linkPdf(pdfs, selectedLinks[index])
                                  }
                                  disabled={!selectedLinks[index]}
                                  className="fl-r"
                                >
                                  <InsertLinkIcon className="fs-l"></InsertLinkIcon>
                                  <span className="text-trans-none">Link</span>
                                </Button>
                              </>
                            )}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          className="cancel-btn"
          style={{ width: "inherit" }}
          onClick={onClose}
        >
          <span className="text-trans-none">Close</span>
        </Button>
      </DialogActions>
      {isPdfOpen && (
        <PreviewPDF
          isOpen={isPdfOpen}
          onRequestClose={closePdfModal}
          pdfUrl={pdfUrl}
          docType={docType}
        />
      )}
    </Dialog>
  );
};

export default OpenPdfModal;
