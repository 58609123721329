import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import './Invoice.css'; // Import CSS file here
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import { createInvoice, getQuickBooksCustomerList, getQuickBooksServiceList, getQuickBooksTermsList, saveInvoice, createInvoiceActivity } from '../../../Helper/api';
import FullScreenSpinner from '../../../components/FullScreenSpinner';

const useStyles = makeStyles({
  inputRoot: {
    height: '2.75em',
  },
});

const InvoicePopup = ({ open, openQuickBooks, onClose }) => {
  const today = dayjs();
  const startDate = today;
  const endDate = today.day(12);

  const [invoiceData, setInvoiceData] = useState({
    customerName: '',
    customerId: '',
    customerEmail: '',
    billingAddress: '',
    state: '',
    city: '',
    postalCode: '',
    invoiceDate: startDate,
    dueDate: endDate,
    poNumber: '',
    driver: '',
    dispatcher: '',
    productOrService: '',
    productOrServiceId: '',
    description: 'PICKUP LOCATION: \nDROP LOCATION: ',
    quantity: '1',
    rate: '',
    amount: '',
    invoice_number: '',
    termId: '',
    message: 'THIS INVOICE HAS BEEN ASSIGNED AND MUST BE PAID \nDIRECTLY TO ORANGE COMMERCIAL CREDIT \nP.O.BOX 11099 \nOLYMPIA, WA 98508-1099'
  });

  const classes = useStyles();
  const [customerList, setCustomerList] = useState();
  const [servicesList, setServicesList] = useState();
  const [termsList, setTermsList] = useState();
  const [defaultTermValue, setDefaultTermValue] = useState(null);
  const [defaultServiceValue, setDefaultServiceValue] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCustomerList('');
    getServicesList();
    getTermsList();
    renderData();
  }, []);

  const getServicesList = async () => {
    try {
      const response = await getQuickBooksServiceList();
      setServicesList(response?.body?.QueryResponse?.Item);
      const defaultService = response?.body?.QueryResponse?.Item?.find(option => option.Name === 'Revenue/Load - Invoice');
      if (defaultService) {
        setDefaultServiceValue(defaultService);
        setInvoiceData(prevData => ({
          ...prevData,
          productOrService: defaultService.Name,
          productOrServiceId: defaultService.Id
        }));
      }
    } catch (error) {
      console.error('Error fetching Services list:', error);
    }
  };

  const getCustomerList = async (companyName) => {
    try {
      const response = await getQuickBooksCustomerList(companyName);
      setCustomerList(response?.body?.QueryResponse?.Customer);
    } catch (error) {
      console.error('Error fetching customer list:', error);
    }
  };

  const handleCustomerInputChange = (event, value) => {
    getCustomerList(value);
  };

  const renderData = () => {
    const data = JSON.parse(localStorage.getItem('invoiceDetails'));
    if (data) {
      const mappedData = {
        ...invoiceData,
        dispatcher: data.dispatcher,
        driver: data.truck_number,
        description: `PICKUP LOCATION: ${data.from_city}\nDROP LOCATION: ${data.to_city}`,
        rate: data.rate,
        amount: data.rate,
        invoice_number: data.invoice_number,
        poNumber: data.order_number
      };
      setInvoiceData(mappedData);
      checkCustomer();
    }
  };

  const checkCustomer = () => {
    const data = JSON.parse(localStorage.getItem('invoiceDetails'));
    console.log("customerList", customerList);
    if (data && data.debtor) {
      const matchedCustomer = customerList?.find(customer =>
        data.debtor.toLowerCase().includes(customer.DisplayName.toLowerCase()) ||
        customer.DisplayName.toLowerCase().includes(data.debtor.toLowerCase())
      );
      if (matchedCustomer) {
        setInvoiceData(prevState => ({
          ...prevState,
          customerName: matchedCustomer.DisplayName,
          customerId: matchedCustomer.Id,
          billingAddress: matchedCustomer.BillAddr.Line1,
          state: matchedCustomer.BillAddr.CountrySubDivisionCode,
          city: matchedCustomer.BillAddr.City,
          postalCode: matchedCustomer.BillAddr.PostalCode,
          customerEmail: matchedCustomer.PrimaryEmailAddr.Address
        }));
      }
    }
  };

  const getTermsList = async () => {
    try {
      const response = await getQuickBooksTermsList();
      setTermsList(response?.body?.QueryResponse?.Term);
      const defaultTerm = response?.body?.QueryResponse?.Term?.find(option => option.Name === 'Due on receipt');
      if (defaultTerm) {
        setDefaultTermValue(defaultTerm);
        setInvoiceData(prevData => ({
          ...prevData,
          termId: defaultTerm.Id
        }));
      }
    } catch (error) {
      console.error('Error fetching terms list:', error);
    }
  };

  const handleTermsSelect = (event, value) => {
    if (value) {
      setInvoiceData({
        ...invoiceData,
        termId: value?.Id,
      });
    }
  }

  const handleCustomerSelect = (event, value) => {
    console.log(value);
    if (value) {
      setInvoiceData({
        ...invoiceData,
        customerName: value?.DisplayName,
        customerId: value?.Id,
        billingAddress: value?.BillAddr?.Line1,
        state: value?.BillAddr?.CountrySubDivisionCode,
        city: value?.BillAddr?.City,
        postalCode: value?.BillAddr?.PostalCode,
        customerEmail: value?.PrimaryEmailAddr?.Address
      });

    }
  };

  const isCreateDisabled = () => {
    const requiredFields = [
      'customerName',
      'customerId',
      'customerEmail',
      'invoiceDate',
      'dueDate',
      'poNumber',
      'driver',
      'dispatcher',
      'productOrService',
      'productOrServiceId',
      'description',
      'quantity',
      'rate',
      'amount',
      'invoice_number',
      'termId',
      'message'
    ];

    return requiredFields.some(field => !invoiceData[field]);
  };

  const handleServiceSelect = (event, value) => {
    console.log(value);
    setInvoiceData({
      ...invoiceData,
      productOrService: value?.Name,
      productOrServiceId: value?.Id,
    });
  }


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvoiceData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      if (name === 'rate') {
        updatedData.amount = value;
      }
      return updatedData;
    });
  };

  const handleCancel = () => {
    onClose();
  };

  const handleInvoiceDateChange = (date) => {
    setInvoiceData({ ...invoiceData, invoiceDate: date });
  };

  const handleDueDateChange = (date) => {
    setInvoiceData({ ...invoiceData, dueDate: date });
  };

  const handleCreate = async () => {
    setLoading(true);
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const companyName = localStorage.getItem('companyName');

    const formattedData = {
      CustomerRef: { value: invoiceData?.customerId },
      TxnDate: invoiceData?.invoiceDate,
      DueDate: invoiceData?.dueDate,
      DocNumber: invoiceData?.invoice_number,
      SalesTermRef: {
        value: invoiceData?.termId
      },
      BillAddr: {
        Line1: invoiceData?.billingAddress,
        City: invoiceData?.city,
        CountrySubDivisionCode: invoiceData?.state,
        PostalCode: invoiceData?.postalCode
      },
      Line: [
        {
          Amount: parseFloat(invoiceData?.amount),
          DetailType: "SalesItemLineDetail",
          SalesItemLineDetail: {
            ItemRef: {
              name: invoiceData?.productOrService,
              value: invoiceData?.productOrServiceId
            },
            UnitPrice: parseFloat(invoiceData?.rate),
            Qty: parseInt(invoiceData?.quantity),
            TaxCodeRef: {
              value: "NON"
            }
          },
          Description: invoiceData?.description
        }
      ],
      TotalAmt: parseFloat(invoiceData?.amount),
      PrintStatus: "NeedToPrint",
      EmailStatus: "NeedToSend",
      BillEmail: {
        Address: invoiceData?.customerEmail
      },
      Balance: parseFloat(invoiceData?.amount),
      CustomerMemo: { value: invoiceData?.message },
      CustomField: [
        {
          DefinitionId: "1",
          Type: "StringType",
          StringValue: invoiceData?.poNumber
        },
        {
          DefinitionId: "2",
          Type: "StringType",
          StringValue: invoiceData?.driver
        },
        {
          DefinitionId: "3",
          Type: "StringType",
          StringValue: invoiceData?.dispatcher
        }
      ]
    };

    try {
      const response = await createInvoice(formattedData);
      console.log(formattedData);
      console.log(response?.body?.Invoice?.Id);

      if (response?.body?.Invoice) {
        const saveResponse = await saveInvoice(response?.body?.Invoice?.Id);
        console.log(saveResponse);

        const activityData = {
          invoice_number: invoiceData?.invoice_number,
          order_number: invoiceData?.poNumber,
          user_id: userDetails?.user_id,
          company: companyName,
          created_date: new Date().toISOString()
        };

        if (saveResponse.statusCode === 200) {
          activityData.status = "Success";
        } else {
          activityData.status = "Failed";
        }

        const activityResponse = await createInvoiceActivity(activityData);
        console.log(activityResponse);
      }
    } catch (error) {
      console.error('Error processing invoice:', error);
    } finally {
      setLoading(false);
      onClose();
    }
  };

  return (
    <>
      {loading && (
        <div>
          <FullScreenSpinner />
        </div>
      )}
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle className="invoice-popup-title">
          <h3>Create Invoice</h3>
        </DialogTitle>
        <DialogContent>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-12'>
                <h5>Customer Details</h5>
                <div className='row mt-3 invoice-popup-container'>
                  <div className='row mt-3'>
                    <div className='col-6'>
                      <Autocomplete
                        fullWidth
                        required
                        getOptionLabel={(option) => option.DisplayName}
                        options={customerList || []}
                        onChange={handleCustomerSelect}
                        onInputChange={handleCustomerInputChange}
                        classes={{ inputRoot: classes.inputRoot }}
                        renderInput={(params) => <TextField {...params}
                          label="Customer Name"
                          classes={{ input: classes.autocompleteInput }}
                        />}
                      />
                    </div>
                    <div className='col-6'>
                      <TextField
                        className="invoice-input"
                        fullWidth
                        label="Customer Email"
                        name="customerEmail"
                        value={invoiceData.customerEmail}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='col-3'>
                      <TextField
                        className="invoice-input"
                        fullWidth
                        label="Billing Address"
                        name="billingAddress"
                        value={invoiceData.billingAddress}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='col-3'>
                      <TextField
                        className="invoice-input"
                        fullWidth
                        label="City"
                        name="city"
                        value={invoiceData.city}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='col-3'>
                      <TextField
                        className="invoice-input"
                        fullWidth
                        label="State"
                        name="state"
                        value={invoiceData.state}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='col-3'>
                      <TextField
                        className="invoice-input"
                        fullWidth
                        label="Postal Code"
                        name="postalCode"
                        value={invoiceData.postalCode}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 mt-3'>
                <h5>Company Details</h5>
                <div className='row mt-3 invoice-popup-container'>
                  <div className='row mt-3'>
                    <div className='col-3'>
                      <TextField
                        className="invoice-input"
                        fullWidth
                        label="PO Number"
                        name="poNumber"
                        value={invoiceData.poNumber}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='col-3'>
                      <TextField
                        className="invoice-input"
                        fullWidth
                        label="Driver"
                        name="driver"
                        value={invoiceData.driver}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='col-3'>
                      <TextField
                        className="invoice-input"
                        fullWidth
                        label="Dispatcher"
                        name="dispatcher"
                        value={invoiceData.dispatcher}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className='col-3'>
                      <TextField
                        className="invoice-input"
                        fullWidth
                        label="Invoice Number"
                        name="invoice_number"
                        value={invoiceData.invoice_number}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 mt-3'>
                <h5>Invoice Details</h5>
                <div className='row mt-3 invoice-popup-container'>
                  <div className='row mt-3'>
                    <div className='col-6'>
                      <Autocomplete
                        fullWidth
                        getOptionLabel={(option) => option.Name}
                        options={termsList}
                        onChange={handleTermsSelect}
                        value={defaultTermValue}
                        classes={{ inputRoot: classes.inputRoot }}
                        renderInput={(params) => <TextField {...params}
                          label="Terms"
                        />}
                      />
                    </div>
                    <div className='col-3'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Invoice Date"
                          value={invoiceData.invoiceDate}
                          onChange={handleInvoiceDateChange}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className='col-3'>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Due Date"
                          value={invoiceData.dueDate}
                          onChange={handleDueDateChange}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </div>
                    <div className='col-12 mt-3'>
                      <div className='row'>
                        <div className='col-6'>
                          <Autocomplete
                            fullWidth
                            getOptionLabel={(option) => option.Name}
                            options={servicesList}
                            onChange={handleServiceSelect}
                            value={defaultServiceValue}
                            classes={{ inputRoot: classes.inputRoot }}
                            renderInput={(params) => <TextField {...params}
                              label="Product/Service"
                              classes={{ input: classes.autocompleteInput }}
                            />}
                          />
                        </div>
                        <div className='col-2'>
                          <TextField
                            className="invoice-input"
                            fullWidth
                            label="Quantity"
                            name="quantity"
                            type="number" // Change type to 'number' for numeric input
                            value={invoiceData.quantity}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className='col-2'>
                          <TextField
                            className="invoice-input"
                            fullWidth
                            label="Rate"
                            name="rate"
                            type="number" // Change type to 'number' for numeric input
                            value={invoiceData.rate}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className='col-2'>
                          <TextField
                            className="invoice-input"
                            fullWidth
                            label="Amount"
                            name="amount"
                            type="number" // Change type to 'number' for numeric input
                            value={invoiceData.amount}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-12 mt-3'>
                      <div className='row'>
                        <div className='col-6'>
                          <TextField
                            className="invoice-input"
                            fullWidth
                            label="Description"
                            name="description"
                            rows={4}
                            multiline
                            value={invoiceData.description}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className='col-6'>
                          <TextField
                            className="invoice-input"
                            fullWidth
                            label="Message"
                            name="message"
                            multiline
                            rows={4}
                            value={invoiceData.message}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="invoice-button-container">
          <Button className="invoice-cancel-button" onClick={handleCancel} color="secondary">Cancel</Button>
          <Button className="invoice-create-button" variant='contained' onClick={handleCreate} color="primary" disabled={isCreateDisabled()}>Create</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InvoicePopup;
