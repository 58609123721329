import React, { useState, useEffect } from 'react';
import './Support.css';
import LocationOnIcon from '@mui/icons-material/LocationOnOutlined';
import LocalPhoneIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import { sendSupportEmail } from '../../Helper/api';
import SnackBarMessage from '../../components/SnackBarMessage';
import { Button, TextField } from '@mui/material';
import contactUs from './../../assets/ContactUs.png';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
    textField: {
        width: '100%',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none',
            },
        },
    },
});
const Support = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: ''
    });
    const [isFormValid, setIsFormValid] = useState(false);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState('');
    const classes = useStyles();
    useEffect(() => {
        const isValid = Object.values(formData).every(field => field.trim() !== '');
        setIsFormValid(isValid);
    }, [formData]);
    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await sendSupportEmail((formData));
            if (response.status === 200) {
                setIsSnackbarOpen(true);
                setSnackBarMessage('Email sent successfully!')
                setFormData({
                    name: '',
                    email: '',
                    subject: '',
                    phone: '',
                    message: ''
                })
            } else {
                setIsSnackbarOpen(true);
                setSnackBarMessage('Failed to send email. Please try again later!');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        }
    };
    return (
        <div className="container-fluid contact-cm">
            <h1 className='text-center'>Contact Cartage Manager</h1>
            <div className="row">
                {/* <div className='col-7'>
                    <img src={contactUs} style={{ width: '100%' }} alt="Document Scan Icon" />
                </div> */}
                <div className="col-8 form-card contact-info">
                    <h2>Get in touch</h2>
                    <p>
                        We love hearing from you. Reach out below and let us know how we can help.
                    </p>
                    <form onSubmit={handleSubmit} className='row'>
                        <div className='col-6'>
                            <TextField className='w-100' variant="standard" type="text" id="name" label="Name" name="name" value={formData.name} onChange={handleChange} required />
                        </div>
                        <div className='col-6'>
                            <TextField className='w-100' variant="standard" type="email" id="email" name="email" label="Email" value={formData.email} onChange={handleChange} required />
                        </div>
                        <div className='col-6'>
                            <TextField className='w-100' variant="standard" type="text" id="subject" label="Subject" name="subject" value={formData.subject} onChange={handleChange} required />
                        </div>
                        <div className='col-6'>
                            <TextField className='w-100' variant="standard" type="tel" id="phone" label="Phone" name="phone" value={formData.phone} onChange={handleChange} required />
                        </div>
                        <div className='col-12'>
                            <TextField variant="standard" className='col-12' id="message" label="Your Message" name="message" multiline rows={4} value={formData.message} onChange={handleChange} required />
                        </div>
                        <div className='col-12 text-center mt-3'>
                            <Button className={isFormValid ? 'col-4 theme-bg' : 'col-4 disabled-btn'} variant='contained' disabled={!isFormValid} onClick={handleSubmit}>
                                <span className='text-trans-none'>Submit</span>
                            </Button>
                        </div>
                    </form>
                </div>
                <div className="col-4">
                    <div className="contact-info border-color bg-contactus">
                        <div>
                            <span className='font-bold shipment-header'>Contact Us</span>
                            <p>Feel free to contact us from below details information</p>
                        </div>
                        <div className="contact-item row">
                            <div className='col-3'>
                                <LocationOnIcon className='contact-item-icon theme-bg'></LocationOnIcon>
                            </div>
                            <div className='col-9'>
                                <h4 className='font-bold'>Visit Our Office</h4>
                                <p>417 Oakbend Dr. Suite 180<br />Lewisville, TX 75067</p>
                            </div>
                        </div>
                        <div className="contact-item row">
                            <div className='col-3'>
                                <LocalPhoneIcon className='contact-item-icon theme-bg'></LocalPhoneIcon>
                            </div>
                            <div className='col-9'>
                                <h4 className='font-bold'>Call Us Anytime</h4>
                                <p>Inquiry: (214) 206-8976</p>
                            </div>
                        </div>
                        <div className="contact-item row">
                            <div className='col-3'>
                                <EmailIcon className='contact-item-icon theme-bg'></EmailIcon>
                            </div>
                            <div className='col-9'>
                                <h4 className='font-bold'>Request A Quote</h4>
                                <p>bizdev@bizcloudexperts.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="map">
                        <iframe
                            loading="lazy"
                            src="https://maps.google.com/maps?q=BizCloud%20Experts%20417%20Oakbend%20Dr.%20Suite%20180%20Lewisville%2C%20TX%2075067&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near"
                            title="BizCloud Experts 417 Oakbend Dr. Suite 180 Lewisville, TX 75067"
                            width="100%"
                            height="300"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            aria-label="BizCloud Experts 417 Oakbend Dr. Suite 180 Lewisville, TX 75067">
                        </iframe>
                    </div>
                </div>
                <SnackBarMessage
                    isSnackbarOpen={isSnackbarOpen}
                    snackBarMessage={snackBarMessage}
                    setIsSnackbarOpen={setIsSnackbarOpen}
                />
            </div>
        </div>
    );
};
export default Support;