import React from 'react';
import { Snackbar } from '@mui/material';

const SnackBarMessage = ({ isSnackbarOpen, snackBarMessage, setIsSnackbarOpen }) => {
    return (
        <Snackbar
            open={isSnackbarOpen}
            autoHideDuration={2000}
            message={snackBarMessage}
            onClose={() => setIsSnackbarOpen(false)}
        />
    );
};

export default SnackBarMessage;
