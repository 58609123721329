import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import '../../Modules/DocumentScan/form.css';
import './SearchForm.css';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, FormControlLabel, Snackbar, TextField } from '@mui/material';
import { useLocation } from 'react-router-dom';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ContactsIcon from '@mui/icons-material/Contacts';
import PaddingIcon from '@mui/icons-material/Padding';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { saveDetails, searchDetails } from '../../Helper/api';
import TableDisplay from '../../components/table';
import LaunchIcon from '@mui/icons-material/Launch';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    },
};

const useStyles = makeStyles({
    input: {
        // Specify your desired styles for MuiInputBase-input and MuiFilledInput-input here

        padding: '10px !important',
        // fontSize: '16px',
        // border: '1px solid #ccc',
        // borderRadius: '5px',
        "& .MuiInputBase-input.Mui-disabled": {
            WebkitTextFillColor: "#5d5d5d",
            padding: '0 !important'
        },
    },
});

const SearchForm = () => {

    const location = useLocation()
    const value = location.pathname.split('/')[1]
    const id = location.pathname.split('/')[2];

    const [formData, setFormData] = useState({});
    const [originalFormData, setOriginalFormData] = useState({});
    const [changedFields, setChangedFields] = useState({});
    const [isDataChanged, setIsDataChanged] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [docValid, setDocValid] = useState(true);
    const [message, setMessage] = useState("");
    const [hasData, setHasData] = useState(true);
    const [freightChargeTerms, setFreightChargeTerms] = useState(false);
    const checkboxOptions = ['Prepaid', 'Collected', 'ThirdParty'];
    const [loading, setLoading] = useState(true);
    const [isEditing, setEditing] = useState(false);
    const [isPdfOpen, setPdfOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');

    const openPdf = (pdfUrl) => {
        setPdfUrl(pdfUrl + '#toolbar=0&navpanes=0');
        setPdfOpen(true);
    };

    const closePdf = () => {
        setPdfUrl('');
        setPdfOpen(false);
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await searchDetails(id, value);

                setLoading(false);
                setFormData(JSON.parse(JSON.stringify(response.data)));
                setOriginalFormData(JSON.parse(JSON.stringify(response.data)));
                // setFormData({
                //   "uuid": "872f1c6c-58c6-44d3-ae1f-85a60f1f605e",
                //   "attachmentLocation": "s3://cartage-manager-email-scanned-documents-dev/bol/unprocessed/aeronet-1.pdf",
                //   "billToAddress": "3645 North Lakewood Blvd. Long Beach CA 90808",
                //   "billToContact": "Jesse Carlos",
                //   "billToName": "Shimadzu Medical Systems USA",
                //   "billToPhone": "(310) 217-8855",
                //   "bolNumber": "105150687",
                //   "consigneeAddress": "301 Tyson Ave",
                //   "consigneeContact": "Joe Gwaltney",
                //   "consigneeName": "HENRY COUNTY MEDICAL GROUP",
                //   "consigneePhone": "731-415-5549",
                //   "correlationId": "2010869d-861c-4481-9c6c-442260c18633",
                //   "docType": "BOL",
                //   "emailSubject": "bol dev test",
                //   "emailTimestamp": "2024-01-15T00:22:37.591Z",
                //   "insertTimestamp": "2024-01-15T00:22:43",
                //   "itemDetails": [
                //     {
                //       "tableData": {
                //         "r1": {
                //           "c1": "Pcs.",
                //           "c2": "Description",
                //           "c3": "Actual Weight",
                //           "c4": "L",
                //           "c5": "W",
                //           "c6": "H",
                //           "c7": "Dim Weight"
                //         },
                //         "r2": {
                //           "c1": "1",
                //           "c2": "MOBILE X-RAY MEDICAL EQUIPMENT",
                //           "c3": "140",
                //           "c4": "30",
                //           "c5": "30",
                //           "c6": "42",
                //           "c7": "189"
                //         }
                //       },
                //       "tableName": "Unknown Table"
                //     },
                //     {
                //       "tableData": {
                //         "r2": {
                //           "c1": "HANDLING",
                //           "c2": "UNIT",
                //           "c3": "PACKAGE",
                //           "c4": null,
                //           "c5": "WEIGHT",
                //           "c6": "H.M",
                //           "c7": "COMMODITY DESCRIPTION Commodities requiring special or additional care or attention in handling or stowing",
                //           "c8": "LTL",
                //           "c9": "ONLY"
                //         },
                //         "r3": {
                //           "c1": "QTY",
                //           "c2": "TYPE",
                //           "c3": "QTY",
                //           "c4": "TYPE",
                //           "c5": null,
                //           "c6": "(X)",
                //           "c7": "must be marked and packaged as to ensure safe transportation with ordinary care See Section 2(e) of NMFC Item 360",
                //           "c8": "NMFC#",
                //           "c9": "CLASS"
                //         },
                //         "r4": {
                //           "c1": "19",
                //           "c2": "H",
                //           "c3": "19",
                //           "c4": "SK",
                //           "c5": "41933 lbs 760 lbs",
                //           "c6": null,
                //           "c7": "Sodium Bicarbonate PALLET/SLIP",
                //           "c8": "46220 02",
                //           "c9": "55"
                //         },
                //         "r5": {
                //           "c1": "19",
                //           "c2": null,
                //           "c3": "19",
                //           "c4": null,
                //           "c5": "42693 lbs",
                //           "c6": null,
                //           "c7": "GRAND TOTALS (Weight in lbs)",
                //           "c8": null,
                //           "c9": null
                //         }
                //       },
                //       "tableName": "CARRIERINFORMATION"
                //     }
                //   ],
                //   "originalEmailLocation": "s3://cartage-manager-email-scanned-documents-dev/dev/ejdgivunv6ilti8e61fm8astbqvgt2pvjj75oog1",
                //   "senderEmail": "kiran.kraleti@bizcloudexperts.com",
                //   "shipmentDate": "11/10/2023",
                //   "shipmentDestination": "BNA",
                //   "shipmentOrigin": "LAX",
                //   "shipperAddress": "3645 North Lakewood Blvd.",
                //   "shipperContact": "Jesse Carlos",
                //   "shipperName": "Shimadzu Medical Systems USA",
                //   "shipperPhone": "(310) 217-8855",
                //   "status": "Processed"
                // })
                response.data.statusCode === 404 ? setHasData(false) : setHasData(true);

            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [id]);

    const handleInputChange = (key, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [key]: value,
        }));
        setIsDataChanged(true);
        setChangedFields((prevChangedFields) => ({
            ...prevChangedFields,
            [key]: value,
        }));
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSave = () => {
        if (Object.keys(changedFields).length === 0) {
            return;
        }
        setEditing(true);
        setDocValid(false);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const cancelChanges = () => {
        setFormData(originalFormData)
        setIsDataChanged(false);
        setChangedFields({});
        setEditing(false);
        setIsCancelModalOpen(false);
        setDocValid(true);
        setMessage('Changes are Discarded');
        setOpen(true);
    };

    const cancelCloseModal = () => {
        setIsCancelModalOpen(false);
    };

    const saveChanges = async () => {
        formData.status = 'Retrain';
        try {
            // const response = await fetch(`https://jtmbaktotl.execute-api.us-east-1.amazonaws.com/dev/bol/${id}`, {
            //   method: 'POST',
            //   headers: {
            //     'Content-Type': 'application/json',
            //   },
            //   body: JSON.stringify(formData),
            // });

            const response = await saveDetails(id, value, formData)

            if (response.status === 200) {
                setIsDataChanged(false);
                setChangedFields({});
                setIsModalOpen(false);
                setMessage('Data successfully updated!');
                setOpen(true);
            } else {
                console.error('Failed to update data:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const renderCheckboxes = () => {
        if (formData?.freightChargeTerms) {
            setFreightChargeTerms(true);
            return checkboxOptions.map((option) => {
                const isChecked = isOptionChecked(option);

                return (
                    <FormControlLabel
                        key={option}
                        control={
                            <Checkbox
                                disabled={docValid}
                                checked={isChecked}
                                onChange={(e) => handleCheckboxChange(option, e.target.checked)}
                            />
                        }
                        label={option}
                    />
                );
            });
        } else {
            setFreightChargeTerms(false);
        }
    };

    const isOptionChecked = (option) => {
        for (const item of formData.freightChargeTerms) {
            if (item.name === option && item.enabled) {
                return true;
            }
        }
        return false;
    };

    const handleCheckboxChange = (option, checked) => {
        const updatedTerms = formData.freightChargeTerms.map((item) =>
            item.name === option ? { ...item, enabled: checked } : item
        );
        handleInputChange('freightChargeTerms', updatedTerms);
    };

    const FullScreenSpinner = () => {
        return (
            <div className="full-screen-spinner-overlay">
                <div className="spinner"></div>
            </div>
        );
    };

    const downloadPDF = (url) => {
        fetch(url).then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const contentType = response.headers.get('content-type');
            if (contentType !== 'application/pdf') {
                throw new Error(`Invalid content type: ${contentType}`);
            }
            return response.blob();
        }).then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const a = document.createElement('a');
            a.href = url;
            a.download = 'document.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        })
            .catch(error => console.error('Error downloading PDF:', error));
    };

    const classes = useStyles();

    return (

        <div style={{
            background: '#f0f2f8',
            minHeight: '91vh'
        }}>
            {loading ? (
                <FullScreenSpinner />
            ) : (
                <div className='container'>
                    <div className='row place-center'>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 header mb-3">
                            <div className='row'>
                                <div className='col-sm-12 col-md-6 col-lg-6'>
                                    {formData.docType === 'BOL' && <span> <ReceiptLongIcon /> Bill of Lading</span>}
                                    {formData.docType === 'DA' && <span> <ReceiptLongIcon /> Delivery Alerts</span>}
                                    {formData.docType === 'PA' && <span> <ReceiptLongIcon /> Pickup Alerts</span>}
                                    {formData.docType === 'POD' && <span> <ReceiptLongIcon /> Proof of Delivery</span>}
                                </div>
                            </div>
                        </div>
                        {hasData ? (
                            <div className="row p-0">
                                <div className='accordian-mui'>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <span className="header-text"> <InsertDriveFileOutlinedIcon /> Documents</span>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='row pb-3'>
                                                {
                                                    formData.documents.map((rowData, index) => (
                                                        <div className='col-sm-4 col-md-3 col-lg-2 text-center' onClick={() => { openPdf(rowData.preSignedUrl) }}>
                                                            <embed
                                                                style={{ 'width': '50%', 'height': '60%', 'overflow': 'hidden', border: 'none' }} scroll="no"
                                                                src={rowData.preSignedUrl} title="description"></embed>

                                                            {rowData.Name === 'BOL' && <label onClick={() => { openPdf(rowData.preSignedUrl) }} className='pdf-font'>Bill of Lading <LaunchIcon className='pdf-icon' /></label>}
                                                            {rowData.Name === 'DA' && <label onClick={() => { openPdf(rowData.preSignedUrl) }} className='pdf-font'>Delivery Alerts <LaunchIcon className='pdf-icon' /></label>}
                                                            {rowData.Name === 'PA' && <label onClick={() => { openPdf(rowData.preSignedUrl) }} className='pdf-font'>Pickup Alerts <LaunchIcon className='pdf-icon' /></label>}
                                                            {rowData.Name === 'POD' && <label onClick={() => { openPdf(rowData.preSignedUrl) }} className='pdf-font'>Proof of Delivery <LaunchIcon className='pdf-icon' /></label>}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div className='accordian-mui'>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <span className="header-text"><ReceiptIcon /> Shipment Details</span>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='row pb-3'>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    {/* <SelectionTag name='Bill of Lading Number'></SelectionTag> */}
                                                    <span className='font-bold'>Bill of Lading Number</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter BOL number'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.bolNumber}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('bolNumber', e.target.value)}
                                                    />
                                                </div>

                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Master Bill of Lading Number</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Master BOL number'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.masterBolNumber}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('masterBolNumber', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Shipment ETA</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Shipment ETA'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.shipmentEta}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('shipmentEta', e.target.value)}
                                                    />
                                                </div>

                                            </div>
                                            <div className='row pb-3'>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Shipment Date</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Shipment Date'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.shipmentDate}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('shipmentDate', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Shipment Time</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Shipment Time'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.shipmentTime}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('shipmentTime', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Origin</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Shipment Origin'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.shipmentOrigin}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('shipmentOrigin', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Destination</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Shipment Destination'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.shipmentDestination}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('shipmentDestination', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row pb-3'>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Pickup Contact Name</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Contact Name'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.pickupContactName}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('pickupContactName', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Pickup Phone Number</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Phone Number'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.pickupPhoneNumbr}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('pickupPhoneNumbr', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Pickup Address</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Address'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.pickupAddress}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('pickupAddress', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Pickup Location</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Location'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.pickupLocation}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('pickupLocation', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row pb-3'>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Pickup Ready Time</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Pickup Time'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.pickupReadyTime}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('pickupReadyTime', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Pickup Close Time</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Close Time'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.pickupCloseTime}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('pickupCloseTime', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row pb-3'>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Shipment Pickup</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Shipment Pickup'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.shipmentPickup}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('shipmentPickup', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Shipment Service Level</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Shipment Service Level'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.shipmentServiceLevel}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('shipmentServiceLevel', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Shipment Delivery</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Shipment Delivery'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.shipmentDelivery}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('shipmentDelivery', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Shipment Requester Phone</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Shipment Requester Phone'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.shipmentRequesterPhone}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('shipmentRequesterPhone', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row pb-3'>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Shipment Requestor Fax</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Shipment Requestor Fax'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.ShipmentRequesterFax}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('ShipmentRequesterFax', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Shipment Requestor Address</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Shipment Requestor Address'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.shipmentRequesterAddress}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('shipmentRequesterAddress', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Service Level</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Service Level'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.serviceLevel}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('serviceLevel', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-4 col-lg-3 col-xl-3'>
                                                    <span className='font-bold'>Payment Terms</span>

                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Payment Terms'} variant="filled" disabled={docValid}
                                                        type="text"
                                                        value={formData.paymentTerms}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        onChange={(e) => handleInputChange('paymentTerms', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div className='accordian-mui'>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <span className="header-text"> <LocalShippingIcon /> Shipper Details</span>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='row pb-3'>
                                                <div className='col-sm-12 col-md-6 col-lg-3'>
                                                    <label className='font-bold'>Name</label>
                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Name'} variant="filled" disabled={docValid}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        type="text"
                                                        value={formData.shipperName}
                                                        onChange={(e) => handleInputChange('shipperName', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-3'>
                                                    <label className='font-bold'>Address</label>
                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Address'} variant="filled" disabled={docValid}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        type="text"
                                                        value={formData.shipperAddress}
                                                        onChange={(e) => handleInputChange('shipperAddress', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-3'>
                                                    <label className='font-bold'>Contact</label>
                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Contact'} variant="filled" disabled={docValid}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        type="text"
                                                        value={formData.shipperContact ? formData.shipperContact : ''}
                                                        onChange={(e) => handleInputChange('shipperContact', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-3'>
                                                    <label className='font-bold'>Phone</label>
                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Phone Number'} variant="filled" disabled={docValid}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        type="text"
                                                        value={formData.shipperPhone}
                                                        onChange={(e) => handleInputChange('shipperPhone', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-sm-12 col-md-6 col-lg-3'>
                                                    <label className='font-bold'>Fax</label>
                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Fax Number'} variant="filled" disabled={docValid}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        type="text"
                                                        value={formData.shipperFax}
                                                        onChange={(e) => handleInputChange('shipperFax', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-3'>
                                                    <label className='font-bold'>Requested By</label>
                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Requested by'} variant="filled" disabled={docValid}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        type="text"
                                                        value={formData.shipperRequestedby}
                                                        onChange={(e) => handleInputChange('shipperRequestedby', e.target.value)}
                                                    />
                                                </div>

                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div className='accordian-mui'>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <span className="header-text"> <ContactsIcon /> Consignee Details</span>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='row'>
                                                <div className='col-sm-12 col-md-6 col-lg-3'>
                                                    <label className='font-bold'>Name</label>
                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Name'} variant="filled" disabled={docValid}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        type="text"
                                                        value={formData.consigneeName}
                                                        onChange={(e) => handleInputChange('consigneeName', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-3'>
                                                    <label className='font-bold'>Address</label>
                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Address'} variant="filled" disabled={docValid}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        type="text"
                                                        value={formData.consigneeAddress}
                                                        onChange={(e) => handleInputChange('consigneeAddress', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-3'>
                                                    <label className='font-bold'>Contact</label>
                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Contact'} variant="filled" disabled={docValid}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        type="text"
                                                        value={formData.consigneeContact}
                                                        onChange={(e) => handleInputChange('consigneeContact', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-3'>
                                                    <label className='font-bold'>Phone Number</label>
                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Phone number'} variant="filled" disabled={docValid}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        type="text"
                                                        value={formData.consigneePhone}
                                                        onChange={(e) => handleInputChange('consigneePhone', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div className='accordian-mui'>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <span className="header-text"> <AccountBalanceIcon /> 3rd Party Bill to Details</span>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='row'>
                                                <div className='col-sm-12 col-md-6 col-lg-3'>
                                                    <label className='font-bold'>Carrier Name</label>
                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Carrier Name'} variant="filled" disabled={docValid}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        type="text"
                                                        value={formData.billToName}
                                                        onChange={(e) => handleInputChange('billToName', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-3'>
                                                    <label className='font-bold'>Address</label>
                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Address'} variant="filled" disabled={docValid}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        type="text"
                                                        value={formData.billToAddress}
                                                        onChange={(e) => handleInputChange('billToAddress', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-3'>
                                                    <label className='font-bold'>Contact</label>
                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Contact'} variant="filled" disabled={docValid}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        type="text"
                                                        value={formData.billToContact}
                                                        onChange={(e) => handleInputChange('billToContact', e.target.value)}
                                                    />
                                                </div>
                                                <div className='col-sm-12 col-md-6 col-lg-3'>
                                                    <label className='font-bold'>Phone</label>
                                                    <TextField id="filled-basic" placeholder={docValid ? '' : 'Enter Phone number'} variant="filled" disabled={docValid}
                                                        InputProps={{
                                                            className: classes.input,
                                                        }}
                                                        className='col-12'
                                                        type="text"
                                                        value={formData.billToPhone}
                                                        onChange={(e) => handleInputChange('billToPhone', e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div className='accordian-mui'>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <span className="header-text"><PaddingIcon /> Additional Details</span>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <label className='font-bold'>Freight Charge Terms</label>
                                                    {freightChargeTerms ? (
                                                        <div>
                                                            {renderCheckboxes()}
                                                        </div>
                                                    ) : <textarea
                                                        disabled
                                                        value='Not Found'
                                                    ></textarea>
                                                    }
                                                </div>
                                                <div className='col-12'>
                                                    <label className='font-bold'>Special Instructions</label>
                                                    <textarea
                                                        disabled={docValid}
                                                        value={formData.instructions}
                                                        onChange={(e) => handleInputChange('instructions', e.target.value)}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div className='accordian-mui' style={{ 'padding-bottom': '2%' }}>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1-content"
                                            id="panel1-header"
                                        >
                                            <span className="header-text"><PostAddIcon /> Shipment Item Details</span>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className='row'>
                                                {formData && formData.itemDetails && (
                                                    <TableDisplay itemDetails={formData.itemDetails} />
                                                )}
                                                <div className='col-12'>
                                                    {(formData.itemDetails && formData.itemDetails.length > 0) && (
                                                        <div className="table-container">
                                                            {/* <TableContainer component={Paper}>
                                <Table>
                                  <TableHead>{renderTableHeaders()}</TableHead>
                                  <TableBody>{renderTableRows()}</TableBody>
                                </Table>
                              </TableContainer> */}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                                <div className='text-center'>
                                    {isDataChanged && (
                                        <button onClick={handleSave} className='col-sm-9 col-md-5 col-lg-3 save-button'>Save Changes</button>
                                    )}
                                    {!isDataChanged && (
                                        null
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className='col-12 col-sm-12 col-md-8 col-lg-8 col-xl-7'>No record found for unique ID : {id}</div>
                        )}

                        {/* {isPdfOpen && (
                            <Dialog onClose={closePdf}>
                                <DialogContent>
                                    <p>This is a dialog!</p>
                                    <button onClick={closePdf}>Close</button>
                                </DialogContent>
                            </Dialog>
                        )} */}

                        <Modal
                            style={customStyles}
                            isOpen={isPdfOpen}
                            onRequestClose={closePdf}
                            contentLabel="Preview"
                        >
                            <label className='font-bold'>Preview</label>
                            <iframe style={{ 'width': '100%', 'height': '60vh' }} src={pdfUrl}></iframe>
                            {/* <Document file={pdfUrl} /> */}
                            <button
                                onClick={() => { downloadPDF(pdfUrl) }}
                                className='save-button Cancel-btn confirm-button'>Download</button>
                            <button onClick={closePdf} className='save-button Cancel-btn confirm-button'>Close</button>
                        </Modal>

                        <Modal
                            style={customStyles}
                            isOpen={isModalOpen}
                            onRequestClose={closeModal}
                            contentLabel="Confirm Changes Modal"
                        >
                            <h2>Confirm Changes</h2>
                            <div>
                                <p>Are you sure you want to save the following changes?</p>
                                <div>
                                    {Object.entries(changedFields).map(([key, value]) => (
                                        <div className='mb-2'>
                                            <div key={key}>{`${key} : ${originalFormData[key]}`}</div>
                                            <div key={key}>{`Updated: ${value}`}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <button onClick={saveChanges} className='save-button confirm-button mt-3'>Yes</button>
                            <button onClick={closeModal} className='save-button Cancel-btn confirm-button'>No</button>
                        </Modal>

                        <Modal
                            style={customStyles}
                            isOpen={isCancelModalOpen}
                            onRequestClose={cancelCloseModal}
                            contentLabel="Confirm Changes Modal"
                        >
                            <h2>Cancel Changes</h2>
                            <div>
                                <p>Are you sure you want to cancel the following changes?</p>
                                <div>
                                    {Object.entries(changedFields).map(([key, value]) => (
                                        <div className='mb-2'>
                                            <div key={key}>{`${key} : ${originalFormData[key]}`}</div>
                                            <div key={key}>{`Updated: ${value}`}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <button onClick={cancelChanges} className='save-button confirm-button mt-3'>Yes</button>
                            <button onClick={cancelCloseModal} className='save-button Cancel-btn confirm-button'>No</button>
                        </Modal>
                    </div>
                    <Snackbar
                        open={open}
                        autoHideDuration={2000}
                        onClose={handleClose}
                        message={message}
                    />
                </div>
            )}
        </div>
    );
};

export default SearchForm;