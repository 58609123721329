import React from 'react';
import OpenPdfModal from './../OpenPdfModal';
import PreviewPDF from './../PreviewPDF';
import ExportPdfDetails from './../ExportPdfDetails';
import UploadDocuments from './../UploadDocuments';
import WarningModal from './../../../components/WarningModal';
import InvoicePopup from './../InvoicePopup/Invoice';
import QuickBooksPopup from './../QuickBooksPopup';
import './../Shipments.css';
import ErrorModal from '../../../components/ErrorModal';

const PdfModals = ({
    isPdfOpen,
    closePdfModal,
    pdfUrl,
    isExportPdfDetailsOpen,
    openPreviewPdfModal,
    cancel,
    selectedRows,
    uploadDocuments,
    handleCloseUploadDocuments,
    processDocuments,
    showWarningModal,
    handleCloseWarningModal,
    openPdfModal,
    handleClosePdfModal,
    pdfModalData,
    isInvoicePopupOpen,
    handleCloseInvoicePopup,
    isQuickBooksPopupOpen,
    handleCloseQuickBooksPopup,
    isOpen,
    message,
    hideModal
}) => {
    return (
        <div>
            <PreviewPDF
                isOpen={isPdfOpen}
                onRequestClose={closePdfModal}
                pdfUrl={pdfUrl}
                docType='Shipment Summary'
            />
            <ExportPdfDetails
                isOpen={isExportPdfDetailsOpen}
                onClose={openPreviewPdfModal}
                cancel={cancel}
                selectedRows={selectedRows}
            />
            <UploadDocuments
                isOpen={uploadDocuments}
                onProcess={processDocuments}
                cancel={handleCloseUploadDocuments}
            />
            <WarningModal
                isOpen={showWarningModal}
                message="The order number is missing for this shipment. Please update the order number to view the details for this shipment."
                onClose={handleCloseWarningModal}
            />
            <OpenPdfModal
                open={openPdfModal}
                onClose={handleClosePdfModal}
                modalData={pdfModalData}
            />
            <InvoicePopup
                open={isInvoicePopupOpen}
                onClose={handleCloseInvoicePopup}
            />
            <QuickBooksPopup
                openQuickBooks={isQuickBooksPopupOpen}
                handleCloseQuickBooksPopup={handleCloseQuickBooksPopup}
            />
            <ErrorModal isOpen={isOpen} message={message} onClose={hideModal} />
        </div>
    );
};

export default PdfModals;
