import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { MenuItem, Select } from '@mui/material';
import './ExportPdfDetails.css';
import FileUploadIcon from '@mui/icons-material/FileUpload';

const ExportPdfDetails = ({ isOpen, onClose, cancel, selectedRows }) => {
    const [signature, setSignature] = useState('');
    const [transferType, setTransferType] = useState('');
    const [isDoneDisabled, setIsDoneDisabled] = useState(true); // State to manage the disabled state of the "Done" button
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        if (transferType && signature) {
            setIsDoneDisabled(false);
        } else {
            setIsDoneDisabled(true);
        }
    }, [transferType, signature]);

    const handleTransferType = (event) => {
        setTransferType(event.target.value);
    };

    const handleSignatureChange = (event) => {
        setFileName('');
        const file = event.target.files[0];
        console.log(file);
        setFileName(file?.name ? file?.name : '');
        if (file === undefined) {
            setSignature('');
        }
        if (file) {
            if (file.type.startsWith('image/jpeg') || file.type.startsWith('image/jpg')) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    // reader.result contains the base64 representation of the image
                    setSignature(reader.result);
                };
                // Read the contents of the file as a data URL
                reader.readAsDataURL(file);
            } else {
                // Display an error message if the selected file is not an image or JPEG format
                alert('Please select an image file with JPEG format.');
                // Clear the file input
                event.target.value = null;
                setSignature(null);
            }
        }
    };

    const handleClose = () => {
        onClose(transferType, signature, selectedRows);
        setTransferType('');
        setSignature('');
        setFileName('');
    };

    return (
        <Modal open={isOpen} onClose={handleClose} disableBackdropClick={true}>
            <div className='export-modal-box'>
                <div>
                    <h3>Additional Details</h3>
                </div>
                <div className='mt-3'>
                    <h6>Transfer Type</h6>
                    <Select
                        style={{ width: '100%' }}
                        labelId="transferType"
                        id="transferType"
                        value={transferType}
                        placeholder="Tansfer Type"
                        onChange={handleTransferType}
                    >
                        <MenuItem style={{ width: '100%' }} value='WIRE'>Wire</MenuItem>
                        <MenuItem style={{ width: '100%' }} value='PICKUP'>Pickup</MenuItem>
                        <MenuItem style={{ width: '100%' }} value='MAIL'>Mail</MenuItem>
                        <MenuItem style={{ width: '100%' }} value='ACH'>ACH</MenuItem>
                        <MenuItem style={{ width: '100%' }} value='OVERNIGHT'>Overnight</MenuItem>
                    </Select>
                </div>
                <div className='mt-3'>
                    <h6>Upload Signature</h6>
                    <label for="file-upload" class="custom-file-upload">
                        <FileUploadIcon></FileUploadIcon> Upload
                    </label>
                    <input className='file-selector-button pl-0' id="file-upload" type="file" accept="image/jpeg,image/jpg" onChange={handleSignatureChange} />
                    <span className='ml-2'>{fileName}</span>
                </div>
                <div className='mt-3 div-center color-or fs-m'>
                    <span>You are exporting a total of {selectedRows.length} invoices.</span>
                    <br />
                    <span>Please click on proceed to export the combined pdf.</span>
                </div>
                <Button
                    onClick={cancel}
                    className='mt-3 cancel-btn'
                    >
                    <span className='text-trans-none'>Cancel</span>
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className='fl-r mt-3'
                    style={{ width: '40%' }}
                    onClick={handleClose}
                    disabled={isDoneDisabled}>
                    <span className='text-trans-none'>Proceed</span></Button>
            </div>
        </Modal>
    );
};

export default ExportPdfDetails;
