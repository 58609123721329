import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import FreightForm from "./Modules/DocumentScan/form";
import Search from "./Modules/DocumentScan/search";
import Header from "./Header";
import Footer from "./Footer";
import SearchForm from "./Pages/SearchForm";
import Dashboard from "./Pages/Dashboard";
import Shipments from "./Modules/Invoice";
import SignIn from "./Pages/SignIn";
import RegisterCompany from "./Pages/RegisterCompany";
import FullScreenSpinner from "./components/FullScreenSpinner";
import UserActivity from "./Profile/UserActivity";
import ModulesPricing from "./Profile/modules-pricing";
import Users from "./Profile/Users";
import QuickBooks from "./components/QuickBooks";
import CompanyDetails from "./Pages/CompanyDetails";

import LandingPage from "./Pages/LandingPage";
import Analytics from "./Profile/companyAdmin/Analytics";
import Contact from "./Pages/Contact";
import Support from "./Pages/Support";

export const UserGroupsContext = React.createContext([]);

function App() {

  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const storedAuth = localStorage.getItem('authenticated');
    if (storedAuth) {
      setAuthenticated(true);
    }
    setLoading(false);
  }, []);

  const onLoginSuccess = (result) => {
    setAuthenticated(true);
    localStorage.setItem('authenticated', true);
    navigate('/home');
  };

  if (loading) {
    return <FullScreenSpinner />;
  }

  return (
    <div style={{ overflowY: 'hidden', paddingBottom: '1px' }}>
      <Header />
      <Routes>
        <Route path="/signin" element={<SignIn onLoginSuccess={onLoginSuccess} />} />
        <Route path="/Register" element={<RegisterCompany />} />
        <Route path="/home" element={<LandingPage />} />
        <Route path="/company-list" element={<CompanyDetails />} />
        <Route path="/contact-cm" element={<Contact />} />
        <Route path="/support" element={<Support />} />
        <Route path="/*" element={<SignIn onLoginSuccess={onLoginSuccess} />} />
        {(authenticated || authenticated === 'true') ? (
          <>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route exact path="/bol/:id" element={<FreightForm />} />
            <Route exact path="/pa/:id" element={<FreightForm />} />
            <Route exact path="/pod/:id" element={<FreightForm />} />
            <Route exact path="/da/:id" element={<FreightForm />} />
            <Route path="/search/:bolid" element={<SearchForm />} />
            <Route path="/search" element={<Search />} />
            <Route path="/invoice-module" element={<Shipments />} />
            <Route path="/user-activity" element={<UserActivity />} />
            <Route path="/modules-pricing" element={<ModulesPricing />} />
            <Route path="/users" element={<Users />} />
            <Route path="/qb" element={<QuickBooks />} />
            <Route path="/company-list" element={<CompanyDetails />} />
            <Route path="/analytics" element={<Analytics />} />
          </>
        ) : (
          <Route path="*" element={<SignIn onLoginSuccess={onLoginSuccess} />} />
        )}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;