import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import './analytics.css'

const UserLoginTable = ({ data }) => {
  const [pageSize, setPageSize] = React.useState(5);

  // Define columns for DataGrid
  const columns = [
    { field: 'first_name', headerName: 'First Name', flex: 1, sortable: true },
    { field: 'last_name', headerName: 'Last Name', flex: 1, sortable: true },
    { field: 'company_label', headerName: 'Company', flex: 1, sortable: true },
    { field: 'role_name', headerName: 'Role Name', flex: 1, sortable: true },
    { field: 'document_sent_count', headerName: 'Document Sent Count', flex: 1, sortable: true },
    { field: 'export_summary_count', headerName: 'Document Export Count', flex: 1, sortable: true },
    { field: 'file_download_count', headerName: 'Document Download Count', flex: 1, sortable: true },
    { field: 'login_activity_count', headerName: 'Login Count', flex: 1, sortable: true },
  ];

  // Add an ID to each row for DataGrid
  const rows = data.map((row, index) => ({ id: index, ...row }));

  return (
    <div style={{ height: 400, width: '100%' }} className='no-border'>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        rowsPerPageOptions={[5, 10, 25]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        pagination
        sortingOrder={['asc', 'desc']}
      />
    </div>
  );
};

export default UserLoginTable;
