import React, { useEffect, useState } from 'react';
import { Button, TextField, FormControl, InputLabel, MenuItem, Select, Dialog, DialogActions, DialogContent } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import './../Shipments.css';
import { getUserDetailsByCompany } from '../../../Helper/api';
import CloseIcon from '@mui/icons-material/Close';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const FilterModal = ({
    handleSearch,
    startDate,
    endDate,
    sUser,
    handleStartDateChange,
    handleEndDateChange,
    table,
    handleTableChange,
    isFilterPopupOpen,
    closeFilterModal,
    users
}) => {
    
    const [selectedUser, setSelectedUser] = useState('all'); // Set default to 'all'


    useEffect(() => {
        console.log("Filter modal users", users);
        if (sUser === null) {
            setSelectedUser('all');
        }
    }, [sUser]);

    const handleUserChange = (event) => {
        setSelectedUser(event.target.value);
    };

    const handleSearchClick = () => {
        handleSearch(selectedUser, users.find(user => { return user.user_id === selectedUser }), startDate, endDate);
        closeFilterModal();
    };

    return (
        <Dialog open={isFilterPopupOpen} onClose={closeFilterModal} maxWidth="sm" fullWidth>
            <DialogContent>
                <div className='row'>
                    <div className='col-12 filter-header'>
                        <span className='shipment-header'>
                            Filters
                        </span>
                        <CloseIcon onClick={closeFilterModal} className='fl-r pointer'></CloseIcon>
                    </div>
                    {/* <div className='col-lg-6'>
                        <FormControl className="mt-3 ml-3 w-80">
                            <InputLabel>Document Type</InputLabel>
                            <Select
                                className='h-2em'
                                value={table}
                                label="Document Type"
                                onChange={handleTableChange}
                            >
                                <MenuItem value={'invoices'}>Invoices</MenuItem>
                                <MenuItem value={'rateConfirmations'}>Rate Confirmations</MenuItem>
                                <MenuItem value={'proofOfDelivery'}>Proof of Deliveries</MenuItem>
                            </Select>
                        </FormControl>
                    </div> */}
                    <div className='col-lg-6'>
                        <FormControl className="mt-3 ml-3 w-80">
                            <InputLabel>User</InputLabel>
                            <Select
                                className='h-2em'
                                value={selectedUser}
                                label="User"
                                onChange={handleUserChange}
                            >
                                <MenuItem value='all'>All</MenuItem>
                                { users && users.map((user) => (
                                    <MenuItem key={user.user_id} value={user.user_id}>
                                        {user.first_name + " " + user.last_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-lg-6'></div>
                    <div className='col-lg-6'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Start Date"
                                value={startDate}
                                className="mt-3 ml-3 w-80"
                                onChange={handleStartDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    <div className='col-lg-6'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="End Date"
                                value={endDate}
                                className="mt-3 ml-3 w-80"
                                onChange={handleEndDateChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
            </DialogContent>
            <DialogActions className='justify-center'>
                <Button onClick={handleSearchClick} variant="contained" className='theme-bg'>
                    <CheckRoundedIcon />
                    <span className='ml-3 text-trans-none'>Apply</span>
                </Button>
            </DialogActions>
        </Dialog >
    );
};

export default FilterModal;
