import React, { useEffect, useState } from 'react'
import "./Register.css"
import Tab from '@mui/material/Tab';
import ReviewTab from './Review';
import {
    Box,
    TextField,
    Card,
    CardContent,
    Button,
    Grid,
    Radio
} from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { FaCentSign } from "react-icons/fa6";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { getModuleFeature, companyNameValidation, userNameValidation } from '../../Helper/api';
import { Typography } from '@mui/material';
import AlertComponent from '../../components/Alert/alert';
import { Link } from 'react-router-dom';

const Register = () => {
    useEffect(() => {
        const fetchPlanData = async () => {
            const res = await getModuleFeature();
            setPlans(res);
        };
        fetchPlanData();
    }, [])
    const [value, setValue] = useState('1')
    const [plans, setPlans] = useState([]);
    // const [modules, setModules] = useState([])
    const [modules, setModules] = useState('Advanced')
    const [alert, setAlert] = useState(false)
    const [userAlert, setUserAlert] = useState(false)
    const [companyDetails, setCompanyDetails] = useState({
        company: "",
        email: "",
        phone: "",
        address: "",
        firstName: "",
        lastName: "",
        userName: "",
        userEmail: "",
        password: "",
        userPhone: ""
    })
    const [animate, setAnimate] = useState(false);

    useEffect(() => {
        setAnimate(true);
    }, []);

    const validateFields = () => {
        const mandatoryFields = ["company", "phone", "address", "firstName", "lastName", "password", "userName", "userEmail"];
        const isFilled = mandatoryFields.every(field => companyDetails[field].trim() !== "");
        if (!isFilled) {
            document.getElementById('error').innerHTML = 'Fill the mandatory fields';
        }
        return isFilled;
    };
    const handleChange = (e, index) => {
        if (!alert && !userAlert && validateFields()) {
            setValue(index);
        }
    };
    const handleClickNext = (e) => {
        if (!alert && !userAlert && validateFields()) {
            // setValue((parseInt(value) + 1).toString());
            setValue((parseInt(value) + 2).toString());
        }
    };
    const handleChangeInput = async (e) => {
        const { name, value } = e.target;
        setCompanyDetails(prevState => ({ ...prevState, [name]: value }));
        if (name === "company") {
            const res = await companyNameValidation(value)
            setAlert((res.data) === "exist")
        }
        if (name === "userName") {
            const res = await userNameValidation(value)
            setUserAlert((res.data) === "exist")
        }
        const mandatoryFields = ["company", "phone", "address", "firstName", "lastName", "password", "userName", "userEmail"];
        const isFilled = mandatoryFields.every(field => companyDetails[field].trim() !== "");
        if (isFilled) {
            document.getElementById('error').innerHTML = '';
        }
    };

    // const handleSelectModule = (e) => {
    //     const { name } = e.target;
    //     setModules(name);
    // }
    return (
        <div className={`container-fluid p-0 m-0 ${animate ? 'fade-in' : ''}`}>
            <div className='row'>
                <div className="col-md-7">
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <div>
                                <div style={{ borderBottom: '1', borderColor: 'gray', padding: '0 25% 0 25%' }}>
                                    <TabList onChange={handleChange} aria-label="lab API tabs example" variant="fullWidth">
                                        <Tab label="Company/User Details" value="1" />
                                        {/* <Tab label="Module" value="2" /> */}
                                        <Tab label="Review" value="3" />
                                    </TabList>
                                </div>
                            </div>
                            <div className='tab-content'>
                                <TabPanel value="1">
                                    <Grid container spacing={1} >
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="center"
                                            xs={6}>
                                            <Box
                                                className='register-form'
                                                height={500}
                                                width={450}
                                                sx={{
                                                    boxShadow: 3,
                                                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                                                    color: (theme) =>
                                                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                                                    p: 5,
                                                    m: 2,
                                                    borderRadius: 2,
                                                    textAlign: 'center',
                                                    fontSize: '1rem',
                                                    fontWeight: '700',
                                                }}

                                            >
                                                <label>Company Details</label>
                                                <TextField value={companyDetails.company} name='company' onChange={handleChangeInput} id="outlined-basic" label="Company*" variant="outlined" fullWidth='true' />
                                                {alert ? <AlertComponent severity="error" alertMessage="Company name already exist" /> : <></>}
                                                <TextField value={companyDetails.address} name='address' onChange={handleChangeInput} id="outlined-basic" label="Address*" variant="outlined" fullWidth='true' />
                                                <TextField value={companyDetails.email} name='email' onChange={handleChangeInput} id="outlined-basic" label="Email" variant="outlined" fullWidth='true' />
                                                <TextField value={companyDetails.phone} name='phone' onChange={handleChangeInput} id="outlined-basic" label="Phone Number*" variant="outlined" fullWidth='true' />
                                            </Box>
                                        </Grid>
                                        <Grid container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center" xs={6}>
                                            <Box
                                                className='register-form'
                                                height={500}
                                                width={450}
                                                sx={{
                                                    boxShadow: 3,
                                                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                                                    color: (theme) =>
                                                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                                                    p: 5,
                                                    m: 2,
                                                    borderRadius: 2,
                                                    textAlign: 'center',
                                                    fontSize: '1rem',
                                                    fontWeight: '700',
                                                }}
                                            >
                                                <label>User Details</label>
                                                <TextField value={companyDetails.firstName} name='firstName' onChange={handleChangeInput} id="outlined-basic" label="First Name*" variant="outlined" fullWidth='true' />
                                                <TextField value={companyDetails.lastName} name='lastName' onChange={handleChangeInput} id="outlined-basic" label="Last Name*" variant="outlined" fullWidth='true' />
                                                <TextField value={companyDetails.userName} name='userName' onChange={handleChangeInput} id="outlined-basic" label="Username*" variant="outlined" fullWidth='true' />
                                                {userAlert ? <AlertComponent severity="error" alertMessage="Username already exist" /> : <></>}
                                                <TextField value={companyDetails.userEmail} name='userEmail' onChange={handleChangeInput} id="outlined-basic" label="Email*" variant="outlined" fullWidth='true' />
                                                <TextField value={companyDetails.password} name='password' onChange={handleChangeInput} id="outlined-basic" label="Password*" variant="outlined" fullWidth='true' />
                                                <TextField value={companyDetails.userPhone} name='userPhone' onChange={handleChangeInput} id="outlined-basic" label="Phone Number" variant="outlined" fullWidth='true' />
                                            </Box>

                                        </Grid>
                                    </Grid>
                                    <div className='signup-button'>
                                        <div>
                                            <Button variant="contained" onClick={handleClickNext}>Next</Button>
                                        </div>
                                        <div id='error' className='error-div' style={{ fontSize: "small" }}></div>
                                    </div>
                                </TabPanel>
                                {/* <TabPanel value="2">
                        <Grid container spacing={1} direction="row"
                            justifyContent="center"
                            alignItems="center" xs={12}>
                            <Box
                                height={500}
                                width={1000}
                                sx={{
                                    bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                                    color: (theme) =>
                                        theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                                    m: 2,
                                    borderRadius: 2,
                                    textAlign: 'center',
                                    fontSize: '1rem',
                                    fontWeight: '700',
                                }}
                            >
                                <label><h3><b>Pricing</b></h3></label>
                                <label><h5>Choose your Plan</h5></label>
                                <Box className='modules'>
                                    {plans.map((item, index) => (
                                        <>
                                            <Card sx={{ height: 400, width: 340, borderRadius: '16px', boxShadow: 3 }}>
                                                <CardContent className='card-content'>
                                                    <div className='card-title'>
                                                        <div className='card-titlecl1'>
                                                            <div className='card-titlecl'>
                                                                <Radio
                                                                    checked={modules === item.plan_name}
                                                                    onChange={handleSelectModule}
                                                                    name={item.plan_name}
                                                                />
                                                            </div>
                                                            <div className='card-titlecl'>
                                                                {item.plan_name}
                                                            </div>
                                                        </div>
                                                        {item.plan_name === 'Advanced' && (
                                                            <div className='card-titlecl2'>
                                                                Recommended
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='card-title'>
                                                        <div className='card-price'>{item.pricing}</div>
                                                        <div className='card-price-icon'><FaCentSign /></div>
                                                        <div className='card-price-label'><label>/document</label></div>
                                                    </div>
                                                    <List >
                                                        {(item.feature_list).split(',').map((feature, index) => (
                                                            <ListItem
                                                                key={value}
                                                                sx={{ gap: 2 }}
                                                            >
                                                                <IoIosCheckmarkCircle className='card-icon'></IoIosCheckmarkCircle>
                                                                <ListItemText primary={feature} />
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </CardContent>
                                            </Card>
                                        </>

                                    ))}
                                </Box>
                            </Box>
                        </Grid>
                        <div className='signup-button'>
                            <Button variant="contained" onClick={handleClickNext}>Next</Button>
                        </div>
                    </TabPanel> */}
                                <TabPanel value="3">
                                    <ReviewTab
                                        company={companyDetails.company}
                                        email={companyDetails.email}
                                        phone={companyDetails.phone}
                                        address={companyDetails.address}
                                        firstName={companyDetails.firstName}
                                        lastName={companyDetails.lastName}
                                        userName={companyDetails.userName}
                                        userEmail={companyDetails.userEmail}
                                        password={companyDetails.password}
                                        userPhone={companyDetails.userPhone}
                                        modules={modules}
                                    />
                                </TabPanel>
                            </div>
                        </TabContext>
                    </Box>
                </div>
                <div className="col-md-5 info-panel login-background">
                    <Typography variant="h3" className='mt-3'>Welcome Back!</Typography>
                    <Typography className='mt-3'>
                        <>
                            <h6>
                                To keep connected with us
                            </h6>
                            <h6>
                                Enter your credentials to access your account.
                            </h6>
                        </>
                    </Typography>
                    <Link className='link' to="/signin">
                        <Button
                            variant="contained"
                            className='signup-btn mt-3'
                        // startIcon={<LoginIcon />}
                        >
                            Sign In
                        </Button>
                    </Link>
                </div>
            </div >
        </div>
    )
}

export default Register