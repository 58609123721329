export const setItemWithExpiry = (key, value, expiryInMinutes) => {
    const now = new Date();
    const expiryTime = now.getTime() + expiryInMinutes * 60000; // Convert minutes to milliseconds
    const item = {
        value: value,
        expiry: expiryTime,
    };
    localStorage.setItem(key, JSON.stringify(item));
};

export const getItemWithExpiry = (key) => {
    const itemString = localStorage.getItem(key);
    console.log(itemString);
    if (!itemString) {
        return null;
    }
    const item = itemString;
    const now = new Date();
    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }
    return JSON.parse(item).value;
};