import React from 'react';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { Link, useLocation } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { FaUserLarge, FaPhone, FaFlag } from "react-icons/fa6";
import { RiGraduationCapLine } from "react-icons/ri";
import { HiUserCircle } from "react-icons/hi";
import { FaBuilding } from 'react-icons/fa';
import { IoMail } from "react-icons/io5";
import "./sideBar.css";
import { useNavigate } from 'react-router-dom';
import SessionService from '../services/SessionService';
import InsightsIcon from '@mui/icons-material/Insights';

const SideBar = ({ data }) => {
    const location = useLocation();
    const { pathname } = location;
    const navigate = useNavigate();
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    const isFromCompanyList = localStorage.getItem('companyName') === 'bizcloudExperts';
    const analyticsMenuItem = { label: "Analytics", path: "/analytics", icon: <InsightsIcon style={{ fontSize: '15px', marginRight: "15px", Width: "70px" }} /> };

    const DEFAULT_APPLICATION = [
        // { label: "Modules/Pricing", path: "/modules-pricing", icon: <FaUserLarge style={{ fontSize: '15px', marginRight: "15px", Width: "70px" }} /> },
        { label: "Users Activity", path: "/user-activity", icon: <RiGraduationCapLine style={{ fontSize: '15px', marginRight: "15px", Width: "70px" }} /> },
        { label: "Users", path: "/users", icon: <FaUserLarge style={{ fontSize: '15px', marginRight: "15px", Width: "70px" }} /> },
        analyticsMenuItem
    ]

    const COMPANY_LIST_APPLICATION = [
        { label: "Customers", path: "/company-list", icon: <FaBuilding style={{ fontSize: '15px', marginRight: "15px", Width: "70px" }} /> },
        analyticsMenuItem
    ]

    const logout = () => {
        localStorage.clear();
        SessionService.setCompanyName('');
        navigate('/signin');
        window.location.reload();
    }
    // const applicationLinks = location.pathname === "/company-list" ? COMPANY_LIST_APPLICATION : DEFAULT_APPLICATION;
    const applicationLinks = isFromCompanyList ? COMPANY_LIST_APPLICATION : DEFAULT_APPLICATION;

    return (
        <div style={{ height: '88vh', display: 'flex', flexDirection: 'column' }}>
            <Sidebar style={{ flex: '1', backgroundColor: 'darkgrey', zIndex: '0', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                    <div className='icon-container'>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                                <HiUserCircle style={{ fontSize: '50px', margin: "15px", color: "white" }} />
                            </div>
                            <div>
                                <span>{userDetails?.first_name + " " + userDetails?.last_name}</span>
                            </div>
                        </div>
                        {userDetails?.email && (
                            <div className='ellipsis'><IoMail style={{ marginLeft: "20px", fontSize: "12px" }} /><span className='icon-names'>{userDetails?.email}</span></div>
                        )}
                        {userDetails?.phone && (
                            <div><FaPhone style={{ marginLeft: "20px", fontSize: "12px" }} /><span className='icon-names'>{userDetails?.phone}</span></div>
                        )}
                    </div>
                    <div className='item-container'>
                        {applicationLinks.map((item, id) => (
                            <Link
                                to={{
                                    pathname: item.path
                                }}
                                key={id}
                                className={"sidebar-item " + (pathname === item.path ? "active" : "")}
                            >   {item.icon}
                                {item.label}
                            </Link>
                        ))}
                        <div className='logout-btn' onClick={logout} ><FiLogOut /> Logout </div>
                    </div>
                </div>
            </Sidebar>
        </div>
    )
}

export default SideBar;
