import React from 'react';
import Modal from 'react-modal';
import './PreviewPDF.css';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { downloadActivity } from '../../../Helper/api';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
        zIndex: 1500, // Ensure the overlay is above other content
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white', // Optional: You can adjust the background color of the modal content
        border: 'none', // Optional: Remove border if desired
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Optional: Add shadow for better visibility
        height: '95vh',
        width: '75vh'
    },
};

const PreviewPDF = ({ isOpen, onRequestClose, pdfUrl, docType }) => {

    const downloadPDF = async (url) => {
        const data = {
            "file_type": docType,
            "company": null,
            "created_date": new Date()
        }
        await downloadActivity(data);
        fetch(url).then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const contentType = response.headers.get('content-type');
            if (contentType !== 'application/pdf') {
                throw new Error(`Invalid content type: ${contentType}`);
            }
            return response.blob();
        }).then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const a = document.createElement('a');
            a.href = url;
            a.download = 'document.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        })
            .catch(error => console.error('Error downloading PDF:', error));
    };

    return (
        <Modal
            style={customStyles}
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Preview"
        >
            <label className='font-bold'>{docType}</label>
            {pdfUrl ? (
                <>
                    <div className='pdf-container' style={{ 'width': '100%', 'height': '75vh', 'overflow': 'hidden', 'position': 'relative' }}>
                        <iframe title='PDF Preview' style={{ 'width': '100%', 'height': '100%', 'border': 'none', 'position': 'absolute', 'left': '0', 'top': '0' }} src={pdfUrl + "#toolbar=0"}></iframe>
                    </div>
                    <div className='pt-3'>
                        <button
                            onClick={() => { downloadPDF(pdfUrl); }}
                            className='save-button confirm-button'>
                            <DownloadRoundedIcon></DownloadRoundedIcon>
                            Download
                        </button>
                        <button
                            onClick={onRequestClose}
                            className='save-button close-button confirm-button'>
                            Close
                        </button>
                    </div>
                </>
            ) : <span>No document found</span>}
        </Modal>
    );
};

export default PreviewPDF;
