import React from 'react';
import './Header.css';
import Modal from './slidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import SessionService from '../services/SessionService';
import cmLogo from './../assets/CM_LOGO.png';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import HomeIcon from '@mui/icons-material/Home';
import Button from '@mui/material/Button';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirect = () => {
    handleClose();
    if (SessionService.getCompanyName()) {
      navigate('/dashboard');
    }
  };

  const handleRedirectToSignIn = () => {
    handleClose();
    localStorage.clear();
    SessionService.setCompanyName('');
    navigate('/signin'); // Navigate to the signin route first
    window.location.reload(); // Reload the page after navigating
  };

  const handleRedirectToProfile = () => {
    handleClose()
    if (localStorage.getItem('companyName') === 'bizcloudExperts') {
      navigate('/company-list');
    } else {
      navigate('/user-activity');
    }
  };

  const handleRedirectToLogin = () => {
    navigate('/login');
  };

  const handleRedirectToSignUp = () => {
    navigate('/Register');
  };

  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  return (
    <div className='linear-background padding-p7'>
      <div className="container-fluid m-0">
        <div className="row align-items-center">
          <div className="col-6 col-md-8">
            <span className='text-colour header-font-size pointer ml-2' onClick={handleRedirect}>
              Cartage Manager
            </span>
          </div>
          <div className="col-6 col-md-4 text-end">
            {SessionService.getAuthenticated() ? (
              <>
                <AccountCircleRoundedIcon
                  className='fl-r pointer'
                  style={{ color: 'white', fontSize: 'xx-large' }}
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleRedirectToProfile}>
                    <PersonRoundedIcon style={{ marginRight: '8px' }} />
                    Profile
                  </MenuItem>
                  <MenuItem onClick={handleRedirect}>
                    <HomeIcon style={{ marginRight: '8px' }} />
                    Home
                  </MenuItem>
                  <MenuItem onClick={handleRedirectToSignIn}>
                    <LogoutIcon style={{ marginRight: '8px' }} />
                    Logout
                  </MenuItem>
                </Menu>
              </>
            ) : location.pathname === '/' ? (
              <>
                <Button
                  onClick={handleRedirectToLogin}
                  className="text-white px-4 hover-login"
                >
                  <span className="text-trans-none">Login</span>
                </Button>
                <Button
                  onClick={handleRedirectToSignUp}
                  className="bg-white px-4 hover-signup"
                >
                  <span className="text-trans-none">Signup</span>
                </Button>
              </>
            ) : null}
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={toggleModal} />
    </div>
  );
};

export default Header;
