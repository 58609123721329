import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import { CiSearch } from "react-icons/ci";
import './search.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { searchDetails } from '../../Helper/api';
import SnackBarMessage from '../../components/SnackBarMessage';


const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const location = useLocation()
  const value = location.pathname.split('/')[1]
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const handleSearch = async () => {
    try {

      const response = await searchDetails(searchTerm, value);
      console.log('response', response);
      if (response.status === 404) {
        setSnackBarMessage(response.description);
        setIsSnackbarOpen(true);
      } else {
        navigate(`/search/${searchTerm}`);
      }
    } catch (error) {
      // Handle errors
      console.error(error);
    }
  };

  return (
    <>
      <div className="container" style={{marginTop: '16%'}}>
        <div className='row'>
          <div className='col-8'>
            <input
              type="text"
              placeholder="Search for Bol Number"
              value={searchTerm}
              className="textfield fl-r"
              style={{ width: '500px', borderRadius: '20px', border: '2px solid #ccc', height: '60px' }}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className='col-4'>
            <IconButton onClick={handleSearch} color="primary" className='fl-l search-icon' style={{ fontSize: '40px', paddingRight: '20px' }}>
              <CiSearch />
            </IconButton>
          </div>
        </div>
        <SnackBarMessage
          isSnackbarOpen={isSnackbarOpen}
          snackBarMessage={snackBarMessage}
          setIsSnackbarOpen={setIsSnackbarOpen}
        />
      </div>
    </>
  );
};

export default Search;
