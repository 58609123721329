import { Card, CardContent } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReceiptIcon from '@mui/icons-material/Receipt';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import './Dashboard.css';
import SessionService from '../../services/SessionService';
import { getUserDetails } from '../../Helper/api';
import invoiceIcon from './../../assets/invoice.png';
import documentScanIcon from './../../assets/search.png';

const Dashboard = () => {
    const navigate = useNavigate();
    const userGroups = SessionService.getCognitoGroups();

    const formsNavigate = () => {
        navigate('/search');
    };

    const shipmentNavigate = () => {
        navigate('/invoice-module');
    };

    useEffect(() => {
        const fetchUserData = async () => {
            const response = await getUserDetails(localStorage.getItem('user_id'));
            console.log(response);
            localStorage.setItem('userDetails', JSON.stringify(response?.data[0]));
        };
        fetchUserData();
    })

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-12 service-header-font'>
                    My Services
                </div>
                {userGroups.includes('documents_module') && (
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <Card onClick={formsNavigate} className='pointer'>
                            <CardContent className='row'>
                                <div className='col-4 place-center'>
                                    <img src={documentScanIcon} style={{ width: '100%' }} alt="Document Scan Icon" />
                                </div>
                                <div className='col-8 p-0'>
                                    <span className='p-0 fl-l'>
                                        Documents Scan
                                    </span>
                                    <br></br>
                                    <span className='p-0 fl-l fs-12'>
                                        Scan, digitize, and organize documents effortlessly.
                                    </span>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                )}
                {userGroups.includes('invoice_module') && (
                    <div className='col-sm-6 col-md-4 col-lg-3'>
                        <Card onClick={shipmentNavigate} className='pointer'>
                            <CardContent className='row'>
                                <div className='col-4 place-center'>
                                    <img src={invoiceIcon} style={{ width: '100%' }} alt="Invoice Icon" />
                                </div>
                                <div className='col-8 p-0'>
                                    <span className='p-0 fl-l'>
                                        Invoicing
                                    </span>
                                    <br></br>
                                    <span className='p-0 fl-l fs-12'>
                                        Efficiently generate, track, and manage invoices
                                    </span>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Dashboard;
