import React, { useState } from 'react'
import "./Register.css"
import {
    Box,
    Button,
    Grid,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import { companySignup } from '../../Helper/api';
import { useNavigate } from 'react-router-dom';
import FullScreenSpinner from '../../components/FullScreenSpinner';
import { Snackbar } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const ReviewTab = ({ company, email, phone, address, firstName, lastName, userName, userEmail, password, userPhone, modules }) => {

    const [loading, setLoading] = useState(false);
    const [snackbar, setSnackbar] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);

    const navigate = useNavigate();
    const handleClick = async () => {
        setLoading(true);
        const payload = {
            company_name: company,
            email: email,
            phone: phone,
            address: address,
            firstName: firstName,
            lastName: lastName,
            user_Name: userName,
            user_email: userEmail,
            user_phone: userPhone,
            password: password,
            modules: modules,
        }
        const response = await companySignup(payload)
        // if (response.status === "success") {
        //     setLoading(false);
        //     setSnackbar(true);
        //     navigate('/signin');
        // }
        setLoading(false);
        setOpenDialog(true);
    }
    const handleCloseDialog = () => {
        setOpenDialog(false);
        navigate('/signin');
    }
    return (
        <>
            {loading && (
                <div>
                    <FullScreenSpinner />
                </div>
            )}
            <div>
                <Grid container spacing={1} >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        xs={12}>
                        <Box
                            className='reviewBox'
                            height={500}
                            width={600}
                            sx={{
                                boxShadow: 3,
                                bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#c5cae9' : '#fff'),
                                color: (theme) =>
                                    theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                                p: 5,
                                m: 2,
                                borderRadius: 2,
                                textAlign: 'center',
                                fontSize: '0.875rem',
                                fontWeight: '700',
                            }}

                        >
                            <label><h5>Review Details</h5></label>
                            <div className='reviewList'>
                                <label htmlFor="">Company Name</label>
                                <label htmlFor="">{company}</label>
                            </div>
                            <div className='reviewList'>
                                <label htmlFor="">Email Address</label>
                                <label htmlFor="">{email}</label>
                            </div>
                            <div className='reviewList'>
                                <label htmlFor="">Contact No</label>
                                <label htmlFor="">{phone}</label>
                            </div>
                            <div className='reviewList'>
                                <label htmlFor="">Company Address</label>
                                <label htmlFor="">{address}</label>
                            </div>
                            <div className='reviewList'>
                                <label htmlFor="">First Name</label>
                                <label htmlFor="">{firstName}</label>
                            </div>
                            <div className='reviewList'>
                                <label htmlFor="">Last Name</label>
                                <label htmlFor="">{lastName}</label>
                            </div>
                            <div className='reviewList'>
                                <label htmlFor="">UserName</label>
                                <label htmlFor="">{userName}</label>
                            </div>
                            <div className='reviewList'>
                                <label htmlFor="">User Email</label>
                                <label htmlFor="">{userEmail}</label>
                            </div>
                            <div className='reviewList'>
                                <label htmlFor="">Password</label>
                                <label htmlFor="">{password}</label>
                            </div>
                            <div className='reviewList'>
                                <label htmlFor="">Phone Number</label>
                                <label htmlFor="">{userPhone}</label>
                            </div>
                            <div className='reviewList'>
                                <label htmlFor="">Modules</label>
                                <label htmlFor="">{modules}</label>
                            </div>

                        </Box>
                    </Grid>
                </Grid>
                <div className='signup-button'>
                    <Button variant="contained" onClick={handleClick}>SignUp</Button>

                </div>
                <div style={{ marginTop: '20px' }}>
                    <Alert severity="success" color="warning" >
                        This signup will initiate the creation of an account for both the company and a user, which will remain in pending status until further action is taken.
                        A member of our Cartage Manager team will be in touch with you shortly to gather additional details.
                    </Alert>
                </div>
                <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                >
                    <DialogTitle>Sign Up Success</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Stack sx={{ width: '100%' }} spacing={2} >
                                <Alert severity="info">This signup will initiate the creation of an account for both the company and a user, which will remain in pending status until further action is taken.
                                    A member of our Cartage Manager team will be in touch with you shortly to gather additional details.</Alert>
                            </Stack>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <Snackbar
                open={snackbar}
                autoHideDuration={3000}
                message='User created succesfully'
                onClose={() => setSnackbar(false)}
            />
        </>
    )
}

export default ReviewTab
